import { CalendarEvent } from 'src/types/calendar';
import axios from 'src/utils/axios';

class CalendarApi {
    count(params: any = {}): Promise<number> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/events', params: { ...params, pageSize: 1 } })
                .then((response) => {
                    resolve(Number(response.headers.totalcount));
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    getEvents(params: any = {}): Promise<CalendarEvent[]> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/events', params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    createEvent(data): Promise<CalendarEvent> {
        return new Promise((resolve, reject) =>
            axios({ method: 'POST', url: '/events', data })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    updateEvent({ eventId, update }): Promise<CalendarEvent> {
        return new Promise((resolve, reject) =>
            axios({ method: 'PUT', url: '/events/' + eventId, data: update })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    deleteEvent(eventId: string): Promise<true> {
        return new Promise((resolve, reject) =>
            axios({ method: 'DELETE', url: '/events/' + eventId })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }
}

export const calendarApi = new CalendarApi();
