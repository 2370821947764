export const DHL_SERVICES = [
    {
        label: 'Standard',
        value: 'standard',
    },
    {
        label: 'Prio',
        value: 'prio',
    },
    {
        label: 'Europaket',
        value: 'euro',
    },
    {
        label: 'International',
        value: 'international',
    },
    {
        label: 'Connect',
        value: 'connect',
    },
    {
        label: 'Warenpost',
        value: 'warenpost',
    },
];

export const VCA_TYPES = [
    {
        label: 'A16',
        value: 'A16',
    },
    {
        label: 'A18',
        value: 'A18',
    },
];

export const ENDORSEMENT_TYPES = [
    {
        label: 'Retournieren',
        value: 'IMMEDIATE',
    },
    {
        label: 'Verwerfen',
        value: 'ABANDONMENT',
    },
];

export const CUSTOMER_ALERT_TYPES = [
    {
        label: 'E-Mail',
        value: 'EMAIL',
    },
    {
        label: 'SMS',
        value: 'SMS',
    },
];

export const IDENT_TYPES = [
    {
        label: 'Personalausweis (DE)',
        value: 'GERMAN_IDENTITY_CARD',
    },
    {
        label: 'Reisepass (DE)',
        value: 'GERMAN_PASSPORT',
    },
    {
        label: 'Reisepass (INT.)',
        value: 'INTERNATIONAL_PASSPORT',
    },
];

export const TIME_SLOTS = [
    {
        label: 'Vormittag',
        value: 'FORENOON',
    },
    {
        label: 'Mittag',
        value: 'NOON',
    },
    {
        label: 'Nachmittag',
        value: 'AFTERNOON',
    },
    {
        label: 'Abend',
        value: 'EVENING',
    },
];

export const DHL_DELIVERED_CODES = ['DLVRD'];
