import 'ace-builds/src-min-noconflict/ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-tomorrow_night';
import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-quill/dist/quill.snow.css';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from 'src/App';
import { AuthProvider } from 'src/contexts/auth';
import { SettingsProvider } from 'src/contexts/settings';
import store from 'src/store';

import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

ReactDOM.render(
    <StrictMode>
        <HelmetProvider>
            <ReduxProvider store={store}>
                <StyledEngineProvider injectFirst>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <SettingsProvider>
                            <BrowserRouter>
                                <AuthProvider>
                                    <App />
                                </AuthProvider>
                            </BrowserRouter>
                        </SettingsProvider>
                    </LocalizationProvider>
                </StyledEngineProvider>
            </ReduxProvider>
        </HelmetProvider>
    </StrictMode>,
    document.getElementById('root')
);
