import axios from 'src/utils/axios';

class ExtraApi {
    async search(text: string): Promise<any> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/search/' + text })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Extra Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async getDashboardData(): Promise<number> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/dashboard' })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Extra Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async getGermanyCount(): Promise<number> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/count/germany' })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Extra Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }
}

export const extraApi = new ExtraApi();
