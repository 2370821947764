import { AppThunk } from 'src/store';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LoadingState {
    loading: boolean;
    backdrop: boolean;
}

const initialState: LoadingState = {
    loading: false,
    backdrop: false,
};

const slice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setLoading(state: LoadingState, action: PayloadAction<any>): void {
            const loading = action.payload;

            state.loading = loading;
        },
        setBackdrop(state: LoadingState, action: PayloadAction<any>): void {
            const backdrop = action.payload;

            state.backdrop = backdrop;
        },
    },
});

export const { reducer } = slice;

export const setLoading =
    (loading: boolean): AppThunk =>
    (dispatch): void => {
        dispatch(slice.actions.setLoading(loading));
    };

export const setBackdrop =
    (backdrop: boolean): AppThunk =>
    (dispatch): void => {
        dispatch(slice.actions.setBackdrop(backdrop));
    };

export default slice;
