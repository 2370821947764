import moment from 'moment';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { calendarApi } from 'src/api/calendar';
import { deliveryApi } from 'src/api/delivery';
import { AppThunk } from 'src/store';
import { CalendarEvent } from 'src/types/calendar';

interface CalendarState {
    events: CalendarEvent[];
    isModalOpen: boolean;
    selectedEventId: string | null;
    selectedRange: {
        start: number;
        end: number;
    } | null;
}

const initialState: CalendarState = {
    events: [],
    isModalOpen: false,
    selectedEventId: null,
    selectedRange: null,
};

const slice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        getEvents(state: CalendarState, action: PayloadAction<CalendarEvent[]>): void {
            state.events = action.payload;
        },
        createEvent(state: CalendarState, action: PayloadAction<CalendarEvent>): void {
            state.events.push(action.payload);
        },
        selectEvent(state: CalendarState, action: PayloadAction<string>): void {
            state.isModalOpen = true;
            state.selectedEventId = action.payload;
        },
        updateEvent(state: CalendarState, action: PayloadAction<CalendarEvent>): void {
            const event = action.payload;

            state.events = state.events.map((_event) => {
                if (_event._id === event._id) {
                    return { ..._event, ...event };
                }

                return _event;
            });
        },
        deleteEvent(state: CalendarState, action: PayloadAction<string>): void {
            state.events = state.events.filter((event) => event._id !== action.payload);
        },
        selectRange(state: CalendarState, action: PayloadAction<{ start: number; end: number }>): void {
            const { start, end } = action.payload;

            state.isModalOpen = true;
            state.selectedRange = {
                start,
                end,
            };
        },
        openModal(state: CalendarState): void {
            state.isModalOpen = true;
        },
        closeModal(state: CalendarState): void {
            state.isModalOpen = false;
            state.selectedEventId = null;
            state.selectedRange = null;
        },
    },
});

export const { reducer } = slice;

export const getEvents =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        const events = await calendarApi.getEvents();
        const deliveries = await deliveryApi.find({ 'planned_arrival>': moment().subtract(1, 'month').format('YYYY-MM-DD'), 'planned_arrival<': moment().add(1, 'month').format('YYYY-MM-DD') });

        const data = [
            ...events.map((e) => ({ ...e, type: 'event', id: e._id, color: e.all_day ? '#688eff' : '#58C15C' })),
            ...deliveries.map((e) => ({
                id: e._id,
                _id: e._id,
                all_day: true,
                color: '#d18b3b',
                note: e.note,
                end: e.planned_arrival,
                start: e.planned_arrival,
                title: e.name,
                type: 'delivery',
            })),
        ];

        dispatch(slice.actions.getEvents(data));
    };

export const createEvent =
    (createData): AppThunk =>
    async (dispatch): Promise<void> => {
        const data = await calendarApi.createEvent(createData);

        dispatch(slice.actions.createEvent({ ...data, type: 'event', color: data.all_day ? '#688eff' : '#58C15C' }));
    };

export const selectEvent =
    (eventId?: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(slice.actions.selectEvent(eventId));
    };

export const updateEvent =
    (eventId: string, update: any, type: string): AppThunk =>
    async (dispatch): Promise<void> => {
        if (type === 'event' || !type) {
            const data = await calendarApi.updateEvent({
                eventId,
                update,
            });

            dispatch(slice.actions.updateEvent({ ...data, type: 'event', color: data.all_day ? '#688eff' : '#58C15C' }));
        } else {
            await deliveryApi.update(eventId, { planned_arrival: new Date(moment(update.start).format('YYYY-MM-DD')) });

            dispatch(slice.actions.updateEvent({ _id: eventId, start: new Date(moment(update.start).format('YYYY-MM-DD')), end: new Date(moment(update.start).format('YYYY-MM-DD')) }));
        }
    };

export const deleteEvent =
    (eventId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        await calendarApi.deleteEvent(eventId);

        dispatch(slice.actions.deleteEvent(eventId));
    };

export const selectRange =
    (start: number, end: number): AppThunk =>
    (dispatch): void => {
        dispatch(slice.actions.selectRange({ start, end }));
    };

export const openModal =
    (): AppThunk =>
    (dispatch): void => {
        dispatch(slice.actions.openModal());
    };

export const closeModal =
    (): AppThunk =>
    (dispatch): void => {
        dispatch(slice.actions.closeModal());
    };

export default slice;
