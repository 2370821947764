import { findIndex, get, omit, startCase } from 'lodash';
import moment from 'moment';
import { FC, useState } from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import toast from 'react-hot-toast';
import { Link as RouterLink } from 'react-router-dom';

import { ArrowDropDown, Save } from '@mui/icons-material';
import { Alert, Button, Dialog, DialogTitle, IconButton, Link, Menu, MenuItem, Tooltip, Typography, useTheme } from '@mui/material';

import { orderApi } from 'src/api/order';
import Scrollbar from 'src/components/Scrollbar';
import { setError } from 'src/slices/error';
import { useDispatch } from 'src/store';
import { Order } from 'src/types/order';

interface Props {
    orderId: string;
    order: Order;
    histories: Order[];

    setOrder: (value: Order) => void;
}

const HistoryAlert: FC<Props> = ({ orderId, order, histories, setOrder }) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [index, setIndex] = useState<number>(findIndex(histories, { _id: order._id }) > 0 ? findIndex(histories, { _id: order._id }) : 0);
    const [openDetails, setOpenDetails] = useState<boolean>(false);

    const open = Boolean(anchorEl);
    const isDifferentVersion = !(order._id === histories[0]._id || order._id === orderId);
    const ITEM_HEIGHT = 80;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const onOpenDetails = () => setOpenDetails(true);
    const onCloseDetails = () => setOpenDetails(false);

    const onSaveVersion = async (): Promise<void> => {
        try {
            const update = {
                ...order,
                items: order.items.map((e) => ({ item: get(e, 'item._id'), amount: e.amount })),
                data: { ...order.data, documents: order.data.documents.map((e) => get(e, '_id')) },
                client: get(order, 'client._id'),
                warehouse: get(order, 'warehouse._id'),
            };

            await orderApi.update(orderId, update);

            toast.success('Auftrag gespeichert');

            window.history.back();
        } catch (error) {
            dispatch(setError(error));
        }
    };

    return (
        <>
            {/manuell erstellt|Import durch Schnittstelle/gim.test(get(histories, [index, 'changes'])) ? (
                <Alert
                    sx={{ mt: 1 }}
                    variant='outlined' // filled when theme === light ?
                    severity='info'
                    action={
                        <>
                            {isDifferentVersion && (
                                <Tooltip title='Diese Version speichern'>
                                    <IconButton color='inherit' size='small' onClick={onSaveVersion}>
                                        <Save fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Tooltip title='Andere Version auswählen'>
                                <IconButton onClick={handleClick} color='inherit' size='small'>
                                    <ArrowDropDown fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </>
                    }>
                    {get(histories, [index, 'user', 'username']) ? (
                        <>
                            <b>Manuell erstellt von: </b>
                            <Link
                                underline='always'
                                color='inherit'
                                component={RouterLink}
                                to={`/dashboard/users/${get(histories, [index, 'user', '_id'])}`}>
                                {startCase(get(histories, [index, 'user', 'username']))}
                            </Link>
                        </>
                    ) : (
                        get(histories, [index, 'changes'])
                    )}
                </Alert>
            ) : (
                // if history.note === "Erstellt"
                <Alert
                    sx={{ mt: 1 }}
                    variant='outlined' // filled when theme === light ?
                    severity='warning'
                    action={
                        <>
                            {isDifferentVersion && (
                                <Tooltip title='Diese Version speichern'>
                                    <IconButton color='inherit' size='small' onClick={onSaveVersion}>
                                        <Save fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Button color='inherit' size='small' onClick={onOpenDetails}>
                                DETAILS
                            </Button>
                            <Tooltip title='Andere Version auswählen'>
                                <IconButton onClick={handleClick} color='inherit' size='small'>
                                    <ArrowDropDown fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </>
                    }>
                    <b>Änderung: </b> {get(histories, [index, 'changes'])}{' '}
                    {get(histories, [index, 'user', 'username']) && (
                        <>
                            - <b>Nutzer: </b>
                            <Link
                                underline='always'
                                color='inherit'
                                component={RouterLink}
                                to={`/dashboard/users/${get(histories, [index, 'user', '_id'])}`}>
                                {startCase(get(histories, [index, 'user', 'username']))}
                            </Link>
                        </>
                    )}
                </Alert>
            )}

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '55ch',
                    },
                }}>
                {histories.map((option, i) => (
                    <MenuItem
                        key={option._id}
                        selected={option._id === order._id}
                        onClick={() => {
                            setIndex(i);
                            setOrder(option);
                            handleClose();
                        }}>
                        <Typography variant='inherit' noWrap>
                            {moment(option.created_at).format('LLL')} - {get(option, 'changes')}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>

            <Dialog open={openDetails} onClose={onCloseDetails} fullWidth maxWidth='lg'>
                <DialogTitle sx={{ backgroundColor: theme.palette.background.default }}>
                    {/manuell erstellt|Import durch Schnittstelle/gim.test(get(histories, [index, 'changes'])) ? (
                        get(histories, [index, 'user', 'username']) ? (
                            <>
                                <b>Manuell erstellt von: </b>
                                <Link
                                    underline='always'
                                    color='inherit'
                                    component={RouterLink}
                                    to={`/dashboard/users/${get(histories, [index, 'user', '_id'])}`}>
                                    {startCase(get(histories, [index, 'user', 'username']))}
                                </Link>
                            </>
                        ) : (
                            get(histories, [index, 'changes'])
                        )
                    ) : (
                        <>
                            <b>Änderung: </b> {get(histories, [index, 'changes'])}{' '}
                            {get(histories, [index, 'user', 'username']) && (
                                <>
                                    - <b>Nutzer: </b>
                                    <Link
                                        underline='always'
                                        color='inherit'
                                        component={RouterLink}
                                        to={`/dashboard/users/${get(histories, [index, 'user', '_id'])}`}>
                                        {startCase(get(histories, [index, 'user', 'username']))}
                                    </Link>
                                </>
                            )}
                        </>
                    )}
                </DialogTitle>
                <Scrollbar>
                    <ReactDiffViewer
                        styles={{
                            variables: {
                                dark: {
                                    addedBackground: '#2fb557',
                                    addedColor: '#fff',
                                    addedGutterBackground: '#71d18d',
                                    addedGutterColor: '#fff',
                                    codeFoldBackground: theme.palette.background.default,
                                    codeFoldContentColor: '#fff',
                                    codeFoldGutterBackground: theme.palette.background.default,
                                    diffViewerBackground: theme.palette.background.default,
                                    emptyLineBackground: theme.palette.background.default,
                                    gutterBackground: theme.palette.background.default,
                                    gutterBackgroundDark: theme.palette.background.default,
                                    gutterColor: '#fff',
                                    removedBackground: '#b5382f',
                                    removedColor: '#fff',
                                    removedGutterBackground: '#cc5c54',
                                    removedGutterColor: '#fff',
                                    wordAddedBackground: '#77d994',
                                    wordRemovedBackground: '#d46961',
                                },
                                light: {
                                    addedBackground: '#2fb557',
                                    addedColor: '#fff',
                                    addedGutterBackground: '#71d18d',
                                    addedGutterColor: '#fff',
                                    codeFoldBackground: theme.palette.background.default,
                                    codeFoldContentColor: theme.palette.text.primary,
                                    codeFoldGutterBackground: theme.palette.background.default,
                                    diffViewerBackground: theme.palette.background.default,
                                    emptyLineBackground: theme.palette.background.default,
                                    gutterBackground: theme.palette.background.default,
                                    gutterBackgroundDark: theme.palette.background.default,
                                    gutterColor: theme.palette.text.primary,
                                    removedBackground: '#b5382f',
                                    removedColor: '#fff',
                                    removedGutterBackground: '#cc5c54',
                                    removedGutterColor: '#fff',
                                    wordAddedBackground: '#77d994',
                                    wordRemovedBackground: '#d46961',
                                },
                            },
                            line: {
                                padding: '10px 2px',
                                '&:hover': {
                                    background: theme.palette.primary.main,
                                },
                            },
                        }}
                        oldValue={JSON.stringify(omit(histories[index + 1], ['_id', 'user', 'created_at', 'changes']), null, 2)}
                        newValue={JSON.stringify(omit(histories[index], ['_id', 'user', 'created_at', 'changes']), null, 2)}
                        splitView={true}
                        showDiffOnly={true}
                    />
                </Scrollbar>
            </Dialog>
        </>
    );
};

export default HistoryAlert;
