import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { KeyboardArrowRight } from '@mui/icons-material';
import { Breadcrumbs, Grid, Link, Typography } from '@mui/material';

const Headline: FC = () => {
    return (
        <Grid container justifyContent='space-between' spacing={3}>
            <Grid item>
                <Typography color='textPrimary' variant='h3'>
                    Account
                </Typography>
                <Breadcrumbs aria-label='breadcrumb' separator={<KeyboardArrowRight fontSize='small' />} sx={{ mt: 1 }}>
                    <Link color='textPrimary' component={RouterLink} to='/dashboard' variant='subtitle2'>
                        Dashboard
                    </Link>
                    <Typography color='textSecondary' variant='subtitle2'>
                        Account
                    </Typography>
                </Breadcrumbs>
            </Grid>
        </Grid>
    );
};

export default Headline;
