import { User } from 'src/types/user';
import axios from 'src/utils/axios';
import { decode } from 'src/utils/jwt';

class AuthApi {
    async login({ username, password }): Promise<string> {
        return new Promise((resolve, reject) =>
            axios({ method: 'POST', url: '/auth', data: { username, password } })
                .then((response) => {
                    if (!response.data.access_token) {
                        reject(new Error('Benutzername / Passwort überprüfen'));
                        return;
                    }

                    resolve(response.data.access_token);
                })
                .catch((error) => {
                    console.error('[Auth Api]: ', error.response ? error.response.data : error);
                    reject(new Error('Benutzername / Passwort überprüfen'));
                })
        );
    }

    me(accessToken): Promise<User> {
        return new Promise((resolve, reject) => {
            try {
                const user = decode(accessToken) as any;

                resolve(user);
            } catch (err) {
                console.error('[Auth Api]: ', err);
                reject(new Error('Internal server error'));
            }
        });
    }
}

export const authApi = new AuthApi();
