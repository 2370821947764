import { get } from 'lodash';
import { FC, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { alpha, Box, Card, CardContent, Grid, Typography } from '@mui/material';

import SplitDialog from './SplitDialog';

import { Fulfillment } from 'src/types/fulfillment';
import { Item } from 'src/types/item';

interface Props {
    path: string;
    height: string;
    fulfillment?: Fulfillment;
    items: Item[];

    setItems: (value: Item[]) => void;
}

const ItemsBox: FC<Props> = ({ path, height, fulfillment, items, setItems }) => {
    const [item, setItem] = useState<Item>();
    const [split, setSplit] = useState<boolean>(false);
    const [index, setIndex] = useState<number>(0);

    const onOpenSplit = () => setSplit(true);
    const onCloseSplit = () => setSplit(false);

    const onSplit = (amount: number) => {
        setItems(
            items
                .filter((e, i) => index !== i)
                .concat({ ...item, amount: item.amount - amount })
                .concat({ ...item, amount })
        );
    };

    return (
        <>
            {split && <SplitDialog item={item} open={split} onClose={onCloseSplit} onSplit={onSplit} />}

            <Box flexDirection='column' maxHeight={height}>
                {items.map((item: Item, index: number) =>
                    fulfillment && Boolean(fulfillment._id) ? (
                        <Box
                            sx={{
                                outline: 'none',
                                py: 1,
                            }}>
                            <Card
                                sx={{
                                    borderColor: 'primary.main',
                                }}
                                onClick={() => {
                                    if (item.amount === 1) return;

                                    setItem(item);
                                    setIndex(index);
                                    onOpenSplit();
                                }}
                                variant={'outlined'}>
                                <CardContent>
                                    <Grid container justifyContent='space-between' spacing={3}>
                                        <Grid item>
                                            <Typography color='textPrimary' variant='subtitle2'>
                                                {get(item, 'item.name')}
                                            </Typography>
                                            <Typography color='textSecondary' variant='body1'>
                                                {(get(item, 'item.aliases') || []).map((e) => e.name).join(', ')}
                                            </Typography>
                                            <Typography color='textSecondary' variant='body1'>
                                                {get(item, 'amount')}x
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Box>
                    ) : (
                        <Draggable draggableId={`${path}.${index}`} index={index} key={index}>
                            {(_provided, snapshot): JSX.Element => (
                                <Box
                                    ref={_provided.innerRef}
                                    sx={{
                                        outline: 'none',
                                        py: 1,
                                    }}
                                    {..._provided.draggableProps}
                                    {..._provided.dragHandleProps}>
                                    <Card
                                        raised={snapshot.isDragging}
                                        sx={{
                                            ...(snapshot.isDragging && {
                                                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                                            }),
                                            '&:hover': {
                                                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                                            },
                                            borderColor: 'primary.main',
                                        }}
                                        onClick={() => {
                                            if (item.amount === 1) return;

                                            setItem(item);
                                            setIndex(index);
                                            onOpenSplit();
                                        }}
                                        variant={snapshot.isDragging ? 'elevation' : 'outlined'}>
                                        <CardContent>
                                            <Grid container justifyContent='space-between' spacing={3}>
                                                <Grid item>
                                                    <Typography color='textPrimary' variant='subtitle2'>
                                                        {get(item, 'item.name')}
                                                    </Typography>
                                                    <Typography color='textSecondary' variant='body1'>
                                                        {(get(item, 'item.aliases') || []).map((e) => e.name).join(', ')}
                                                    </Typography>
                                                    <Typography color='textSecondary' variant='body1'>
                                                        {get(item, 'amount')}x
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Box>
                            )}
                        </Draggable>
                    )
                )}
            </Box>
        </>
    );
};

export default ItemsBox;
