import { get } from 'lodash';
import { FC, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';

import { Add, ArrowBack, ArrowForward, Delete } from '@mui/icons-material';
import { alpha, Divider, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';

import ItemsBox from './ItemsBox';

import RoleGuard from 'src/components/authentication/RoleGuard';
import { FulfillmentStatusLabel } from 'src/components/dashboard/FulfillmentStatusLabel';
import WarehouseLookupField from 'src/components/dashboard/warehouse/WarehouseAutocomplete';
import Scrollbar from 'src/components/Scrollbar';
import { ROLE } from 'src/constants/role';
import { Fulfillment } from 'src/types/fulfillment';
import { Item } from 'src/types/item';

interface Props {
    fulfillments: Fulfillment[];
    items: Item[];

    setFulfillments: (value: Fulfillment[]) => void;
    setIndex: (value: number) => void;
    setItems: (value: Item[]) => void;
}

const FulfillmentPaper: FC<Props> = (props) => {
    const { fulfillments, items, setFulfillments, setItems } = props;
    const [index, setFulfillmentIndex] = useState<number>(0);

    const setIndex = (value: number): void => {
        setFulfillmentIndex(value);
        props.setIndex(value);
    };

    return (
        <Paper
            elevation={10}
            sx={{
                flexDirection: 'column',
                maxHeight: '100%',
                mx: 1,
                overflowX: 'hidden',
                overflowY: 'hidden',
                width: {
                    xs: 300,
                    sm: 380,
                    lg: '50%',
                },
                backgroundColor: (theme) => alpha(theme.palette.background.default, 0.4),
            }}>
            {Boolean(fulfillments[index]._id) ? (
                <Box
                    sx={{
                        flexGrow: 1,
                        minHeight: 250,
                        px: 2,
                        py: 1,
                        overflowY: 'hidden',
                    }}>
                    <Grid container justifyContent='space-between' spacing={3}>
                        <Grid item>
                            <IconButton disabled={index === 0} onClick={() => setIndex(index - 1)}>
                                <ArrowBack />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton disabled={index === fulfillments.length - 1} onClick={() => setIndex(index + 1)}>
                                <ArrowForward />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Typography color='textPrimary' variant='h4' align='center'>
                        Sendung #{index + 1}
                    </Typography>
                    {fulfillments[index].status && (
                        <>
                            <Typography color='textSecondary' variant='body2' align='center'>
                                Sendung bereits erstellt
                            </Typography>
                            <Box sx={{ mt: 1, justifyContent: 'center', display: 'flex' }}>
                                {FulfillmentStatusLabel(fulfillments[index])}
                            </Box>
                        </>
                    )}
                    <Box textAlign='center'>
                        <Tooltip title='Sendung löschen'>
                            <IconButton
                                color='default'
                                disabled={Boolean(fulfillments[index]._id)}
                                sx={{ mr: 1, mt: 1, mb: 1 }}
                                onClick={() => {
                                    const fulfillmentItems = get(fulfillments, index).items;

                                    setIndex(0);
                                    setItems(items.concat(fulfillmentItems));
                                    setFulfillments(fulfillments.filter((e, fulfillmentIndex) => fulfillmentIndex !== index));
                                }}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Scrollbar>
                        <RoleGuard roles={[ROLE.SUPERADMIN, ROLE.ADMIN]}>
                            <Box sx={{ ml: 1.5, mr: 1.5, mt: 1 }}>
                                <WarehouseLookupField
                                    disabled={Boolean(fulfillments[index]._id)}
                                    key={index}
                                    warehouse={fulfillments[index].warehouse}
                                    setWarehouse={(value) =>
                                        setFulfillments(
                                            fulfillments.map((fulfillment, fulfillmentIndex) => {
                                                if (index !== fulfillmentIndex) return fulfillment;

                                                return {
                                                    ...fulfillment,
                                                    warehouse: value._id,
                                                };
                                            })
                                        )
                                    }
                                />
                            </Box>
                            <Divider sx={{ m: 2, ml: 3, mr: 3 }} />
                        </RoleGuard>
                        <Box sx={{ ml: 1.5, mr: 1.5 }}>
                            <ItemsBox
                                path={`fulfillment.${index}`}
                                fulfillment={fulfillments[index]}
                                height='45vh'
                                items={fulfillments[index].items}
                                setItems={(items) =>
                                    setFulfillments(
                                        fulfillments.map((fulfillment, fulfillmentIndex) => {
                                            if (index !== fulfillmentIndex) return fulfillment;

                                            return {
                                                ...fulfillment,
                                                items,
                                            };
                                        })
                                    )
                                }
                            />
                        </Box>
                    </Scrollbar>
                </Box>
            ) : (
                <Droppable droppableId={`fulfillments.${index}`} type='card'>
                    {(provided): JSX.Element => (
                        <Box
                            ref={provided.innerRef}
                            sx={{
                                flexGrow: 1,
                                minHeight: 250,
                                px: 2,
                                py: 1,
                                overflowY: 'hidden',
                            }}>
                            <Grid container justifyContent='space-between' spacing={3}>
                                <Grid item>
                                    <IconButton disabled={index === 0} onClick={() => setIndex(index - 1)}>
                                        <ArrowBack />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <IconButton disabled={index === fulfillments.length - 1} onClick={() => setIndex(index + 1)}>
                                        <ArrowForward />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Typography color='textPrimary' variant='h4' align='center'>
                                Sendung #{index + 1}
                            </Typography>
                            {fulfillments[index].status && (
                                <>
                                    <Typography color='textSecondary' variant='body2' align='center'>
                                        Sendung bereits erstellt
                                    </Typography>
                                    <Box sx={{ mt: 1, justifyContent: 'center', display: 'flex' }}>
                                        {FulfillmentStatusLabel(fulfillments[index])}
                                    </Box>
                                </>
                            )}
                            <Box textAlign='center'>
                                <Tooltip title='Sendung löschen'>
                                    <IconButton
                                        color='default'
                                        disabled={Boolean(fulfillments[index]._id)}
                                        sx={{ mr: 1, mt: 1, mb: 1 }}
                                        onClick={() => {
                                            const fulfillmentItems = get(fulfillments, index).items;

                                            setIndex(0);
                                            setItems(items.concat(fulfillmentItems));

                                            if (fulfillments.length === 1) {
                                                setFulfillments(
                                                    fulfillments
                                                        .concat({ items: [], warehouse: null })
                                                        .filter((e, fulfillmentIndex) => fulfillmentIndex !== index)
                                                );
                                            } else {
                                                setFulfillments(fulfillments.filter((e, fulfillmentIndex) => fulfillmentIndex !== index));
                                            }
                                        }}>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Scrollbar>
                                <RoleGuard roles={[ROLE.SUPERADMIN, ROLE.ADMIN]}>
                                    <Box sx={{ ml: 1.5, mr: 1.5, mt: 1 }}>
                                        <WarehouseLookupField
                                            disabled={Boolean(fulfillments[index]._id)}
                                            key={index}
                                            warehouse={fulfillments[index].warehouse}
                                            setWarehouse={(value) =>
                                                setFulfillments(
                                                    fulfillments.map((fulfillment, fulfillmentIndex) => {
                                                        if (index !== fulfillmentIndex) return fulfillment;

                                                        return {
                                                            ...fulfillment,
                                                            warehouse: value._id,
                                                        };
                                                    })
                                                )
                                            }
                                        />
                                    </Box>
                                    <Divider sx={{ m: 2, ml: 3, mr: 3 }} />
                                </RoleGuard>
                                <Box sx={{ ml: 1.5, mr: 1.5 }}>
                                    <ItemsBox
                                        path={`fulfillment.${index}`}
                                        fulfillment={fulfillments[index]}
                                        height='45vh'
                                        items={fulfillments[index].items}
                                        setItems={(items) =>
                                            setFulfillments(
                                                fulfillments.map((fulfillment, fulfillmentIndex) => {
                                                    if (index !== fulfillmentIndex) return fulfillment;

                                                    return {
                                                        ...fulfillment,
                                                        items,
                                                    };
                                                })
                                            )
                                        }
                                    />
                                </Box>
                                {fulfillments[index].items.length === 0 ? (
                                    <Box
                                        sx={{
                                            alignItems: 'center',
                                            border: 1,
                                            borderRadius: 1,
                                            borderColor: 'divider',
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            justifyContent: 'center',
                                            outline: 'none',
                                            p: 6,
                                            '&:hover': {
                                                backgroundColor: 'action.hover',
                                                cursor: 'pointer',
                                                opacity: 0.5,
                                            },
                                            mr: 1.5,
                                            ml: 1.5,
                                            mb: 1,
                                        }}>
                                        <Box
                                            sx={{
                                                '& img': {
                                                    width: 100,
                                                },
                                            }}>
                                            <Add />
                                        </Box>
                                    </Box>
                                ) : (
                                    <>{provided.placeholder}</>
                                )}
                            </Scrollbar>
                        </Box>
                    )}
                </Droppable>
            )}
        </Paper>
    );
};

export default FulfillmentPaper;
