import { FC, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { AddRoad, ChromeReaderMode, EmojiTransportation, Error, LocalShipping, MarkunreadMailbox, Notifications } from '@mui/icons-material';
import {
    Avatar, Badge, Box, IconButton, Link, List, ListItem, ListItemAvatar, ListItemText, Popover, Theme, Tooltip, Typography, useMediaQuery
} from '@mui/material';

import { useSelector } from 'src/store';

const NotificationsPopover: FC = () => {
    const { openOrders, openFulfillments, openCollections, deliveries, draftOrders, events, logs, productsToOrder, total } = useSelector(
        (state) => state.notifications
    );
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    return (
        <>
            <Tooltip title='Mitteilungen'>
                <IconButton color='inherit' ref={anchorRef} onClick={handleOpen} size={mdUp ? 'large' : 'small'}>
                    <Badge color='error' badgeContent={total}>
                        <Notifications />
                    </Badge>
                </IconButton>
            </Tooltip>

            <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: { width: 360 },
                }}>
                <Box sx={{ p: 2 }}>
                    <Typography color='textPrimary' variant='h6'>
                        Mitteilungen
                    </Typography>
                </Box>
                {total === 0 ? (
                    <Box sx={{ p: 2 }}>
                        <Typography color='textPrimary' variant='subtitle2'>
                            Keine Mitteilungen
                        </Typography>
                    </Box>
                ) : (
                    <>
                        <List disablePadding>
                            {openOrders > 0 && (
                                <ListItem
                                    divider={
                                        openFulfillments > 0 ||
                                        openCollections > 0 ||
                                        deliveries > 0 ||
                                        draftOrders > 0 ||
                                        logs > 0 ||
                                        productsToOrder > 0 ||
                                        events > 0
                                    }>
                                    <ListItemAvatar>
                                        <Avatar
                                            component={RouterLink}
                                            to='/dashboard/orders'
                                            sx={{
                                                backgroundColor: 'primary.main',
                                                color: 'primary.contrastText',
                                            }}>
                                            <LocalShipping fontSize='small' />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Link
                                                color='textPrimary'
                                                sx={{ cursor: 'pointer' }}
                                                component={RouterLink}
                                                to='/dashboard/orders'
                                                underline='none'
                                                variant='subtitle2'>
                                                Aufträge
                                            </Link>
                                        }
                                        secondary={`${openOrders} ${
                                            openOrders === 1 ? 'Offener Auftrag' : 'Offene Aufträge'
                                        } zu bearbeiten`}
                                    />
                                </ListItem>
                            )}
                            {openFulfillments > 0 && (
                                <ListItem
                                    divider={
                                        openCollections > 0 ||
                                        deliveries > 0 ||
                                        draftOrders > 0 ||
                                        logs > 0 ||
                                        productsToOrder > 0 ||
                                        events > 0
                                    }>
                                    <ListItemAvatar>
                                        <Avatar
                                            component={RouterLink}
                                            to='/dashboard/fulfillments'
                                            sx={{
                                                backgroundColor: 'primary.main',
                                                color: 'primary.contrastText',
                                            }}>
                                            <MarkunreadMailbox fontSize='small' />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Link
                                                color='textPrimary'
                                                sx={{ cursor: 'pointer' }}
                                                component={RouterLink}
                                                to='/dashboard/fulfillments'
                                                underline='none'
                                                variant='subtitle2'>
                                                Sendungen
                                            </Link>
                                        }
                                        secondary={`${openFulfillments} ${
                                            openFulfillments === 1 ? 'Offene Sendung' : 'Offene Sendungen'
                                        } zu bearbeiten`}
                                    />
                                </ListItem>
                            )}
                            {openCollections > 0 && (
                                <ListItem divider={deliveries > 0 || draftOrders > 0 || logs > 0 || productsToOrder > 0 || events > 0}>
                                    <ListItemAvatar>
                                        <Avatar
                                            component={RouterLink}
                                            to='/dashboard/collections'
                                            sx={{
                                                backgroundColor: 'primary.main',
                                                color: 'primary.contrastText',
                                            }}>
                                            <MarkunreadMailbox fontSize='small' />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Link
                                                color='textPrimary'
                                                sx={{ cursor: 'pointer' }}
                                                component={RouterLink}
                                                to='/dashboard/collections'
                                                underline='none'
                                                variant='subtitle2'>
                                                Läufe
                                            </Link>
                                        }
                                        secondary={`${openCollections} ${
                                            openCollections === 1 ? 'Offener Lauf' : 'Offene Läufe'
                                        } zu bearbeiten`}
                                    />
                                </ListItem>
                            )}
                            {deliveries > 0 && (
                                <ListItem divider={draftOrders > 0 || logs > 0 || productsToOrder > 0 || events > 0}>
                                    <ListItemAvatar>
                                        <Avatar
                                            component={RouterLink}
                                            to='/dashboard/deliveries'
                                            sx={{
                                                backgroundColor: 'primary.main',
                                                color: 'primary.contrastText',
                                            }}>
                                            <EmojiTransportation fontSize='small' />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Link
                                                component={RouterLink}
                                                to='/dashboard/deliveries'
                                                color='textPrimary'
                                                sx={{ cursor: 'pointer' }}
                                                underline='none'
                                                variant='subtitle2'>
                                                Anlieferungen
                                            </Link>
                                        }
                                        secondary={`${deliveries} ${
                                            deliveries === 1 ? 'Anlieferung kommt bald an' : 'Anlieferungen kommen bald an'
                                        }`}
                                    />
                                </ListItem>
                            )}
                            {draftOrders > 0 && (
                                <ListItem divider={logs > 0 || productsToOrder > 0 || events > 0}>
                                    <ListItemAvatar>
                                        <Avatar
                                            component={RouterLink}
                                            to='/dashboard/draft-orders'
                                            sx={{
                                                backgroundColor: 'primary.main',
                                                color: 'primary.contrastText',
                                            }}>
                                            <Error fontSize='small' />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Link
                                                color='textPrimary'
                                                sx={{ cursor: 'pointer' }}
                                                component={RouterLink}
                                                to='/dashboard/draft-orders'
                                                underline='none'
                                                variant='subtitle2'>
                                                Entwürfe
                                            </Link>
                                        }
                                        secondary={`${draftOrders} ${draftOrders === 1 ? 'Entwurf' : 'Entwürfe'} zu bearbeiten`}
                                    />
                                </ListItem>
                            )}
                            {events > 0 && (
                                <ListItem divider={logs > 0 || productsToOrder > 0}>
                                    <ListItemAvatar>
                                        <Avatar
                                            component={RouterLink}
                                            to='/dashboard/calendar'
                                            sx={{
                                                backgroundColor: 'primary.main',
                                                color: 'primary.contrastText',
                                            }}>
                                            <Error fontSize='small' />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Link
                                                color='textPrimary'
                                                sx={{ cursor: 'pointer' }}
                                                component={RouterLink}
                                                to='/dashboard/calendar'
                                                underline='none'
                                                variant='subtitle2'>
                                                Termine
                                            </Link>
                                        }
                                        secondary={`${events} ${events === 1 ? 'Termin' : 'Termine'} steht an`}
                                    />
                                </ListItem>
                            )}
                            {logs > 0 && (
                                <ListItem divider={productsToOrder > 0}>
                                    <ListItemAvatar>
                                        <Avatar
                                            component={RouterLink}
                                            to='/dashboard/logs'
                                            sx={{
                                                backgroundColor: 'primary.main',
                                                color: 'primary.contrastText',
                                            }}>
                                            <ChromeReaderMode fontSize='small' />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Link
                                                color='textPrimary'
                                                sx={{ cursor: 'pointer' }}
                                                component={RouterLink}
                                                to='/dashboard/logs'
                                                underline='none'
                                                variant='subtitle2'>
                                                Logs
                                            </Link>
                                        }
                                        secondary={`${logs} ${logs === 1 ? 'Log' : 'Logs'} zu überprüfen`}
                                    />
                                </ListItem>
                            )}
                            {productsToOrder > 0 && (
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar
                                            component={RouterLink}
                                            to='/dashboard/stock'
                                            sx={{
                                                backgroundColor: 'primary.main',
                                                color: 'primary.contrastText',
                                            }}>
                                            <AddRoad fontSize='small' />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Link
                                                component={RouterLink}
                                                to='/dashboard/stock'
                                                color='textPrimary'
                                                sx={{ cursor: 'pointer' }}
                                                underline='none'
                                                variant='subtitle2'>
                                                Nachbestellungen
                                            </Link>
                                        }
                                        secondary={`${productsToOrder} ${
                                            productsToOrder === 1 ? 'Nachbestellung' : 'Nachbestellungen'
                                        } ausstehend`}
                                    />
                                </ListItem>
                            )}
                        </List>
                    </>
                )}
            </Popover>
        </>
    );
};

export default NotificationsPopover;
