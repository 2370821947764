import { get } from 'lodash';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { clientApi } from 'src/api/client';
import { setError } from 'src/slices/error';
import { useDispatch } from 'src/store';
import { Client } from 'src/types/client';

import { Group } from '@mui/icons-material';
import { Autocomplete, CircularProgress, InputAdornment, TextField } from '@mui/material';

interface Props {
    client?: string;
    disabled?: boolean;

    setClient: (value: Client) => void;
}

const ClientAutocomplete: FC<Props> = ({ client, disabled, setClient }) => {
    const dispatch = useDispatch();

    const [selected, setSelected] = useState<Client>();
    const [results, setResults] = useState<Client[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');

    const fetchData = useCallback(async (): Promise<void> => {
        try {
            const results = await clientApi.find({
                search,
                select: 'name,credentials,return_address,warehouse,splits',
                sort: 'name',
            });

            setResults(results);
            setLoading(false);
        } catch (error) {
            dispatch(setError(error));
        }
    }, [search, dispatch]);

    const getClient = useCallback(async (): Promise<void> => {
        try {
            setLoading(true);

            const result = await clientApi.findById(client, {
                select: 'name,credentials,return_address,warehouse,splits',
            });

            setSelected(result);
            setLoading(false);
        } catch (error) {
            dispatch(setError(error));
        }
    }, [client, dispatch]);

    useEffect(() => {
        if (client) getClient();
    }, [client, getClient]);

    useEffect(() => {
        if (!loading) return;

        fetchData();
    }, [loading, fetchData]);

    useEffect(() => {
        if (selected) setClient(selected);
        if (selected === null) setClient(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    if (client && !selected)
        return (
            <Autocomplete
                key='1'
                disabled
                fullWidth
                options={[]}
                renderInput={(params): JSX.Element => (
                    <TextField fullWidth label='Kunde' variant='outlined' {...params} />
                )}
            />
        );

    return (
        <Autocomplete
            key='2'
            disabled={disabled}
            fullWidth
            getOptionLabel={(option) => get(option, 'name')}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            loading={loading}
            onChange={(x, value: Client) => {
                if (!value) setSelected(null);
                else setSelected(value);
            }}
            onClose={() => setLoading(false)}
            onOpen={() => setLoading(true)}
            options={results}
            value={selected}
            renderInput={(params): JSX.Element => (
                <TextField
                    onChange={(event) => {
                        if (event.target.value) {
                            setSearch(event.target.value);
                            setLoading(true);
                        } else {
                            setSelected(null);
                        }
                    }}
                    fullWidth
                    label='Kunde'
                    variant='outlined'
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position='start' sx={{ ml: 1 }}>
                                <Group />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <Fragment>
                                {loading ? <CircularProgress color='primary' size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default ClientAutocomplete;
