import { get } from 'lodash';
import moment from 'moment';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { CheckCircle } from '@mui/icons-material';
import { Box, Card, CardHeader, Divider, Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { Fulfillment } from 'src/types/fulfillment';

interface OrderDetailsProps {
    fulfillments: Fulfillment[];
}

const OrderFulfillments: FC<OrderDetailsProps> = ({ fulfillments }) => {
    return (
        <Box sx={{ mt: 2 }}>
            <Card sx={{ pb: 3 }}>
                <CardHeader title='Sendungen' />
                <Divider />
                <Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Lauf</TableCell>
                                <TableCell>Pakete</TableCell>
                                <TableCell>Exportiert</TableCell>
                                <TableCell>Lager</TableCell>
                                <TableCell>Datum</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fulfillments.map((fulfillment) => (
                                <TableRow key={fulfillment._id}>
                                    <TableCell>
                                        <Link color='inherit' component={RouterLink} to={`/dashboard/fulfillments/${fulfillment._id}`} variant='subtitle2'>
                                            {fulfillment._id}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        <Link
                                            color='inherit'
                                            component={RouterLink}
                                            to={`/dashboard/collections/${get(fulfillment, 'collection_id._id') || fulfillment.collection_id}`}
                                            variant='subtitle2'>
                                            {get(fulfillment, 'collection_id.name') || fulfillment.collection_id}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{fulfillment.parcels.length}</TableCell>
                                    <TableCell>{fulfillment.parcels.length > 0 && fulfillment.parcels.filter((e) => !e.exported).length === 0 && <CheckCircle />}</TableCell>
                                    <TableCell>
                                        <Link color='inherit' component={RouterLink} to={`/dashboard/warehouses/${get(fulfillment, 'warehouse._id') || fulfillment.warehouse}`} variant='subtitle2'>
                                            {get(fulfillment, 'warehouse.name') || fulfillment.warehouse}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{moment(fulfillment.created_at).format('LLL')}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </Card>
        </Box>
    );
};

export default OrderFulfillments;
