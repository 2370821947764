import { alpha } from '@mui/material';

import Label from 'src/components/dashboard/Label';
import { FULFILLMENT_STATUS_LABELS } from 'src/constants/fulfillment';
import { Fulfillment } from 'src/types/fulfillment';

export const FulfillmentStatusLabel = (fulfillment: Fulfillment): JSX.Element => {
    const isInReservation: boolean = fulfillment.status_name === 'reservation';

    const colorCode: string = FULFILLMENT_STATUS_LABELS[fulfillment.status_name]['colorCode'];
    const text: string = FULFILLMENT_STATUS_LABELS[fulfillment.status_name]['text'];

    return <Label colorCode={isInReservation ? `linear-gradient(90deg, ${alpha(colorCode, 0.8)} ${fulfillment.reserved}, ${alpha(colorCode, 0.3)} ${fulfillment.reserved})` : colorCode}>{text}</Label>;
};
