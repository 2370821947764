import { get, groupBy, sumBy, uniqBy } from 'lodash';
import moment from 'moment';
import { FC } from 'react';

import { Box, Card, CardHeader, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { Fulfillment } from 'src/types/fulfillment';

interface Page {
    date: string;
    orders: number;
    parcels: number;
    neutral: number;
    positions: number;
    picks: number;
    packaging_effort: number;
    health_care_boxes: number;
}

interface Props {
    fulfillments: Fulfillment[];
}

const AnalyticsDetails: FC<Props> = ({ fulfillments }) => {
    const grouped = groupBy(
        fulfillments.map((e) => ({
            ...e,
            date: moment(get(e, 'Versanddatum')).format('DD.MM.YYYY (dd)'),
        })),
        'date'
    );

    const pages: Page[] = Object.keys(grouped).map((k) => {
        return {
            date: k,
            orders: uniqBy(grouped[k], 'Auftragsnummer').length,
            parcels: grouped[k].length,
            neutral: grouped[k].filter((e) => get(e, 'Neutral')).length,
            positions: sumBy(grouped[k], 'Positionen'),
            picks: sumBy(grouped[k], 'Picks'),
            packaging_effort: grouped[k].filter((e) => !get(e, 'Eigenverpackung')).length,
            health_care_boxes: 0,
        };
    });

    return (
        <Card>
            <CardHeader
                disableTypography
                title={
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}>
                        <Typography color='textPrimary' variant='h6'>
                            Detaillierte Übersicht
                        </Typography>
                    </Box>
                }
            />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Datum</TableCell>
                        <TableCell>Aufträge</TableCell>
                        <TableCell>Pakete</TableCell>
                        <TableCell>Neutral</TableCell>
                        <TableCell>Positionen</TableCell>
                        <TableCell>Picks</TableCell>
                        <TableCell>Pflegepakete</TableCell>
                        <TableCell>Anbruch</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pages.map((page, index) => (
                        <TableRow
                            key={index}
                            sx={{
                                '&:last-child td': {
                                    border: 0,
                                },
                            }}>
                            <TableCell>{page.date}</TableCell>
                            <TableCell>{page.orders}</TableCell>
                            <TableCell>{page.parcels}</TableCell>
                            <TableCell>{page.neutral}</TableCell>
                            <TableCell>{page.positions}</TableCell>
                            <TableCell>{page.picks}</TableCell>
                            <TableCell>{page.health_care_boxes}</TableCell>
                            <TableCell>{page.packaging_effort}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    );
};

export default AnalyticsDetails;
