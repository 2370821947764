import { alpha } from '@mui/material';

import Label from 'src/components/dashboard/Label';
import { SHOP_LABELS } from 'src/constants/shop';

export const OriginLabel = (origin: any, active?: boolean): JSX.Element => {
    return (
        <Label
            sx={{ mt: 0.2, mb: 0.2, mr: 0.2 }}
            color={SHOP_LABELS[origin]['color']}
            colorCode={
                active || origin === 'api'
                    ? SHOP_LABELS[origin]['colorCode']
                    : alpha(SHOP_LABELS[origin]['colorCode'], 0.3)
            }>
            {SHOP_LABELS[origin]['text']}
        </Label>
    );
};
