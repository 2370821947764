import { FC, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { ChevronRight, Settings } from '@mui/icons-material';
import { Box, Breadcrumbs, Grid, IconButton, Link, Typography } from '@mui/material';

import AnalyticsExportButton from 'src/components/dashboard/analytics/AnalyticsExportButton';
import AnalyticsSettingsDialog, {
    AnalyticsSettingsDialogRef,
} from 'src/components/dashboard/analytics/AnalyticsSettingsButton';
import { AnalyticsSettings } from 'src/types/analytics-settings';
import { Client } from 'src/types/client';
import { Fulfillment } from 'src/types/fulfillment';

interface Props {
    loading: boolean;
    fulfillments: Fulfillment[];
    settings: AnalyticsSettings;

    setSettings: (value: AnalyticsSettings) => void;
}

const Headline: FC<Props> = ({ loading, fulfillments, settings, setSettings }) => {
    const ref = useRef<AnalyticsSettingsDialogRef>(null);

    return (
        <>
            <Grid container justifyContent='space-between' spacing={3}>
                <Grid item>
                    <Typography color='textPrimary' variant='h3'>
                        Auswertung
                    </Typography>
                    <Breadcrumbs aria-label='breadcrumb' separator={<ChevronRight fontSize='small' />} sx={{ mt: 1 }}>
                        <Link color='textPrimary' component={RouterLink} to='/dashboard' variant='subtitle2'>
                            Dashboard
                        </Link>
                        <Typography color='textSecondary' variant='subtitle2'>
                            Auswertung
                        </Typography>
                    </Breadcrumbs>

                    <Box
                        sx={{
                            mb: -1,
                            mx: -1,
                            mt: 1,
                        }}>
                        <AnalyticsExportButton
                            fulfillments={fulfillments}
                            start={settings.start}
                            end={settings.end}
                            client={settings.client as Client}
                            disabled={loading}
                        />
                    </Box>
                </Grid>

                <Grid item>
                    <IconButton size='large' disabled={loading} onClick={() => ref.current.onOpen()}>
                        <Settings />
                    </IconButton>
                </Grid>
            </Grid>

            <AnalyticsSettingsDialog ref={ref} settings={settings} setSettings={setSettings} />
        </>
    );
};

export default Headline;
