import { createContext, FC, ReactNode, useEffect, useState } from 'react';

import { THEMES } from 'src/constants/theme';

interface Settings {
    theme?: string;
}

export interface SettingsContextValue {
    settings: Settings;

    saveSettings: (update: Settings) => void;
}

const initialSettings: Settings = {
    theme: THEMES.LIGHT,
};

export const restoreSettings = (): Settings | null => {
    let settings = null;

    try {
        const storedData: string | null = window.localStorage.getItem('settings');

        if (storedData) {
            settings = JSON.parse(storedData);
        } else {
            settings = {
                theme: THEMES.LIGHT,
            };
        }
    } catch (err) {
        console.error(err);
        // If stored data is not a strigified JSON this will fail,
        // that's why we catch the error
    }

    return settings;
};

export const storeSettings = (settings: Settings): void => {
    window.localStorage.setItem('settings', JSON.stringify(settings));
};

const SettingsContext = createContext<SettingsContextValue>({
    settings: initialSettings,
    saveSettings: () => {},
});

interface Props {
    children?: ReactNode;
}

export const SettingsProvider: FC<Props> = (props) => {
    const { children } = props;

    const [settings, setSettings] = useState<Settings>(initialSettings);

    useEffect(() => {
        const restoredSettings = restoreSettings();

        if (restoredSettings) {
            setSettings(restoredSettings);
        }
    }, []);

    const saveSettings = (updatedSettings: Settings): void => {
        setSettings(updatedSettings);
        storeSettings(updatedSettings);
    };

    return (
        <SettingsContext.Provider
            value={{
                settings,
                saveSettings,
            }}>
            {children}
        </SettingsContext.Provider>
    );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
