import { lazy, Suspense } from 'react';

import AuthGuard from 'src/components/authentication/AuthGuard';
import GuestGuard from 'src/components/authentication/GuestGuard';
import DashboardLayout from 'src/components/dashboard/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import OrderDetails from 'src/pages/dashboard/OrderDetails';

const Loadable = (Component) => (props) =>
    (
        <Suspense fallback={<LoadingScreen />}>
            <Component {...props} />
        </Suspense>
    );

// Authentication pages

const Login = Loadable(lazy(() => import('src/pages/authentication/Login')));

// Dashboard pages

const Account = Loadable(lazy(() => import('src/pages/dashboard/Account')));
const Analytics = Loadable(lazy(() => import('src/pages/dashboard/Analytics')));
const AutomationCreate = Loadable(lazy(() => import('src/pages/dashboard/AutomationCreate')));
const AutomationDetails = Loadable(lazy(() => import('src/pages/dashboard/AutomationDetails')));
const AutomationEdit = Loadable(lazy(() => import('src/pages/dashboard/AutomationEdit')));
const AutomationList = Loadable(lazy(() => import('src/pages/dashboard/AutomationList')));
const Calendar = Loadable(lazy(() => import('src/pages/dashboard/Calendar')));
const ClientCreate = Loadable(lazy(() => import('src/pages/dashboard/ClientCreate')));
const ClientDetails = Loadable(lazy(() => import('src/pages/dashboard/ClientDetails')));
const ClientEdit = Loadable(lazy(() => import('src/pages/dashboard/ClientEdit')));
const ClientList = Loadable(lazy(() => import('src/pages/dashboard/ClientList')));
const CollectionCreate = Loadable(lazy(() => import('src/pages/dashboard/CollectionCreate')));
const CollectionDetails = Loadable(lazy(() => import('src/pages/dashboard/CollectionDetails')));
const CollectionEdit = Loadable(lazy(() => import('src/pages/dashboard/CollectionEdit')));
const CollectionList = Loadable(lazy(() => import('src/pages/dashboard/CollectionList')));
const DeliveryCreate = Loadable(lazy(() => import('src/pages/dashboard/DeliveryCreate')));
const DeliveryDetails = Loadable(lazy(() => import('src/pages/dashboard/DeliveryDetails')));
const DeliveryEdit = Loadable(lazy(() => import('src/pages/dashboard/DeliveryEdit')));
const DeliveryList = Loadable(lazy(() => import('src/pages/dashboard/DeliveryList')));
const DraftOrderEdit = Loadable(lazy(() => import('src/pages/dashboard/DraftOrderEdit')));
const DraftOrderList = Loadable(lazy(() => import('src/pages/dashboard/DraftOrderList')));
const Finance = Loadable(lazy(() => import('src/pages/dashboard/Finance')));
const FulfillmentDetails = Loadable(lazy(() => import('src/pages/dashboard/FulfillmentDetails')));
const FulfillmentList = Loadable(lazy(() => import('src/pages/dashboard/FulfillmentList')));
const HTTPLogDetails = Loadable(lazy(() => import('src/pages/dashboard/HTTPLogDetails')));
const HTTPLogList = Loadable(lazy(() => import('src/pages/dashboard/HTTPLogList')));
const Kanban = Loadable(lazy(() => import('src/pages/dashboard/Kanban')));
const LogDetails = Loadable(lazy(() => import('src/pages/dashboard/LogDetails')));
const LogList = Loadable(lazy(() => import('src/pages/dashboard/LogList')));
const ManufacturerCreate = Loadable(lazy(() => import('src/pages/dashboard/ManufacturerCreate')));
const ManufacturerDetails = Loadable(lazy(() => import('src/pages/dashboard/ManufacturerDetails')));
const ManufacturerEdit = Loadable(lazy(() => import('src/pages/dashboard/ManufacturerEdit')));
const ManufacturerList = Loadable(lazy(() => import('src/pages/dashboard/ManufacturerList')));
const NotFound = Loadable(lazy(() => import('src/pages/error/NotFound')));
const OrderCreate = Loadable(lazy(() => import('src/pages/dashboard/OrderCreate')));
const OrderEdit = Loadable(lazy(() => import('src/pages/dashboard/OrderEdit')));
const OrderList = Loadable(lazy(() => import('src/pages/dashboard/OrderList')));
const Overview = Loadable(lazy(() => import('src/pages/dashboard/Overview/index')));
const PackPresetCreate = Loadable(lazy(() => import('src/pages/dashboard/PackPresetCreate')));
const PackPresetDetails = Loadable(lazy(() => import('src/pages/dashboard/PackPresetDetails')));
const PackPresetEdit = Loadable(lazy(() => import('src/pages/dashboard/PackPresetEdit')));
const PackPresetList = Loadable(lazy(() => import('src/pages/dashboard/PackPresetList')));
const PackagingCreate = Loadable(lazy(() => import('src/pages/dashboard/PackagingCreate')));
const PackagingDetails = Loadable(lazy(() => import('src/pages/dashboard/PackagingDetails')));
const PackagingEdit = Loadable(lazy(() => import('src/pages/dashboard/PackagingEdit')));
const PackagingList = Loadable(lazy(() => import('src/pages/dashboard/PackagingList')));
const ProductCreate = Loadable(lazy(() => import('src/pages/dashboard/ProductCreate')));
const ProductDetails = Loadable(lazy(() => import('src/pages/dashboard/ProductDetails')));
const ProductEdit = Loadable(lazy(() => import('src/pages/dashboard/ProductEdit')));
const ProductList = Loadable(lazy(() => import('src/pages/dashboard/ProductList')));
const ResponseDetails = Loadable(lazy(() => import('src/pages/dashboard/ResponseDetails')));
const ResponseList = Loadable(lazy(() => import('src/pages/dashboard/ResponseList')));
const StorageCreate = Loadable(lazy(() => import('src/pages/dashboard/StorageCreate')));
const StorageDetails = Loadable(lazy(() => import('src/pages/dashboard/StorageDetails')));
const StorageEdit = Loadable(lazy(() => import('src/pages/dashboard/StorageEdit')));
const StorageList = Loadable(lazy(() => import('src/pages/dashboard/StorageList')));
const UserCreate = Loadable(lazy(() => import('src/pages/dashboard/UserCreate')));
const UserDetails = Loadable(lazy(() => import('src/pages/dashboard/UserDetails')));
const UserEdit = Loadable(lazy(() => import('src/pages/dashboard/UserEdit')));
const UserList = Loadable(lazy(() => import('src/pages/dashboard/UserList')));
const WarehouseCreate = Loadable(lazy(() => import('src/pages/dashboard/WarehouseCreate')));
const WarehouseDetails = Loadable(lazy(() => import('src/pages/dashboard/WarehouseDetails')));
const WarehouseEdit = Loadable(lazy(() => import('src/pages/dashboard/WarehouseEdit')));
const WarehouseList = Loadable(lazy(() => import('src/pages/dashboard/WarehouseList')));

const routes = [
    {
        path: 'dashboard',
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: '',
                element: <Overview />,
            },
            {
                path: 'account',
                element: <Account />,
            },
            {
                path: 'analytics',
                element: <Analytics />,
            },
            {
                path: 'calendar',
                element: <Calendar />,
            },
            {
                path: 'todo',
                element: <Kanban />,
            },
            {
                path: 'orders',
                children: [
                    {
                        path: '',
                        element: <OrderList />,
                    },
                    {
                        path: 'edit/:orderId',
                        element: <OrderEdit />,
                    },
                    {
                        path: ':orderId',
                        element: <OrderDetails />,
                    },
                    {
                        path: 'new',
                        element: <OrderCreate />,
                    },
                ],
            },
            {
                path: 'fulfillments',
                children: [
                    {
                        path: '',
                        element: <FulfillmentList />,
                    },
                    {
                        path: ':fulfillmentId',
                        element: <FulfillmentDetails />,
                    },
                ],
            },
            {
                path: 'collections',
                children: [
                    {
                        path: '',
                        element: <CollectionList />,
                    },
                    {
                        path: 'edit/:collectionId',
                        element: <CollectionEdit />,
                    },
                    {
                        path: ':collectionId',
                        element: <CollectionDetails />,
                    },
                    {
                        path: 'new',
                        element: <CollectionCreate />,
                    },
                ],
            },
            {
                path: 'draft-orders',
                children: [
                    {
                        path: '',
                        element: <DraftOrderList />,
                    },
                    {
                        path: 'edit/:orderId',
                        element: <DraftOrderEdit />,
                    },
                ],
            },
            {
                path: 'products',
                children: [
                    {
                        path: '',
                        element: <ProductList />,
                    },
                    {
                        path: 'edit/:productId',
                        element: <ProductEdit />,
                    },
                    {
                        path: ':productId',
                        element: <ProductDetails />,
                    },
                    {
                        path: 'new',
                        element: <ProductCreate />,
                    },
                ],
            },
            {
                path: 'storages',
                children: [
                    {
                        path: '',
                        element: <StorageList />,
                    },
                    {
                        path: 'edit/:storageId',
                        element: <StorageEdit />,
                    },
                    {
                        path: ':storageId',
                        element: <StorageDetails />,
                    },
                    {
                        path: 'new',
                        element: <StorageCreate />,
                    },
                ],
            },
            {
                path: 'packagings',
                children: [
                    {
                        path: '',
                        element: <PackagingList />,
                    },
                    {
                        path: 'edit/:packagingId',
                        element: <PackagingEdit />,
                    },
                    {
                        path: ':packagingId',
                        element: <PackagingDetails />,
                    },
                    {
                        path: 'new',
                        element: <PackagingCreate />,
                    },
                ],
            },
            {
                path: 'pack-presets',
                children: [
                    {
                        path: '',
                        element: <PackPresetList />,
                    },
                    {
                        path: 'edit/:packPresetId',
                        element: <PackPresetEdit />,
                    },
                    {
                        path: ':packPresetId',
                        element: <PackPresetDetails />,
                    },
                    {
                        path: 'new',
                        element: <PackPresetCreate />,
                    },
                ],
            },
            {
                path: 'deliveries',
                children: [
                    {
                        path: '',
                        element: <DeliveryList />,
                    },
                    {
                        path: 'edit/:deliveryId',
                        element: <DeliveryEdit />,
                    },
                    {
                        path: ':deliveryId',
                        element: <DeliveryDetails />,
                    },
                    {
                        path: 'new',
                        element: <DeliveryCreate />,
                    },
                ],
            },
            {
                path: 'clients',
                children: [
                    {
                        path: '',
                        element: <ClientList />,
                    },
                    {
                        path: 'edit/:clientId',
                        element: <ClientEdit />,
                    },
                    {
                        path: ':clientId',
                        element: <ClientDetails />,
                    },
                    {
                        path: 'new',
                        element: <ClientCreate />,
                    },
                ],
            },
            {
                path: 'manufacturers',
                children: [
                    {
                        path: '',
                        element: <ManufacturerList />,
                    },
                    {
                        path: 'edit/:manufacturerId',
                        element: <ManufacturerEdit />,
                    },
                    {
                        path: ':manufacturerId',
                        element: <ManufacturerDetails />,
                    },
                    {
                        path: 'new',
                        element: <ManufacturerCreate />,
                    },
                ],
            },
            {
                path: 'warehouses',
                children: [
                    {
                        path: '',
                        element: <WarehouseList />,
                    },
                    {
                        path: 'edit/:warehouseId',
                        element: <WarehouseEdit />,
                    },
                    {
                        path: ':warehouseId',
                        element: <WarehouseDetails />,
                    },
                    {
                        path: 'new',
                        element: <WarehouseCreate />,
                    },
                ],
            },
            {
                path: 'automations',
                children: [
                    {
                        path: '',
                        element: <AutomationList />,
                    },
                    {
                        path: 'edit/:automationId',
                        element: <AutomationEdit />,
                    },
                    {
                        path: ':automationId',
                        element: <AutomationDetails />,
                    },
                    {
                        path: 'new',
                        element: <AutomationCreate />,
                    },
                ],
            },
            {
                path: 'logs',
                children: [
                    {
                        path: '',
                        element: <LogList />,
                    },
                    {
                        path: ':logId',
                        element: <LogDetails />,
                    },
                ],
            },
            {
                path: 'responses',
                children: [
                    {
                        path: '',
                        element: <ResponseList />,
                    },
                    {
                        path: ':responseId',
                        element: <ResponseDetails />,
                    },
                ],
            },
            {
                path: 'http-logs',
                children: [
                    {
                        path: '',
                        element: <HTTPLogList />,
                    },
                    {
                        path: ':httpLogId',
                        element: <HTTPLogDetails />,
                    },
                ],
            },
            {
                path: 'users',
                children: [
                    {
                        path: '',
                        element: <UserList />,
                    },
                    {
                        path: 'edit/:userId',
                        element: <UserEdit />,
                    },
                    {
                        path: ':userId',
                        element: <UserDetails />,
                    },
                    {
                        path: 'new',
                        element: <UserCreate />,
                    },
                ],
            },
            {
                path: '*',
                element: <NotFound />,
            },
        ],
    },
    {
        path: '*',
        children: [
            {
                path: '',
                element: (
                    <GuestGuard>
                        <Login />
                    </GuestGuard>
                ),
            },
            {
                path: '*',
                element: <NotFound />,
            },
        ],
    },
];

export default routes;
