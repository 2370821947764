import { get, isEqual } from 'lodash';
import moment from 'moment';
import NProgress from 'nprogress';
import { FC, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { Box, Container, Grid } from '@mui/material';

import Headline from './Headline';

import { fulfillmentApi } from 'src/api/fulfillment';
import AnalyticsDetails from 'src/components/dashboard/analytics/AnalyticsDetails';
import AnalyticsGraph from 'src/components/dashboard/analytics/AnalyticsGraph';
import AnalyticsOrigins from 'src/components/dashboard/analytics/AnalyticsOrigins';
import AnalyticsTotal from 'src/components/dashboard/analytics/AnalyticsTotal';
import { ROLE } from 'src/constants/role';
import useAuth from 'src/hooks/useAuth';
import AuthorizationRequired from 'src/pages/error/AuthorizationRequired';
import { setError } from 'src/slices/error';
import { useDispatch } from 'src/store';
import { AnalyticsSettings } from 'src/types/analytics-settings';

export const ANALYTICS_ROLES = [ROLE.SUPERADMIN, ROLE.ADMIN];
const baseSettings = {
    start: new Date(moment().startOf('month').format('YYYY-MM-DD')),
    end: new Date(moment().endOf('month').format('YYYY-MM-DD')),
};

const Analytics: FC = () => {
    const { user } = useAuth();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState<boolean>(false);
    const [fulfillments, setFulfillments] = useState([]);
    const [settings, setSettings] = useState<AnalyticsSettings>(baseSettings);

    const fetchData = useCallback(async (): Promise<void> => {
        try {
            if (isEqual(baseSettings, settings)) return;

            setLoading(true);

            const fulfillments = await fulfillmentApi.analytics({
                ...settings,
                client: get(settings.client, '_id') || settings.client,
            });

            setFulfillments(fulfillments);
        } catch (error) {
            dispatch(setError(error));
        } finally {
            setLoading(false);
        }
    }, [settings, dispatch]);

    useEffect(() => {
        if (loading) {
            NProgress.start();

            return (): void => {
                NProgress.done();
            };
        }
    }, [loading]);

    useEffect(() => {
        fetchData();
    }, [settings, fetchData]);

    if (!ANALYTICS_ROLES.includes(user.role)) return <AuthorizationRequired />;

    return (
        <>
            <Helmet>
                <title>Auswertung - Shipman</title>
            </Helmet>

            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Headline
                        loading={loading}
                        fulfillments={fulfillments}
                        settings={settings}
                        setSettings={setSettings}
                    />

                    <Box sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xl={9} md={8} xs={12}>
                                <AnalyticsGraph fulfillments={fulfillments} />
                            </Grid>
                            <Grid item xl={3} md={4} xs={12}>
                                <AnalyticsTotal fulfillments={fulfillments} />
                            </Grid>
                            <Grid item xl={9} md={8} xs={12}>
                                <AnalyticsDetails fulfillments={fulfillments} />
                            </Grid>
                            <Grid item xl={3} md={4} xs={12}>
                                <AnalyticsOrigins fulfillments={fulfillments} />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default Analytics;
