import { get } from 'lodash';
import moment from 'moment';
import { forwardRef, useImperativeHandle, useState } from 'react';

import { Add, Delete, Settings } from '@mui/icons-material';
import {
    alpha,
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import ClientAutocomplete from '../client/ClientAutocomplete';

import { CARRIER_OPTIONS } from 'src/constants/carrier';
import { AnalyticsSettings, Mask } from 'src/types/analytics-settings';
import { Client } from 'src/types/client';

interface Props {
    settings: AnalyticsSettings;

    setSettings: (settings: AnalyticsSettings) => void;
}

export type AnalyticsSettingsDialogRef = {
    onOpen: () => void;
};

const AnalyticsSettingsDialog = forwardRef<AnalyticsSettingsDialogRef, Props>(({ settings, setSettings }, ref) => {
    const [open, setOpen] = useState<boolean>();

    const [start, setStart] = useState<Date>(
        settings.start || new Date(moment().startOf('month').format('YYYY-MM-DD'))
    );
    const [end, setEnd] = useState<Date>(settings.end || new Date(moment().endOf('month').format('YYYY-MM-DD')));
    const [client, setClient] = useState<Client>(settings.client as Client);
    const [carrier, setCarrier] = useState<string>(settings.carrier || '');
    const [masks, setMasks] = useState<Mask[]>(settings.masks || []);

    const onOpen = (): void => setOpen(true);
    const onClose = (): void => setOpen(false);
    const onSave = (): void => {
        setSettings({ start, end, client, carrier, masks });
        onClose();
    };

    useImperativeHandle(ref, () => ({
        onOpen,
    }));

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg'>
            <DialogTitle>
                <Box sx={{ display: 'flex', pt: 1 }}>
                    <Avatar
                        sx={{
                            backgroundColor: (theme) => alpha(theme.palette.info.main, 0.1),
                            color: 'info.main',
                            mr: 2,
                            height: 58,
                            width: 58,
                        }}>
                        <Settings fontSize='large' />
                    </Avatar>

                    <Box sx={{ mt: 1 }}>
                        <Typography color='textPrimary' variant='h4'>
                            Einstellungen
                        </Typography>
                    </Box>
                </Box>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <DesktopDatePicker
                            inputFormat='DD.MM.yyyy'
                            label='Von'
                            renderInput={(inputProps) => <TextField fullWidth variant='outlined' {...inputProps} />}
                            value={start}
                            onChange={(newDate) => {
                                if (newDate) setStart(newDate);
                                else setStart(null);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <DesktopDatePicker
                            inputFormat='DD.MM.yyyy'
                            label='Bis'
                            renderInput={(inputProps) => <TextField fullWidth variant='outlined' {...inputProps} />}
                            value={end}
                            onChange={(newDate) => {
                                if (newDate) setEnd(newDate);
                                else setEnd(null);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <ClientAutocomplete
                            client={get(client, '_id') || (client as string)}
                            setClient={(client) => setClient(client)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroup
                            defaultValue={carrier}
                            name='carrier'
                            sx={{ flexDirection: 'row' }}
                            onChange={(e) => setCarrier(e.target.value)}>
                            {[{ label: 'Alle', value: '' }, ...CARRIER_OPTIONS].map((option) => (
                                <FormControlLabel
                                    control={<Radio color='primary' sx={{ ml: 1 }} />}
                                    key={option.value}
                                    value={option.value}
                                    label={
                                        <Typography color='textPrimary' variant='body1'>
                                            {option.label}
                                        </Typography>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color='textPrimary' variant='h5'>
                            Masken
                        </Typography>
                    </Grid>
                    {masks.map((mask, index) => (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label='Name'
                                    name='name'
                                    variant='outlined'
                                    onChange={(e) =>
                                        setMasks((masks) =>
                                            masks.map((m, i) => (i === index ? { ...m, name: e.target.value } : m))
                                        )
                                    }
                                    value={mask.name}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    fullWidth
                                    label='Artikel enthalten'
                                    name='item_included'
                                    variant='outlined'
                                    onChange={(e) =>
                                        setMasks((masks) =>
                                            masks.map((m, i) =>
                                                i === index ? { ...m, item_included: e.target.value } : m
                                            )
                                        )
                                    }
                                    value={mask.item_included}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    fullWidth
                                    type='number'
                                    label='Artikel enthalten Prozent'
                                    name='item_included'
                                    variant='outlined'
                                    onChange={(e) =>
                                        setMasks((masks) =>
                                            masks.map((m, i) =>
                                                i === index
                                                    ? { ...m, item_included_percentage: parseInt(e.target.value) }
                                                    : m
                                            )
                                        )
                                    }
                                    value={mask.item_included_percentage}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    fullWidth
                                    label='Artikel nicht enthalten'
                                    name='item_included'
                                    variant='outlined'
                                    onChange={(e) =>
                                        setMasks((masks) =>
                                            masks.map((m, i) =>
                                                i === index ? { ...m, item_excluded: e.target.value } : m
                                            )
                                        )
                                    }
                                    value={mask.item_excluded}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    fullWidth
                                    type='number'
                                    label='Artikel nicht enthalten Prozent'
                                    name='item_included'
                                    variant='outlined'
                                    onChange={(e) =>
                                        setMasks((masks) =>
                                            masks.map((m, i) =>
                                                i === index
                                                    ? { ...m, item_excluded_percentage: parseInt(e.target.value) }
                                                    : m
                                            )
                                        )
                                    }
                                    value={mask.item_excluded_percentage}
                                />
                            </Grid>
                            {masks.length > 0 && index !== masks.length && (
                                <IconButton
                                    size='large'
                                    onClick={() => setMasks((masks) => masks.filter((m, i) => i !== index))}>
                                    <Delete />
                                </IconButton>
                            )}
                        </>
                    ))}
                    <IconButton
                        size='large'
                        onClick={() => {
                            setMasks((masks) =>
                                masks.concat({
                                    name: '',
                                    item_included: '',
                                    item_included_percentage: null,
                                    item_excluded: '',
                                    item_excluded_percentage: null,
                                })
                            );
                        }}>
                        <Add />
                    </IconButton>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button color='inherit' onClick={onClose}>
                    Abbrechen
                </Button>
                <Button
                    sx={{
                        backgroundColor: 'warning.main',
                        '&:hover': {
                            backgroundColor: 'warning.dark',
                        },
                    }}
                    variant='contained'
                    onClick={onSave}>
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default AnalyticsSettingsDialog;
