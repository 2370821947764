import FileSaver from 'file-saver';
import { get, groupBy, sumBy, uniq, uniqBy } from 'lodash';
import moment from 'moment';
import { FC, useState } from 'react';
import { utils, write } from 'xlsx';

import { CloudDownload, Download, Save } from '@mui/icons-material/';
import { Avatar, Box, Button, Container, Dialog, Paper, Radio, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

import { Client } from 'src/types/client';
import { Fulfillment } from 'src/types/fulfillment';

const typeOptions = [
    {
        title: 'Aufträge & Trackingnummern',
        description: 'Excel Datei (.xlsx) mit allen Aufträgen des Zeitraums, sowie Trackingnummern, Status und Datum',
        value: 'single',
    },
    {
        title: 'Tägliche Übersicht',
        description: 'Excel Datei (.xlsx) mit Täglicher Übersicht',
        value: 'daily',
    },
];

interface ExportDialogProps {
    open: boolean;
    fulfillments: Fulfillment[];
    client: Client;
    start: Date;
    end: Date;

    onClose: () => void;
}

const ExportDialog: FC<ExportDialogProps> = ({ open, fulfillments, client, start, end, onClose }) => {
    const [type, setType] = useState<string>(typeOptions[0].value);

    const formatDataSingle = (value: any[]): any[] => {
        return value.map((fulfillment) => ({
            Auftrag: get(fulfillment, 'Auftragsnummer'),
            'Externe ID': get(fulfillment, 'Externe ID'),
            Versanddatum: moment(get(fulfillment, 'Versanddatum')).format('DD.MM.YYYY'),
            Versanddienstleister: get(fulfillment, 'Versanddienstleister'),
            Trackingnummer: get(fulfillment, 'Trackingnummer'),
            Positionen: get(fulfillment, 'Positionen'),
            Picks: get(fulfillment, 'Picks'),
            Eigenverpackung: get(fulfillment, 'Eigenverpackung') ? 'JA' : '',
            Verpackung: get(fulfillment, 'Verpackung'),
            Maske: get(fulfillment, 'Maske'),
        }));
    };

    const formatDataDaily = (value: any[]): any[] => {
        const grouped = groupBy(
            value.map((e) => ({ ...e, date: moment(get(e, 'Versanddatum')).format('DD.MM.YYYY') })),
            'date'
        );

        return Object.keys(grouped).map((k) => {
            return {
                Datum: k,
                Aufträge: uniqBy(grouped[k], 'Auftragsnummer').length,
                Pakete: grouped[k].length,
                Neutral: grouped[k].filter((e) => e.Neutral).length,
                Positionen: sumBy(grouped[k], 'Positionen'),
                Picks: sumBy(grouped[k], 'Picks'),
                Anbruch: grouped[k].filter((e) => !e.Eigenverpackung).length,
                Pflegepakete: 0,
                ...uniq(grouped[k].map((e) => get(e, 'Maske'))).reduce(
                    (a, b) => ({
                        ...a,
                        [b]: grouped[k].filter((e) => get(e, 'Maske') === b).length,
                        [`${b} Positionen`]: sumBy(
                            grouped[k].filter((e) => get(e, 'Maske') === b),
                            'Positionen'
                        ),
                        [`${b} Picks`]: sumBy(
                            grouped[k].filter((e) => get(e, 'Maske') === b),
                            'Picks'
                        ),
                    }),
                    {}
                ),
                ...uniq(grouped[k].map((e) => get(e, 'Verpackung'))).reduce(
                    (a, b) => ({
                        ...a,
                        [b]: grouped[k].filter((e) => get(e, 'Verpackung') === b).length,
                    }),
                    {}
                ),
            };
        });
    };

    const handleExport = () => {
        var name = `Auswertung ${client.name} ${moment(start).format('DD.MM.YYYY')} - ${moment(end).format(
            'DD.MM.YYYY'
        )} `;
        var data = groupBy(fulfillments, 'Lager');
        var ws = utils.book_new();

        switch (type) {
            case 'single':
                name += 'Aufträge';
                Object.keys(data).forEach((key, index) => {
                    if (index === 0) {
                        utils.sheet_add_aoa(ws, [['Lager', key]], { origin: 'A1' });
                        utils.sheet_add_json(ws, formatDataSingle(data[key]), { origin: 'A2' });
                    } else {
                        utils.sheet_add_aoa(ws, [[]], { origin: -1 });
                        utils.sheet_add_aoa(ws, [['Lager', key]], { origin: -1 });
                        utils.sheet_add_json(ws, formatDataSingle(data[key]), { origin: -1 });
                    }
                });

                break;

            case 'daily':
                name += 'Tagesübersicht';
                Object.keys(data).forEach((key, index) => {
                    if (index === 0) {
                        utils.sheet_add_aoa(ws, [['Lager', key]], { origin: 'A1' });
                        utils.sheet_add_json(ws, formatDataDaily(data[key]), { origin: 'A2' });
                    } else {
                        utils.sheet_add_aoa(ws, [[]], { origin: -1 });
                        utils.sheet_add_aoa(ws, [['Lager', key]], { origin: -1 });
                        utils.sheet_add_json(ws, formatDataDaily(data[key]), { origin: -1 });
                    }
                });

                break;
        }

        var wb = { Sheets: { Daten: ws }, SheetNames: ['Daten'] };
        var excelBuffer = write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true });

        FileSaver.saveAs(
            new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
            name + '.xlsx'
        );
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth='xl'>
            <Box
                sx={{
                    minHeight: '100%',
                    p: 3,
                }}>
                <Container
                    maxWidth='xl'
                    sx={{
                        width: {
                            lg: 800,
                            sm: 500,
                            xs: 280,
                        },
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                        <Avatar
                            sx={{
                                backgroundColor: (theme) => alpha(theme.palette.success.main, 0.1),
                                color: 'success.main',
                                mb: 2,
                                height: 58,
                                width: 58,
                            }}>
                            <CloudDownload fontSize='large' />
                        </Avatar>
                        <Typography color='textPrimary' variant='h4'>
                            Export
                        </Typography>
                        <Box sx={{ mt: 2, width: '100%' }}>
                            {typeOptions.map((typeOption) => (
                                <Paper
                                    onClick={(): void => setType(typeOption.value)}
                                    key={typeOption.value}
                                    sx={{
                                        alignItems: 'flex-start',
                                        display: 'flex',
                                        mt: 2,
                                        mb: 2,
                                        padding: 2,
                                        width: '100%',
                                        '&:hover': {
                                            cursor: 'pointer',
                                        },
                                        backgroundColor: (theme) =>
                                            type === typeOption.value && alpha(theme.palette.success.main, 0.1),
                                        borderColor: type === typeOption.value && 'success.main',
                                    }}
                                    variant='outlined'>
                                    <Radio
                                        checked={type === typeOption.value}
                                        color='success'
                                        onClick={(): void => setType(typeOption.value)}
                                    />
                                    <Box sx={{ ml: 2 }}>
                                        <Typography color='textPrimary' variant='subtitle2'>
                                            {typeOption.title}
                                        </Typography>
                                        <Typography color='textSecondary' variant='body2'>
                                            {typeOption.description}
                                        </Typography>
                                    </Box>
                                </Paper>
                            ))}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mt: 4,
                        }}>
                        <Button
                            color='success'
                            fullWidth
                            size='large'
                            sx={{ mr: 2 }}
                            variant='outlined'
                            onClick={onClose}>
                            Abbrechen
                        </Button>
                        <Button
                            color='success'
                            fullWidth
                            size='large'
                            variant='contained'
                            startIcon={<Save fontSize='small' />}
                            onClick={handleExport}>
                            Herunterladen
                        </Button>
                    </Box>
                </Container>
            </Box>
        </Dialog>
    );
};

interface Props {
    disabled: boolean;
    fulfillments: Fulfillment[];
    client: Client;
    start: Date;
    end: Date;
}
const AnalyticsExportButton: FC<Props> = ({ disabled, fulfillments, client, start, end }) => {
    const [open, setOpen] = useState<boolean>(false);

    const onOpen = (): void => setOpen(true);
    const onClose = (): void => setOpen(false);

    return (
        <>
            <Button
                disabled={disabled || fulfillments.length === 0}
                color='primary'
                onClick={onOpen}
                startIcon={<Download fontSize='small' />}
                sx={{ m: 1 }}
                variant='text'>
                Export
            </Button>

            <ExportDialog
                open={open}
                fulfillments={fulfillments}
                client={client}
                start={start}
                end={end}
                onClose={onClose}
            />
        </>
    );
};

export default AnalyticsExportButton;
