import { get } from 'lodash';

export function getErrorMessage(error: any): string {
    var err = '';

    if (typeof error === 'string') err = error;
    if (get(error, 'message')) {
        if (Array.isArray(get(error, 'message'))) {
            err = get(error, 'message').join('; ');
        } else {
            err = get(error, 'message');
        }
    }
    if (get(error, 'error.exception.message')) err = get(error, 'error.exception.message');
    if (get(error, 'exception.message')) err = get(error, 'exception.message');
    if (get(error, 'exception.response')) err = Array.isArray(get(error, 'exception.response')) ? get(error, 'exception.response').join('; ') : get(error, 'exception.response');
    if (get(error, 'exception.response.message'))
        err = Array.isArray(get(error, 'exception.response.message')) ? get(error, 'exception.response.message').join('; ') : get(error, 'exception.response.message');

    return err;
}
