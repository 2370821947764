import { get } from 'lodash';
import moment from 'moment';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Order } from 'src/types/order';

import { Box, Grid, Link, Typography } from '@mui/material';

import { CarrierLabel } from '../../CarrierLabel';
import { OrderStatusLabel } from '../../OrderStatusLabel';
import { OriginLabel } from '../../OriginLabel';

interface Props {
    order: Order;
}

const HeaderGrid: FC<Props> = ({ order }) => (
    <Grid container spacing={1}>
        <Grid item xs={12}>
            <Box sx={{ mt: 2, p: 4, pt: 3 }}>
                <Grid container justifyContent='space-between'>
                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Link
                                    color='textPrimary'
                                    variant='h4'
                                    component={RouterLink}
                                    to={`/dashboard/clients/${get(order, 'client._id') || order.client}`}>
                                    {get(order, 'client.name') || order.client}
                                </Link>
                            </Grid>
                        </Grid>
                        {order.name !== order.data.id_external && (
                            <Typography color='textSecondary' variant='h6'>
                                {order.data.id_external}
                            </Typography>
                        )}
                        <Typography color='textSecondary' variant='body2'>
                            {moment(order.created_at).format('LLL')}
                        </Typography>
                    </Grid>
                    <Grid item sx={{ mt: 1 }}>
                        <Grid container spacing={0.5}>
                            <Grid item>{OrderStatusLabel(order)}</Grid>
                            <Grid item>{OriginLabel(order.data.origin_name)}</Grid>
                            <Grid item>{CarrierLabel(order.delivery.carrier)}</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
        <Grid item xs={12}>
            <Box sx={{ p: 4, pt: 3 }}>
                <Grid container justifyContent='space-between'>
                    <Grid item>
                        <Typography color='textPrimary' variant='h4'>
                            Empfänger
                        </Typography>
                        <Typography color='textSecondary' variant='h6'>
                            {order.delivery_address.name2
                                ? `${order.delivery_address.name} - ${order.delivery_address.name2}`
                                : order.delivery_address.name}
                        </Typography>
                        <Typography color='textSecondary' variant='body2'>
                            {`${order.delivery_address.street} ${order.delivery_address.house_number}`}
                        </Typography>
                        <Typography color='textSecondary' variant='body2'>
                            {`${order.delivery_address.postal_code} ${order.delivery_address.city}, ${order.delivery_address.country_code}`}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography color='textPrimary' variant='h4'>
                            Absender
                        </Typography>
                        <Typography color='textSecondary' variant='h6'>
                            {order.sender_address.name2
                                ? `${order.sender_address.name} - ${order.sender_address.name2}`
                                : order.sender_address.name}
                        </Typography>
                        <Typography color='textSecondary' variant='body2'>
                            {`${order.sender_address.street} ${order.sender_address.house_number}`}
                        </Typography>
                        <Typography color='textSecondary' variant='body2'>
                            {`${order.sender_address.postal_code} ${order.sender_address.city}, ${order.sender_address.country_code}`}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    </Grid>
);

export default HeaderGrid;
