export const ORDER_STATUS_LABELS = {
    cancelled: {
        text: 'Storniert',
        colorCode: '#43536b',
    },
    completed: {
        text: 'Abgeschlossen',
        colorCode: '#4CAF50',
    },
    fulfillment: {
        text: 'Fulfillment',
        colorCode: '#e0bc2b',
    },
    open: {
        text: 'Offen',
        colorCode: '#cf5d4e',
    },
};

export const ORDER_STATUS_OPTIONS = [
    {
        label: 'Alle',
        value: '',
    },
    {
        label: 'Offen',
        value: '1',
    },
    {
        label: 'Fulfillment',
        value: '2',
    },
    {
        label: 'Abgeschlossen',
        value: '3',
    },
    {
        label: 'Storniert',
        value: '4',
    },
];
