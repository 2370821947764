import { FC } from 'react';
import { Droppable } from 'react-beautiful-dnd';

import { alpha, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';

import ItemsBox from './ItemsBox';

import Scrollbar from 'src/components/Scrollbar';
import { Item } from 'src/types/item';

interface Props {
    items: Item[];

    setItems: (value: Item[]) => void;
}

const OrderPaper: FC<Props> = ({ items, setItems }) => (
    <Paper
        elevation={10}
        sx={{
            backgroundColor: (theme) => alpha(theme.palette.background.default, 0.4),
            flexDirection: 'column',
            maxHeight: '100%',
            mx: 1,
            overflowX: 'hidden',
            overflowY: 'hidden',
            width: {
                xs: 300,
                sm: 380,
                lg: '50%',
            },
        }}>
        <Droppable droppableId='order' type='card'>
            {(provided): JSX.Element => (
                <Box
                    ref={provided.innerRef}
                    sx={{
                        flexGrow: 1,
                        minHeight: 250,
                        px: 2,
                        py: 1,
                    }}>
                    <Typography color='textPrimary' variant='h4' align='center' sx={{ mb: 2 }}>
                        Artikel
                    </Typography>
                    <Scrollbar>
                        <Box sx={{ ml: 1.5, mr: 1.5 }}>
                            <ItemsBox path='order' height='58vh' items={items} setItems={setItems} />
                        </Box>

                        {provided.placeholder}
                    </Scrollbar>
                </Box>
            )}
        </Droppable>
    </Paper>
);

export default OrderPaper;
