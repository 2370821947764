import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { BorderColor, ChevronRight } from '@mui/icons-material';
import { Box, Breadcrumbs, Button, Divider, Grid, Link, Tab, Tabs, Theme, Typography, useMediaQuery } from '@mui/material';

import HistoryAlert from './HistoryAlert';

import CreateFulfillmentButton from 'src/components/dashboard/order/OrderSummary/CreateFulfillmentButton';
import { Fulfillment } from 'src/types/fulfillment';
import { Order } from 'src/types/order';

const tabs = [
    { label: 'Details', value: 'details' },
    { label: 'Sendungen', value: 'fulfillments' },
];

interface HeadlineProps {
    fulfillments: Fulfillment[];
    histories: Order[];
    order: Order;
    orderId: string;
    tab: string;

    onTabChange: (event: any, value: string) => void;
    refresh: () => Promise<void>;
    setOrder: (value: Order) => void;
}

const Headline: FC<HeadlineProps> = ({ fulfillments, histories, order, orderId, tab, onTabChange, refresh, setOrder }) => {
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    return (
        <>
            <Grid container justifyContent='space-between' spacing={3}>
                <Grid item>
                    <Typography color='textPrimary' variant={mdUp ? 'h2' : 'h1'}>
                        {order.name}
                    </Typography>
                    <Breadcrumbs
                        maxItems={mdUp ? 3 : 2}
                        aria-label='breadcrumb'
                        separator={<ChevronRight fontSize='small' />}
                        sx={{ mt: 1 }}>
                        <Link color='textPrimary' component={RouterLink} to='/dashboard' variant='subtitle2'>
                            Dashboard
                        </Link>
                        <Link color='textPrimary' component={RouterLink} to='/dashboard/orders' variant='subtitle2'>
                            Aufträge
                        </Link>
                        <Typography color='textSecondary' variant='subtitle2'>
                            {mdUp ? orderId : order.name}
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item>
                    <Box sx={{ m: -1, mt: { md: 1, xs: 0 } }}>
                        <CreateFulfillmentButton order={order} fulfillments={fulfillments} refresh={refresh} />

                        <Button
                            color='primary'
                            component={RouterLink}
                            startIcon={<BorderColor fontSize='small' />}
                            sx={{ m: 1 }}
                            to={`/dashboard/orders/edit/${orderId}`}
                            variant='contained'>
                            Bearbeiten
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <Box>
                <Tabs
                    indicatorColor='primary'
                    onChange={onTabChange}
                    scrollButtons='auto'
                    textColor='primary'
                    value={tab}
                    variant='scrollable'>
                    {tabs.map((tab) => (
                        <Tab
                            key={tab.value}
                            label={tab.label}
                            value={tab.value}
                            disabled={tab.value === 'fulfillments' && fulfillments.length === 0}
                        />
                    ))}
                </Tabs>
            </Box>

            <Divider />

            {tab === 'details' && <HistoryAlert orderId={orderId} order={order} histories={histories} setOrder={setOrder} />}
        </>
    );
};

export default Headline;
