import { Client } from 'src/types/client';
import axios from 'src/utils/axios';

class ClientApi {
    async find(params: any = {}): Promise<Client[]> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/clients', params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Clients Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async count(params: any = {}): Promise<number> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/clients', params: { ...params, pageSize: 1 } })
                .then((response) => {
                    resolve(Number(response.headers.totalcount));
                })
                .catch((error) => {
                    console.error('[Warehouses Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async findById(ID: string, params: any = {}): Promise<Client> {
        if (!ID) return null;
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/clients/' + ID, params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Clients Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async findInstance(ID: string): Promise<any> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/clients/instance/' + ID })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Clients Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async getStock(ID: string, start: Date, end: Date, details?: boolean): Promise<any> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'GET',
                url: '/clients/' + ID + '/stock',
                params: { start: start.toISOString(), end: end.toISOString(), ...(details && { details: true }) },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Clients Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async getSampleDeliveryNote(ID: string): Promise<any> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/clients/' + ID + '/delivery_note' })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Clients Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async getDeliveryNoteSample(data: Client): Promise<any> {
        return new Promise((resolve, reject) =>
            axios({ method: 'POST', url: '/clients/delivery_note_sample', data })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Clients Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async getSignatureSample(data: Client): Promise<any> {
        return new Promise((resolve, reject) =>
            axios({ method: 'POST', url: '/clients/signature_sample', data })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Clients Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async getHistory(ID: string, params: any = {}): Promise<Client[]> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/clients/' + ID + '/history', params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Clients Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async create(data: Client): Promise<Client> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'POST',
                url: '/clients',
                data,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Clients Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async update(ID: string, data: Client): Promise<Client> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: '/clients/' + ID,
                data,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Clients Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async delete(ID: string): Promise<Client> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'DELETE',
                url: '/clients/' + ID,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Clients Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }
}

export const clientApi = new ClientApi();
