import { get } from 'lodash';
import { FC } from 'react';

import { Box } from '@mui/material';

import FileCard from 'src/components/dashboard/FileCard';
import { Order } from 'src/types/order';

interface Props {
    order: Order;
}

const DocumentsBox: FC<Props> = ({ order }) => (
    <Box
        sx={{
            display: 'flex',
            flexWrap: 'wrap',
            mb: -1,
            mx: -1,
        }}>
        {order.data.documents.concat(order.original_file || []).map((file: any) => (
            <Box
                key={get(file, '_id')}
                sx={{
                    m: 1,
                    width: 240,
                }}>
                <FileCard file={file} />
            </Box>
        ))}
    </Box>
);

export default DocumentsBox;
