import { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import { Box, Card, CardContent, Container, Typography } from '@mui/material';

import LoginJWT from 'src/components/authentication/LoginJWT';
import useAuth from 'src/hooks/useAuth';

const Login: FC = () => {
    const { platform } = useAuth() as any;

    return (
        <>
            <Helmet>
                <title>Login - Shipman</title>
            </Helmet>

            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}>
                <Container maxWidth='sm' sx={{ py: '80px' }}>
                    <Card>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: 4,
                            }}>
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    mb: 3,
                                }}>
                                <div>
                                    <Typography color='textPrimary' gutterBottom variant='h4'>
                                        Shipman
                                    </Typography>
                                    <Typography color='textSecondary' variant='body2'>
                                        Login
                                    </Typography>
                                </div>
                                <Box
                                    sx={{
                                        height: 62,
                                        '& > img': {
                                            maxHeight: '100%',
                                            width: 'auto',
                                        },
                                    }}>
                                    <img alt='Shipman' src='/static/icons/shipman-filled.svg' />
                                </Box>
                            </Box>
                            <Box sx={{ flexGrow: 1, mt: 3 }}>{platform === 'JWT' && <LoginJWT />}</Box>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default Login;
