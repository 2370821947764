import { capitalize, startCase } from 'lodash';
import { FC, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { AccountCircle, Settings } from '@mui/icons-material';
import {
    Badge, Box, Button, ButtonBase, Divider, IconButton, ListItemIcon, ListItemText, MenuItem, Popover, styled, Theme, Tooltip, Typography, useMediaQuery
} from '@mui/material';

import useAuth from 'src/hooks/useAuth';
import { DARK_BG } from 'src/theme';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.main,
        boxShadow: `0 0 0 2px ${theme.palette.background.default}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.6s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const AccountPopover: FC = () => {
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleLogout = async (): Promise<void> => {
        try {
            handleClose();
            await logout();
            navigate('/');
        } catch (err) {
            console.error(err);
            toast.error('Unable to logout.');
        }
    };

    return (
        <>
            <Box
                component={ButtonBase}
                onClick={handleOpen}
                ref={anchorRef}
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    height: 32,
                    '& > img': {
                        maxHeight: '100%',
                        width: 'auto',
                    },
                }}>
                <Tooltip title='Einstellungen'>
                    <IconButton color='inherit' onClick={handleOpen} size={mdUp ? 'large' : 'small'}>
                        <StyledBadge overlap='circular' anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant='dot'>
                            <AccountCircle />
                        </StyledBadge>
                    </IconButton>
                </Tooltip>
            </Box>
            <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
                keepMounted
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: { width: 240 },
                }}>
                <Box sx={{ p: 2 }}>
                    <Typography color='textPrimary' variant='subtitle2'>
                        {startCase(user.username)}
                    </Typography>
                    <Typography color='textSecondary' variant='body2'>
                        {capitalize(user.role)}
                    </Typography>
                </Box>
                <Divider />
                <Box sx={{ mt: 2 }}>
                    <MenuItem onClick={handleClose} component={RouterLink} to='/dashboard/account'>
                        <ListItemIcon>
                            <Settings fontSize='small' />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography color='textPrimary' variant='subtitle2'>
                                    Einstellungen
                                </Typography>
                            }
                        />
                    </MenuItem>
                </Box>
                <Box sx={{ p: 2 }}>
                    <Button color='primary' fullWidth onClick={handleLogout} variant='outlined'>
                        Logout
                    </Button>
                </Box>
            </Popover>
        </>
    );
};

export default AccountPopover;
