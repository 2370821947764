import { FC } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';

import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';

import UserForm from 'src/components/dashboard/user/UserForm';
import { ROLE_LABELS } from 'src/constants/role';
import useAuth from 'src/hooks/useAuth';
import { User } from 'src/types/user';

interface Props {
    initialValue?: User;
    disabled?: boolean;

    onSave: (value: User) => void;
}

const AccountGeneralForm: FC<Props> = ({ initialValue, disabled, onSave }) => {
    const auth = useAuth();
    const navigate = useNavigate();

    const handleLogout = async (): Promise<void> => {
        try {
            await auth.logout();
            navigate('/');
        } catch (err) {
            console.error(err);
            toast.error('Unable to logout.');
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xl={3} lg={4} md={6} xs={12}>
                <Card>
                    <CardContent>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                textAlign: 'center',
                            }}>
                            <Box
                                sx={{
                                    height: 100,
                                    width: 100,
                                    bgcolor: ROLE_LABELS[auth.user.role].colorCode,
                                    pt: 1.5,
                                    borderRadius: 1.2,
                                }}>
                                <Box
                                    sx={{
                                        height: 100,
                                        '& > img': {
                                            maxHeight: '100%',
                                            width: 'auto',
                                            alignItems: 'center',
                                        },
                                    }}>
                                    <img alt='Profil' src='/static/icons/shipman-transparent-sm.svg' />
                                </Box>
                            </Box>
                            <Typography color='textPrimary' sx={{ mt: 1 }} variant='subtitle2'>
                                {auth.user.username}
                            </Typography>
                            <Typography color='textSecondary' variant='body2'>
                                Rolle: {ROLE_LABELS[auth.user.role].label}
                            </Typography>
                        </Box>
                    </CardContent>
                    <CardActions>
                        <Button onClick={handleLogout} color='primary' fullWidth variant='text'>
                            Logout
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid sx={{ mt: -2 }} item xl={9} lg={8} md={6} xs={12}>
                <UserForm disabled={disabled} initialValue={initialValue} onSave={onSave} />
            </Grid>
        </Grid>
    );
};

export default AccountGeneralForm;
