import { motion } from 'framer-motion';
import { shuffle } from 'lodash';
import moment from 'moment';
import { FC, useState } from 'react';
import ReactHowler from 'react-howler';
import { Link as RouterLink } from 'react-router-dom';
import Snowfall from 'react-snowfall';
import RedoLabelDailog from 'src/components/dashboard/RedoLabelDailog';
import { ROLE } from 'src/constants/role';
import { THEMES } from 'src/constants/theme';
import useSettings from 'src/hooks/useSettings';
import { setHelp } from 'src/slices/help';
import ItsTheMostWonderfulTime from 'src/sounds/christmas/Andy Williams - Its the Most Wonderful Time of the Year (Official Audio).mp3';
import RockinAroundTheChristmasTree from 'src/sounds/christmas/Brenda Lee - Rockin Around The Christmas Tree.mp3';
import JingleBellRock from 'src/sounds/christmas/Jingle Bell Rock.mp3';
import ItsBeginningToLookALotLikeChristmas from 'src/sounds/christmas/Michael Buble - Its Beginning to Look a Lot Like Christmas.mp3';
import LastChristmas from 'src/sounds/christmas/Wham - Last Christmas.mp3';
import Sound from 'src/sounds/click.mp3';
import { useDispatch, useSelector } from 'src/store';
import useSound from 'use-sound';

import { DocumentScanner, Help, Menu, NightsStay, PauseCircle, PlayCircle, WbSunny } from '@mui/icons-material';
import {
    AppBar,
    AppBarProps,
    Box,
    IconButton,
    Theme,
    Toolbar,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';

import RoleGuard from '../authentication/RoleGuard';
import AccountPopover from './AccountPopover';
import ContentSearch from './ContentSearch';
import NotificationsPopover from './NotificationsPopover';

interface DashboardNavbarProps extends AppBarProps {
    onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
    ...(theme.palette.mode === 'light' && {
        backgroundColor: theme.palette.background.default,
        boxShadow: 'none',
        color: theme.palette.text.primary,
    }),
    ...(theme.palette.mode === 'dark' && {
        backgroundColor: theme.palette.background.default,
        boxShadow: 'none',
    }),
}));

const songArray = shuffle([
    ItsTheMostWonderfulTime,
    RockinAroundTheChristmasTree,
    JingleBellRock,
    ItsBeginningToLookALotLikeChristmas,
    LastChristmas,
]);

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const dispatch = useDispatch();
    const help = useSelector((state) => state.help.help);
    const { onSidebarMobileOpen, ...other } = props;
    const { settings, saveSettings } = useSettings();
    const [selectedTheme, setSelectedTheme] = useState(settings.theme);
    const [sound] = useSound(Sound);
    const [Songs] = useState(songArray);

    const [playing, setPlaying] = useState<boolean>(false);

    const handleSwitch = () => {
        sound();

        setSelectedTheme((prevSelectedTheme) => {
            if (prevSelectedTheme === THEMES.LIGHT) {
                if (settings.theme === THEMES.LIGHT) {
                    saveSettings({ ...settings, theme: THEMES.DARK });
                    return THEMES.DARK;
                }

                return settings.theme;
            }

            saveSettings({ ...settings, theme: THEMES.LIGHT });
            return THEMES.LIGHT;
        });
    };

    return (
        <>
            {false && (
                <>
                    <ReactHowler
                        src={shuffle([
                            ItsTheMostWonderfulTime,
                            RockinAroundTheChristmasTree,
                            JingleBellRock,
                            ItsBeginningToLookALotLikeChristmas,
                            LastChristmas,
                        ])}
                        playing={playing}
                    />
                </>
            )}
            {false && (
                <>
                    <Snowfall
                        style={{ zIndex: 22200000, position: 'fixed', width: '100%' }}
                        snowflakeCount={70}
                        speed={[0.8, 1.5]}
                        wind={[-0.5, 0.5]}
                        radius={[0.5, 1.5]}
                    />
                </>
            )}
            <DashboardNavbarRoot {...other}>
                {false && (
                    <motion.div
                        initial={{ y: -10 }}
                        style={{ position: 'absolute', zIndex: -9999, opacity: 0.8 }}
                        animate={{
                            x: [280, window.innerWidth - 80, 280],
                            rotateY: 180,
                        }}
                        transition={{
                            x: { duration: 20, repeat: 300 },
                            rotateY: { duration: 0.2, delay: 10, repeat: 700, repeatDelay: 10 },
                        }}>
                        <img height='80px' alt='Components' src='/static/christmas/skating.gif' />
                    </motion.div>
                )}
                <Toolbar disableGutters variant='dense' sx={{ pt: 0.5 }}>
                    <IconButton
                        color='inherit'
                        onClick={() => onSidebarMobileOpen()}
                        sx={{
                            display: {
                                lg: 'none',
                            },
                        }}
                        size='large'>
                        <Menu fontSize='small' />
                    </IconButton>
                    <RouterLink to='/dashboard'>
                        <Box
                            sx={{
                                display: {
                                    lg: 'flex',
                                    xs: 'none',
                                },
                                height: 40,
                                width: 40,
                                '& > img': {
                                    maxHeight: '100%',
                                    width: 'auto',
                                },
                            }}>
                            <img alt='Shipman' src={'/static/icons/logo.png'} />
                        </Box>
                    </RouterLink>
                    {moment().format('DD.MM') === '30.03' && (
                        <Typography sx={{ mt: 4, ml: -1 }} variant='button' color='textSecondary'>
                            {moment().diff('1999-03-30', 'years', false)}
                        </Typography>
                    )}
                    <Box
                        sx={{
                            flexGrow: 1,
                            ml: 2,
                        }}
                    />
                    {false && (
                        <RoleGuard roles={[ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER, ROLE.WORKER]}>
                            <Box sx={{ ml: 1 }}>
                                <RedoLabelDailog />
                            </Box>
                        </RoleGuard>
                    )}
                    <RoleGuard roles={[ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER]}>
                        <ContentSearch />
                    </RoleGuard>
                    {false && (
                        <Box sx={{ ml: 1 }}>
                            <IconButton
                                color='primary'
                                onClick={() => {
                                    if (playing) {
                                        setPlaying(false);
                                        try {
                                        } catch (error) {
                                            console.log(error);
                                        }
                                    } else {
                                        setPlaying(true);
                                    }
                                }}
                                size='large'>
                                {playing ? <PauseCircle /> : <PlayCircle />}
                            </IconButton>
                        </Box>
                    )}
                    <Box sx={{ ml: 1 }}>
                        <Tooltip title={selectedTheme === THEMES.LIGHT ? 'dunkles Design' : 'helles Design'}>
                            <IconButton color='inherit' onClick={handleSwitch} size={mdUp ? 'large' : 'small'}>
                                {selectedTheme === THEMES.LIGHT ? <NightsStay /> : <WbSunny />}
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {false && (
                        <Box sx={{ ml: 1 }}>
                            <Tooltip title='Hilfe'>
                                <IconButton color='inherit' onClick={() => dispatch(setHelp(!help))} size='large'>
                                    <Help />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                    <RoleGuard roles={['superadmin', 'admin']}>
                        <Box sx={{ ml: 1 }}>
                            <NotificationsPopover />
                        </Box>
                    </RoleGuard>
                    <Box sx={{ ml: 1 }}>
                        <AccountPopover />
                    </Box>
                </Toolbar>
            </DashboardNavbarRoot>
        </>
    );
};

export default DashboardNavbar;
