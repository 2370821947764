import { startCase } from 'lodash';
import moment from 'moment';
import { FC } from 'react';

import { Grid, Typography } from '@mui/material';

import useAuth from 'src/hooks/useAuth';

const Headline: FC = () => {
    const { user } = useAuth();

    return (
        <Grid container justifyContent='space-between' spacing={3}>
            <Grid item>
                <Typography color='textSecondary' variant='overline'>
                    Shipman
                </Typography>
                <Typography color='textPrimary' variant='h3'>
                    Hallo, {startCase(user.username)}
                </Typography>
                <Typography color='textSecondary' variant='subtitle2'>
                    {moment().format('LLLL')} - KW {moment().isoWeek()}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Headline;
