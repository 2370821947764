import { Order } from 'src/types/order';
import axios from 'src/utils/axios';

class OrderApi {
    async analytics(params: any = {}): Promise<Order[]> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/orders/analytics', params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Orders Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async find(params: any = {}): Promise<Order[]> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/orders', params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Orders Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async count(params: any = {}): Promise<number> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/orders', params: { ...params, pageSize: 1 } })
                .then((response) => {
                    resolve(Number(response.headers.totalcount));
                })
                .catch((error) => {
                    console.error('[Orders Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async countTextSearch(search: string, params: any = {}): Promise<number> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/orders/search', params: { textSearch: search, ...params, pageSize: 1 } })
                .then((response) => {
                    resolve(Number(response.headers.totalcount));
                })
                .catch((error) => {
                    console.error('[Orders Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async textSearch(search: string, params: any = {}): Promise<Order[]> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/orders/search', params: { textSearch: search, ...params } })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Orders Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async findById(ID: string, params: any = {}): Promise<Order> {
        if (!ID) return null;
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/orders/' + ID, params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Orders Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async import(ID: string): Promise<any> {
        if (!ID) return null;
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/orders/import/' + ID })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Orders Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async check(ID: string): Promise<any> {
        if (!ID) return null;
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/orders/check/' + ID })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Orders Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async getHistory(ID: string, params: any = {}): Promise<Order[]> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/orders/' + ID + '/history', params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Orders Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async findAll(params: any = {}): Promise<Order[]> {
        const count = await this.count(params);

        var orders = [];
        var page = 1;

        while (orders.length < count) {
            const result = await this.find({ ...params, pageSize: 250, page });
            orders = [...orders, ...result];
            page += 1;
        }

        return orders;
    }

    async create(data: Order): Promise<Order> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'POST',
                url: '/orders',
                data,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Orders Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async update(ID: string, data: Order): Promise<Order> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: '/orders/' + ID,
                data,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Orders Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async reserve(ID: string): Promise<Order> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: '/orders/' + ID + '/reserve',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Orders Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? { order: ID, error: error.response.data } : { order: ID, error });
                })
        );
    }

    async bulkReserve(IDs: string[]): Promise<{ success: string[]; failed: { order: string; error: any }[] }> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'POST',
                url: '/orders/reserve',
                data: { orderIds: IDs },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Orders Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? { order: IDs, error: error.response.data } : { order: IDs, error });
                })
        );
    }

    async reset(ID: string): Promise<Order> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: '/orders/' + ID + '/reset',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Orders Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async cancel(ID: string): Promise<Order> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: '/orders/' + ID + '/cancel',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Orders Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async export(ID: string): Promise<Order> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: '/orders/' + ID + '/export',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Orders Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async delete(ID: string): Promise<Order> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'DELETE',
                url: '/orders/' + ID,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Orders Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }
}

export const orderApi = new OrderApi();
