import { FC } from 'react';
import { useNavigate } from 'react-router';
import { Collection } from 'src/types/collection';

import { alpha, Card, CardContent, Grid, Typography } from '@mui/material';

interface Props {
    loading?: boolean;
    collections: Collection[];
}

const OverviewWorker: FC<Props> = ({ loading, collections }) => {
    const navigate = useNavigate();

    return (
        <Grid container spacing={2}>
            {collections.length === 0 && !loading && (
                <Grid item lg={6} xs={12}>
                    <Typography sx={{ mt: 5 }} color='textPrimary' variant='h3'>
                        Keine Läufe zugewiesen.
                    </Typography>
                </Grid>
            )}
            {collections.map((collection) => (
                <Grid item lg={6} xs={12}>
                    <Card
                        sx={{
                            '&:hover': {
                                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.2),
                                cursor: 'pointer',
                            },
                        }}
                        onClick={() => navigate(`/dashboard/collections/${collection._id}`)}>
                        <CardContent sx={{ mt: 3, mb: 3, ml: 2 }}>
                            <Grid container spacing={2} direction='column' alignItems='center' justifyContent='center'>
                                <Grid item xs={12}>
                                    <Typography color='textPrimary' variant='h3'>
                                        {collection.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color='textSecondary' variant='h6'>
                                        {collection.stats?.total} Aufträge
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default OverviewWorker;
