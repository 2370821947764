import { includes } from 'lodash';
import NProgress from 'nprogress';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';

import { Box, Container, Divider, Tab, Tabs } from '@mui/material';

import Headline from './Headline';

import { userApi } from 'src/api/user';
import AccountGeneralForm from 'src/components/dashboard/account/AccountGeneralForm';
import AccountPasswordChange from 'src/components/dashboard/account/AccountPasswordChange';
import LoadingDashboard from 'src/components/dashboard/LoadingDashboard';
import { ROLE } from 'src/constants/role';
import useAuth from 'src/hooks/useAuth';
import AuthorizationRequired from 'src/pages/error/AuthorizationRequired';
import { setError } from 'src/slices/error';
import { useDispatch } from 'src/store';
import { User } from 'src/types/user';

const tabs = [
    { label: 'Profil', value: 'profile' },
    { label: 'Passwort', value: 'security' },
];

export const ACCOUNT_ROLES = [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER, ROLE.WORKER, ROLE.CLIENT];

const Account: FC = () => {
    const dispatch = useDispatch();
    const {
        user: { _id, role },
    } = useAuth();

    const [currentTab, setCurrentTab] = useState<string>('profile');
    const [loading, setLoading] = useState<boolean>(true);
    const [user, setUser] = useState<User>();

    const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };

    const fetchData = useCallback(async (): Promise<void> => {
        try {
            setLoading(true);

            const data = await userApi.findById(_id, { populate: 'client:name,warehouse:name' });

            setUser(data);
        } catch (error) {
            dispatch(setError(error));
        } finally {
            setLoading(false);
        }
    }, [_id, dispatch]);

    useEffect(() => {
        if (loading) {
            NProgress.start();

            return (): void => {
                NProgress.done();
            };
        }
    }, [loading]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onSave = async (user: User): Promise<void> => {
        try {
            setLoading(true);

            await userApi.update(_id, user);

            toast.success('Benutzer gespeichert');
            window.history.back();
        } catch (error) {
            dispatch(setError(error));
        } finally {
            setLoading(false);
        }
    };

    if (!ACCOUNT_ROLES.includes(role)) return <AuthorizationRequired />;
    if (!user) return <LoadingDashboard />;

    return (
        <>
            <Helmet>
                <title>Account - Shipman</title>
            </Helmet>

            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Headline />

                    <Box sx={{ mt: 3 }}>
                        <Tabs
                            indicatorColor='primary'
                            onChange={handleTabsChange}
                            scrollButtons='auto'
                            textColor='primary'
                            value={currentTab}
                            variant='scrollable'>
                            {tabs.map((tab) => (
                                <Tab key={tab.value} label={tab.label} value={tab.value} />
                            ))}
                        </Tabs>
                    </Box>

                    <Divider />

                    <Box sx={{ mt: 3 }}>
                        {currentTab === 'profile' && (
                            <AccountGeneralForm
                                disabled={!includes([ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER], user.role)}
                                initialValue={user}
                                onSave={onSave}
                            />
                        )}
                        {currentTab === 'security' && <AccountPasswordChange />}
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default Account;
