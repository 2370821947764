import { FC } from 'react';

import { Box, Card, Grid } from '@mui/material';

import DocumentsBox from './DocumentsBox';
import HeaderGrid from './HeaderGrid';
import ProductGrid from './ProductGrid';

import { Order } from 'src/types/order';

interface Props {
    order: Order;
}

const OrderSummary: FC<Props> = ({ order }) => {
    return (
        <>
            <Card sx={{ mt: 1 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <HeaderGrid order={order} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ p: 4, pb: 2 }}>
                            <ProductGrid order={order} />
                        </Box>
                    </Grid>
                </Grid>
            </Card>
            <Box sx={{ mb: 4 }}>
                <DocumentsBox order={order} />
            </Box>
        </>
    );
};

export default OrderSummary;
