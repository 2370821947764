import { Formik } from 'formik';
import { FC } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { Box, Button, Card, CardContent, CardHeader, Divider, FormHelperText, Grid, TextField } from '@mui/material';

import { userApi } from 'src/api/user';
import useAuth from 'src/hooks/useAuth';

const AccountPasswordChange: FC = (props) => {
    const { user } = useAuth();

    return (
        <Formik
            initialValues={{
                password: '',
                passwordConfirm: '',
                submit: null,
            }}
            validationSchema={Yup.object().shape({
                password: Yup.string().min(5, 'Muss mindesten 5 Zeichen lang sein').max(255).required('Pflicht'),
                passwordConfirm: Yup.string()
                    .oneOf([Yup.ref('password'), null], 'Passwörter müssen übereinstimmen')
                    .required('Pflicht'),
            })}
            onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }): Promise<void> => {
                try {
                    await userApi.update(user._id, { password: values.password });
                    resetForm();
                    setStatus({ success: true });
                    setSubmitting(false);
                    toast.success('Password updated!');
                } catch (err) {
                    console.error(err);
                    toast.error('Fehler ');
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }}>
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }): JSX.Element => (
                <form onSubmit={handleSubmit} {...props}>
                    <Card>
                        <CardHeader title='Passwort ändern' />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={4} sm={6} xs={12}>
                                    <TextField
                                        error={Boolean(touched.password && errors.password)}
                                        fullWidth
                                        helperText={touched.password && errors.password}
                                        label='Passwort'
                                        name='password'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type='password'
                                        value={values.password}
                                        variant='outlined'
                                    />
                                </Grid>
                                <Grid item md={4} sm={6} xs={12}>
                                    <TextField
                                        error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
                                        fullWidth
                                        helperText={touched.passwordConfirm && errors.passwordConfirm}
                                        label='Passwort bestätigen'
                                        name='passwordConfirm'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type='password'
                                        value={values.passwordConfirm}
                                        variant='outlined'
                                    />
                                </Grid>
                            </Grid>
                            {errors.submit && (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Box>
                            )}
                        </CardContent>
                        <Divider />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 2,
                            }}>
                            <Button color='primary' disabled={isSubmitting} type='submit' variant='contained'>
                                Passwort ändern
                            </Button>
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

export default AccountPasswordChange;
