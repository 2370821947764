import { FC } from 'react';
import { useSelector } from 'src/store';

import { Backdrop, CircularProgress } from '@mui/material';

const LoadingBackdrop: FC = () => {
    const { backdrop } = useSelector((state) => state.loading);

    return (
        <Backdrop
            open={backdrop}
            sx={{
                color: 'primary.main',
                zIndex: (theme) => theme.zIndex.drawer + 10000,
            }}>
            <CircularProgress color='inherit' />
        </Backdrop>
    );
};

export default LoadingBackdrop;
