import moment from 'moment';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { calendarApi } from 'src/api/calendar';
import { collectionApi } from 'src/api/collection';
import { deliveryApi } from 'src/api/delivery';
import { draftOrderApi } from 'src/api/draft-order';
import { fulfillmentApi } from 'src/api/fulfillment';
import { logApi } from 'src/api/log';
import { orderApi } from 'src/api/order';
import { AppThunk } from 'src/store';

interface NotificationState {
    openOrders: number;
    openFulfillments: number;
    openCollections: number;
    deliveries: number;
    draftOrders: number;
    events: number;
    logs: number;
    productsToOrder: number;
    total?: number;
}

const initialState: NotificationState = {
    openOrders: 0,
    openFulfillments: 0,
    openCollections: 0,
    deliveries: 0,
    draftOrders: 0,
    events: 0,
    logs: 0,
    productsToOrder: 0,
    total: 0,
};

const slice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        getNotifications(state: NotificationState, action: PayloadAction<NotificationState>): void {
            state.openOrders = action.payload.openOrders;
            state.openFulfillments = action.payload.openFulfillments;
            state.openCollections = action.payload.openCollections;
            state.deliveries = action.payload.deliveries;
            state.draftOrders = action.payload.draftOrders;
            state.events = action.payload.events;
            state.productsToOrder = action.payload.productsToOrder;
            state.logs = action.payload.logs;
            state.total =
                action.payload.openOrders +
                action.payload.openFulfillments +
                action.payload.openCollections +
                action.payload.deliveries +
                action.payload.draftOrders +
                action.payload.events +
                action.payload.logs +
                action.payload.productsToOrder;
        },
    },
});

export const { reducer } = slice;

export const getNotifications =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        const openOrders = await orderApi.count({ 'fulfilled!': '100.00%', 'status_name!': 'cancelled' });
        const openFulfillments = await fulfillmentApi.count({ 'status_name!': 'completed', collection_id: 'null' });
        const openCollections = await collectionApi.count({ completed: false });
        const deliveries = await deliveryApi.count({
            'planned_arrival>': moment().format('YYYY-MM-DD'),
            'planned_arrival<': moment().add(7, 'days').format('YYYY-MM-DD'),
        });
        const draftOrders = await draftOrderApi.count();
        const events = await calendarApi.count({
            'start<': moment().add(2, 'days').format('YYYY-MM-DD'),
            'start>': moment().format('YYYY-MM-DD'),
        });
        const logs = await logApi.count({ read: false });
        const productsToOrder = 0;

        dispatch(
            slice.actions.getNotifications({
                openOrders,
                openFulfillments,
                openCollections,
                deliveries,
                draftOrders,
                events,
                logs,
                productsToOrder,
            })
        );
    };

export default slice;
