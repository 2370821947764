import { find, get, orderBy } from 'lodash';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Divider, Grid, Link, Typography } from '@mui/material';

import { Order } from 'src/types/order';

interface Props {
    order: Order;
}

const ProductGrid: FC<Props> = ({ order }) => (
    <Grid container spacing={1}>
        <Grid item xs={12} sx={{ mb: 1 }}>
            <Typography color='textPrimary' variant='h4'>
                Produkte
            </Typography>
        </Grid>
        {order.items.map((item, index) => (
            <Grid item xs={12} sx={{ ml: { xl: 3 }, mr: { xl: 3 } }} key={index}>
                <Grid container justifyContent='space-between' sx={{ mb: 1 }}>
                    <Grid item>
                        {item.item ? (
                            <Link
                                color='textPrimary'
                                variant='subtitle2'
                                component={RouterLink}
                                to={`/dashboard/products/${get(item, 'item._id') || item.item}`}>
                                {get(item, 'item.name') || item.item}
                            </Link>
                        ) : (
                            <Typography color='textPrimary' variant='subtitle2'>
                                UNBEKANNT
                            </Typography>
                        )}
                        <Typography color='textSecondary' variant='body2'>
                            {get(item, 'item.aliases')
                                ? find(orderBy(get(item, 'item.aliases'), 'amount', 'desc'), function (o) {
                                      return item.amount >= o.amount && item.amount % o.amount === 0 && o.pick;
                                  })
                                    ? `${
                                          item.amount /
                                          get(
                                              find(orderBy(get(item, 'item.aliases'), 'amount', 'desc'), function (o) {
                                                  return (
                                                      item.amount >= o.amount && item.amount % o.amount === 0 && o.pick
                                                  );
                                              }),
                                              'amount'
                                          )
                                      }x "${
                                          get(
                                              find(orderBy(get(item, 'item.aliases'), 'amount', 'desc'), function (o) {
                                                  return (
                                                      item.amount >= o.amount && item.amount % o.amount === 0 && o.pick
                                                  );
                                              }),
                                              'description'
                                          ) ||
                                          get(
                                              find(orderBy(get(item, 'item.aliases'), 'amount', 'desc'), function (o) {
                                                  return (
                                                      item.amount >= o.amount && item.amount % o.amount === 0 && o.pick
                                                  );
                                              }),
                                              'name'
                                          )
                                      }"`
                                    : get(item, 'item.aliases')
                                          .slice(0, 3)
                                          .map((e) => e.name)
                                          .join(', ')
                                : 'Artikel wurde gelöscht'}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography color='textPrimary' variant='h6'>
                            {item.amount}x
                        </Typography>
                    </Grid>
                </Grid>
                {index + 1 !== order.items.length && <Divider />}
            </Grid>
        ))}
    </Grid>
);

export default ProductGrid;
