import { capitalize, startCase } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import {
    AddRoad,
    Api,
    Assessment,
    Assignment,
    Autorenew,
    CheckCircle,
    ChromeReaderMode,
    ControlCamera,
    DeviceHub,
    EmojiTransportation,
    Error as ErrorIcon,
    Event,
    Factory,
    Group,
    ListAlt,
    LocalShipping,
    MarkunreadMailbox,
    Person,
    Settings,
    ShoppingCart,
    SupervisedUserCircle,
    Warehouse as WarehouseIcon,
    Widgets,
} from '@mui/icons-material';
import { Badge, Box, Drawer, Link, Paper, Theme, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import ChristmasParticlesSidebar from '../ChristmasParticlesSidebar';

import { warehouseApi } from 'src/api/warehouse';
import NavSection from 'src/components/NavSection';
import Scrollbar from 'src/components/Scrollbar';
import { ROLE, ROLE_LABELS, ROLE_OPTIONS } from 'src/constants/role';
import useAuth from 'src/hooks/useAuth';
import { ACCOUNT_ROLES } from 'src/pages/dashboard/Account';
import { ANALYTICS_ROLES } from 'src/pages/dashboard/Analytics';
import { OVERVIEW_ROLES } from 'src/pages/dashboard/Overview/index2';
import { setError } from 'src/slices/error';
import { useDispatch, useSelector } from 'src/store';
import { DARK_BG, DARK_DEFAULT } from 'src/theme';
import { User } from 'src/types/user';
import { Warehouse } from 'src/types/warehouse';

interface Props {
    openMobile: boolean;

    onMobileClose: () => void;
}

const sections = (options: {
    user: User;
    openOrders: number;
    openFulfillments: number;
    openCollections: number;
    draftOrders: number;
    logs: number;
    productsToOrder: number;
    events: number;
}) => [
    {
        title: 'Allgemein',
        items: [
            {
                title: 'Übersicht',
                path: '/dashboard',
                icon: <CheckCircle fontSize='small' />,
                roles: OVERVIEW_ROLES,
            },
            {
                title: 'Auswertung',
                path: '/dashboard/analytics',
                icon: <Assessment fontSize='small' />,
                roles: ANALYTICS_ROLES,
            },
            {
                title: 'Account',
                path: '/dashboard/account',
                icon: <Person fontSize='small' />,
                roles: ACCOUNT_ROLES,
            },
        ],
    },
    {
        title: 'Fulfillment',
        roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER, ROLE.CLIENT],
        items: [
            {
                title: 'Aufträge',
                path: '/dashboard/orders',
                icon: <LocalShipping fontSize='small' />,
                info: <Badge sx={{ mr: 2.5 }} color='error' badgeContent={options.openOrders} />,
                roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER],
            },
            {
                title: 'Auftrag erstellen',
                path: '/dashboard/orders/new',
                icon: <LocalShipping fontSize='small' />,
                roles: [ROLE.CLIENT],
            },
            {
                title: 'Sendungen',
                path: '/dashboard/fulfillments',
                icon: <MarkunreadMailbox fontSize='small' />,
                info: <Badge sx={{ mr: 2.5 }} color='error' badgeContent={options.openFulfillments} />,
                roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER],
            },
            {
                title: 'Läufe',
                path: '/dashboard/collections',
                icon: <ListAlt fontSize='small' />,
                info: <Badge sx={{ mr: 2.5 }} color='error' badgeContent={options.openCollections} />,
                roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER],
            },
            {
                title: 'Entwürfe',
                path: '/dashboard/draft-orders',
                icon: <ErrorIcon fontSize='small' />,
                info: <Badge sx={{ mr: 2.5 }} color='error' badgeContent={options.draftOrders} />,
                roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER],
            },
        ],
    },
    {
        title: 'Lager',
        roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER, ROLE.CLIENT],
        items: [
            {
                title: 'Produkte',
                path: '/dashboard/products',
                icon: <ShoppingCart fontSize='small' />,
                roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER, ROLE.CLIENT],
            },
            {
                title: 'Stellplätze',
                path: '/dashboard/storages',
                icon: <ControlCamera fontSize='small' />,
                roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER],
            },
            {
                title: 'Verpackungen',
                path: '/dashboard/packagings',
                icon: <Widgets fontSize='small' />,
                roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER],
            },
            {
                title: 'Verpackungsregeln',
                path: '/dashboard/pack-presets',
                icon: <Settings fontSize='small' />,
                roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER],
            },
            {
                title: 'Anlieferungen',
                path: '/dashboard/deliveries',
                icon: <EmojiTransportation fontSize='small' />,
                roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER],
            },
            {
                title: 'Nachbestellungen',
                path: '/dashboard/stock',
                icon: <AddRoad fontSize='small' />,
                info: <Badge sx={{ mr: 2.5 }} color='error' badgeContent={options.productsToOrder} />,
                roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER],
            },
        ],
    },
    {
        title: 'Apps',
        roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER],
        items: [
            {
                title: 'To-Do',
                path: '/dashboard/todo',
                icon: <Assignment fontSize='small' />,
                roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER],
            },
            {
                title: 'Kalender',
                path: '/dashboard/calendar',
                icon: <Event fontSize='small' />,
                info: <Badge sx={{ mr: 2.5 }} color='error' badgeContent={options.events} />,
                roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER],
            },
        ],
    },
    {
        title: 'Verwaltung',
        roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER],
        items: [
            {
                title: 'Kunden',
                path: '/dashboard/clients',
                icon: <Group fontSize='small' />,
                roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER],
            },
            {
                title: 'Hersteller',
                path: '/dashboard/manufacturers',
                icon: <Factory fontSize='small' />,
                roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER],
            },
            {
                title: 'Lagerstandorte',
                path: '/dashboard/warehouses',
                icon: <WarehouseIcon fontSize='small' />,
                roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
            },
            {
                title: 'Benutzer',
                path: '/dashboard/users',
                icon: <SupervisedUserCircle fontSize='small' />,
                roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER],
            },
            {
                title: 'Automatik',
                path: '/dashboard/automations',
                icon: <Autorenew fontSize='small' />,
                roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER],
            },
        ],
    },
    ...(options.user.role === ROLE.SUPERADMIN
        ? [
              {
                  title: 'Logging',
                  roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
                  items: [
                      {
                          title: 'Logs',
                          path: '/dashboard/logs',
                          icon: <ChromeReaderMode fontSize='small' />,
                          info: <Badge sx={{ mr: 2.5 }} color='error' badgeContent={options.logs} />,
                          roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
                      },
                      {
                          title: 'API Logs (extern)',
                          path: '/dashboard/responses',
                          icon: <Api fontSize='small' />,
                          roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
                      },
                      {
                          title: 'API Logs (intern)',
                          path: '/dashboard/http-logs',
                          icon: <DeviceHub fontSize='small' />,
                          roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
                      },
                  ],
              },
          ]
        : []),
];

const DashboardSidebar: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const location = useLocation();
    const { openOrders, openFulfillments, openCollections, draftOrders, logs, productsToOrder, events } = useSelector(
        (state) => state.notifications
    );
    const { onMobileClose, openMobile } = props;
    const { user } = useAuth();

    const [warehouse, setWarehouse] = useState<Warehouse>();

    useEffect(() => {
        if (openMobile && onMobileClose) onMobileClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const getWarehouse = useCallback(async (): Promise<void> => {
        try {
            if (user.role === 'client') return;

            const data = await warehouseApi.findById(user.warehouse);

            setWarehouse(data);
        } catch (error) {
            dispatch(setError(error));
        }
    }, [user, dispatch]);

    useEffect(() => {
        if (user.warehouse) {
            getWarehouse();
        }
    }, [user, getWarehouse]);

    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: (theme) => (theme.palette.mode === 'light' ? DARK_DEFAULT : 'background.default'),
                height: '100%',
            }}>
            <Scrollbar options={{ suppressScrollX: true }}>
                <Box sx={{ p: 2 }}>
                    <Paper
                        elevation={0}
                        sx={{
                            alignItems: 'center',
                            backgroundColor: (theme) => (theme.palette.mode === 'light' ? DARK_BG : 'background.paper'),
                            borderRadius: 1,
                            display: 'flex',
                            overflow: 'hidden',
                            p: 2,
                        }}>
                        <RouterLink to='/dashboard/account'>
                            <Box
                                sx={{
                                    height: 52,
                                    width: 52,
                                    bgcolor: ROLE_LABELS[user.role].colorCode,
                                    pl: 1.2,
                                    pt: 0.8,
                                    borderRadius: 0.65,
                                }}>
                                <Box
                                    sx={{
                                        height: 40,
                                        '& > img': {
                                            maxHeight: '100%',
                                            width: 'auto',
                                            alignItems: 'center',
                                        },
                                    }}>
                                    <img alt='Profil' src='/static/icons/shipman-transparent-sm.svg' />
                                </Box>
                            </Box>
                        </RouterLink>
                        <Box sx={{ ml: 2 }}>
                            <Typography
                                color={(theme) =>
                                    theme.palette.mode === 'light'
                                        ? theme.palette.getContrastText(DARK_BG)
                                        : 'textPrimary'
                                }
                                variant='subtitle2'>
                                {startCase(user.username)}
                            </Typography>
                            <Typography
                                color={(theme) =>
                                    theme.palette.mode === 'light'
                                        ? theme.palette.getContrastText(DARK_BG)
                                        : 'textPrimary'
                                }
                                variant='body2'>
                                Rolle:{' '}
                                <Link color='primary' component={RouterLink} to='/dashboard/account'>
                                    {ROLE_LABELS[user.role].label}
                                </Link>
                            </Typography>
                            {warehouse && (
                                <Typography
                                    color={(theme) =>
                                        theme.palette.mode === 'light'
                                            ? theme.palette.getContrastText(DARK_DEFAULT)
                                            : 'textPrimary'
                                    }
                                    variant='body2'>
                                    Lager:{' '}
                                    <Link
                                        color='primary'
                                        component={RouterLink}
                                        to={'/dashboard/warehouses/' + warehouse._id}>
                                        {warehouse.name}
                                    </Link>
                                </Typography>
                            )}
                        </Box>
                    </Paper>
                </Box>
                <Box sx={{ p: 2 }}>
                    {sections({
                        user,
                        openOrders,
                        openFulfillments,
                        openCollections,
                        draftOrders,
                        logs,
                        productsToOrder,
                        events,
                    }).map((section) => (
                        <NavSection
                            key={section.title}
                            pathname={location.pathname}
                            role={user.role}
                            sx={{
                                '& + &': {
                                    mt: 3,
                                },
                            }}
                            {...section}
                        />
                    ))}
                </Box>
            </Scrollbar>
        </Box>
    );

    if (lgUp) {
        return (
            <>
                <Drawer
                    anchor='left'
                    open
                    PaperProps={{
                        sx: {
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light' ? 'background.default' : 'background.paper',
                            width: 280,
                            borderWidth: 0,
                        },
                    }}
                    variant='permanent'>
                    {content}
                </Drawer>
            </>
        );
    }

    return (
        <>
            <Drawer
                anchor='left'
                onClose={onMobileClose}
                open={openMobile}
                PaperProps={{
                    sx: {
                        backgroundColor: 'background.paper',
                        width: 280,
                    },
                }}
                variant='temporary'>
                {content}
            </Drawer>
        </>
    );
};

export default DashboardSidebar;
