import { Order } from 'src/types/order';
import axios from 'src/utils/axios';

class DraftOrderApi {
    async find(params: any = {}): Promise<Order[]> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/draft-orders', params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[HTTP Logs Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async count(params: any = {}): Promise<number> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/draft-orders', params: { ...params, pageSize: 1 } })
                .then((response) => {
                    resolve(Number(response.headers.totalcount));
                })
                .catch((error) => {
                    console.error('[HTTP Logs Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async findById(ID: string, params: any = {}): Promise<Order> {
        if (!ID) return null;
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/draft-orders/' + ID, params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[HTTP Logs Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async delete(ID: string): Promise<Order> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'DELETE',
                url: '/draft-orders/' + ID,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[HTTP Logs Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }
}

export const draftOrderApi = new DraftOrderApi();
