import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'src/store';

interface HelpState {
    help: boolean;
}

const initialState = {
    help: false,
};

const slice = createSlice({
    name: 'help',
    initialState,
    reducers: {
        setHelp(state: HelpState, action: PayloadAction<boolean>): void {
            const help = action.payload;

            state.help = help;
        },
    },
});

export const { reducer } = slice;

export const setHelp =
    (help: boolean): AppThunk =>
    (dispatch): void => {
        dispatch(slice.actions.setHelp(help));
    };

export default slice;
