import axios from 'axios';

import { ENV } from 'src/env';

const axiosInstance = axios.create({ baseURL: ENV.BACKEND_URL });

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
