import axios from 'src/utils/axios';

class NotificationApi {
    async sendMail(options: { to: string; subject: string; text: string }): Promise<any> {
        return new Promise((resolve, reject) =>
            axios({ method: 'POST', url: '/notifications', data: { to: options.to, subject: options.subject, text: options.text } })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Notifications Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }
}

export const notificationApi = new NotificationApi();
