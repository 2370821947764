import { Formik } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';

import { Box, Button, FormHelperText, TextField } from '@mui/material';

import useAuth from 'src/hooks/useAuth';
import useMounted from 'src/hooks/useMounted';

const LoginJWT: FC = (props) => {
    const mounted = useMounted();
    const { login } = useAuth() as any;

    return (
        <Formik
            initialValues={{
                username: '',
                password: '',
                submit: null,
            }}
            validationSchema={Yup.object().shape({
                username: Yup.string().max(255).required('Nutzername erforderlich'),
                password: Yup.string().max(255).required('Passwort erforderlich'),
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }): Promise<void> => {
                try {
                    await login(values.username, values.password);

                    if (mounted.current) {
                        setStatus({ success: true });
                        setSubmitting(false);
                    }
                } catch (err) {
                    console.error(err);
                    if (mounted.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }
            }}>
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }): JSX.Element => (
                <form noValidate onSubmit={handleSubmit} {...props}>
                    <TextField
                        autoFocus
                        error={Boolean(touched.username && errors.username)}
                        fullWidth
                        helperText={touched.username && errors.username}
                        label='Nutzername'
                        margin='normal'
                        name='username'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='username'
                        value={values.username}
                        variant='outlined'
                    />
                    <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label='Passwort'
                        margin='normal'
                        name='password'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='password'
                        value={values.password}
                        variant='outlined'
                    />
                    {errors.submit && (
                        <Box sx={{ mt: 3 }}>
                            <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                    )}
                    <Box sx={{ mt: 2 }}>
                        <Button color='primary' disabled={isSubmitting} fullWidth size='large' type='submit' variant='contained'>
                            Login
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

export default LoginJWT;
