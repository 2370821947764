import { File } from 'src/types/file';
import axios from 'src/utils/axios';

class FileApi {
    async find(params: any = {}): Promise<File[]> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/files', params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Files Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async count(params: any = {}): Promise<number> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/files', params: { ...params, pageSize: 1 } })
                .then((response) => {
                    resolve(Number(response.headers.totalcount));
                })
                .catch((error) => {
                    console.error('[Files Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async findById(ID: string, params: any = {}): Promise<File> {
        if (!ID) return null;
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/files/' + ID, params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Files Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async create(file: any): Promise<File> {
        const data = new FormData();
        data.append('file', file);

        return new Promise((resolve, reject) =>
            axios({
                method: 'POST',
                url: '/files',
                data,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Files Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async delete(ID: string): Promise<File> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'DELETE',
                url: '/files/' + ID,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Files Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }
}

export const fileApi = new FileApi();
