import { get } from 'lodash';
import { FC, useState } from 'react';

import { AccountTree } from '@mui/icons-material/';
import { Backdrop, CircularProgress, IconButton, Tooltip } from '@mui/material';

import CreateFulfillmentDialog from './CreateFulfillmentDialog';

import { fulfillmentApi } from 'src/api/fulfillment';
import { setError } from 'src/slices/error';
import { useDispatch } from 'src/store';
import { Fulfillment } from 'src/types/fulfillment';
import { Order } from 'src/types/order';

interface Props {
    order: Order;
    fulfillments: Fulfillment[];

    refresh: () => Promise<void>;
}

const CreateFulfillmentButton: FC<Props> = ({ order, fulfillments, refresh }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(false);

    const onSave = async (fulfillments: Fulfillment[]) => {
        try {
            setLoading(true);

            for (var fulfillment of fulfillments.filter((e) => !Boolean(e._id) && e.items && e.items.length !== 0)) {
                await fulfillmentApi.create({
                    order: order._id,
                    items: fulfillment.items.map((e) => ({ item: get(e, 'item._id'), amount: e.amount })),
                    warehouse: fulfillment.warehouse ? get(fulfillment, 'warehouse') : get(order, 'warehouse._id'),
                });
            }

            onClose();
            refresh();
        } catch (error) {
            dispatch(setError(error));
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {open && <CreateFulfillmentDialog open={open} order={order} fulfillments={fulfillments} onClose={onClose} onSave={onSave} />}

            <Tooltip title='Teilsendungen erstellen'>
                <IconButton
                    disabled={order.status === 3 || (order.status === 2 && order.fulfilled === '100.00%')}
                    color='default'
                    sx={{ mr: 1 }}
                    onClick={onOpen}>
                    <AccountTree fontSize='small' />
                </IconButton>
            </Tooltip>

            <Backdrop open={loading} sx={{ color: 'primary.main', zIndex: (theme) => theme.zIndex.drawer + 10000 }}>
                <CircularProgress color='inherit' />
            </Backdrop>
        </>
    );
};

export default CreateFulfillmentButton;
