import { get } from 'lodash';
import { FC } from 'react';

import { Card, CardContent, CardHeader, Grid, MenuItem, TextField } from '@mui/material';

import RoleGuard from 'src/components/authentication/RoleGuard';
import ClientAutocomplete from 'src/components/dashboard/client/ClientAutocomplete';
import WarehouseAutocomplete from 'src/components/dashboard/warehouse/WarehouseAutocomplete';
import { ROLE, ROLE_OPTIONS } from 'src/constants/role';
import { User } from 'src/types/user';

interface Props {
    deletable?: boolean;
    disabled?: boolean;
    user: User;

    onChange: (event: any) => void;
    handleChange: (path: string, value: any) => void;
}

const DataCard: FC<Props> = ({ deletable, disabled, user, onChange, handleChange }) => {
    return (
        <Card>
            <CardHeader title='Daten' />
            <CardContent>
                <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                        <TextField
                            disabled={disabled}
                            required
                            fullWidth
                            label='Nutzername'
                            name='username'
                            variant='outlined'
                            onChange={onChange}
                            value={get(user, 'username') || ''}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            disabled={disabled}
                            fullWidth
                            label='Rolle'
                            name='role'
                            required
                            select
                            onChange={onChange}
                            value={get(user, 'role') || ''}
                            variant='outlined'>
                            {ROLE_OPTIONS.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <RoleGuard roles={[ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER]}>
                        <Grid item md={6} xs={12}>
                            <ClientAutocomplete
                                disabled={disabled}
                                client={get(user, 'client._id')}
                                setClient={(client) => handleChange('client', client)}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <WarehouseAutocomplete
                                disabled={disabled}
                                warehouse={get(user, 'warehouse._id')}
                                setWarehouse={(warehouse) => handleChange('warehouse', warehouse)}
                            />
                        </Grid>
                    </RoleGuard>
                    {deletable && (
                        <Grid item md={6} xs={12}>
                            <TextField
                                disabled={disabled}
                                required
                                fullWidth
                                label='Passwort'
                                name='password'
                                type='password'
                                variant='outlined'
                                onChange={onChange}
                                value={get(user, 'password') || ''}
                            />
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default DataCard;
