import { alpha } from '@mui/material';

import Label from 'src/components/dashboard/Label';
import { ORDER_STATUS_LABELS } from 'src/constants/order';
import { Order } from 'src/types/order';

export const OrderStatusLabel = (order: Order): JSX.Element => {
    const isInFulfillment: boolean = order.status_name === 'fulfillment';

    const colorCode: string = ORDER_STATUS_LABELS[order.status_name]['colorCode'];
    const text: string = ORDER_STATUS_LABELS[order.status_name]['text'];

    return <Label colorCode={isInFulfillment ? `linear-gradient(90deg, ${colorCode} ${order.fulfilled}, ${alpha(colorCode, 0.3)} ${order.fulfilled})` : colorCode}>{text}</Label>;
};
