import { FC, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';

import { Box, Container } from '@mui/material';

import Headline from './Headline';

import { collectionApi } from 'src/api/collection';
import { extraApi } from 'src/api/extra';
import OverviewAdmin from 'src/components/dashboard/overview/OverviewAdmin';
import OverviewWorker from 'src/components/dashboard/overview/OverviewWorker';
import Scrollbar from 'src/components/Scrollbar';
import { ROLE } from 'src/constants/role';
import useAuth from 'src/hooks/useAuth';
import AuthorizationRequired from 'src/pages/error/AuthorizationRequired';
import { setError } from 'src/slices/error';
import { useDispatch } from 'src/store';
import { Collection } from 'src/types/collection';

export const OVERVIEW_ROLES = [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER, ROLE.WORKER];

const Overview: FC = () => {
    const { user } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);
    const [collections, setCollections] = useState<Collection[]>([]);
    const [data, setData] = useState<any>();
    const [germanyCount, setGermanyCount] = useState<any>();

    const fetchData = useCallback(async (): Promise<void> => {
        try {
            if ([ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER].includes(user.role)) {
                const data = await extraApi.getGermanyCount();

                setGermanyCount(data);
            }

            if (ROLE.WORKER === user.role) {
                const collections = await collectionApi.find();

                setCollections(collections);
            }
        } catch (error) {
            dispatch(setError(error));
        } finally {
            setLoading(false);
        }
    }, [user, dispatch]);

    useEffect(() => {
        setLoading(true);

        fetchData();

        const interval = setInterval(() => {
            fetchData();
        }, 5000);

        return () => clearInterval(interval);
    }, [fetchData]);

    if (user.role === ROLE.CLIENT) navigate('/dashboard/products');
    if (!OVERVIEW_ROLES.includes(user.role)) return <AuthorizationRequired />;

    return (
        <>
            <Helmet>
                <title>Übersicht - Shipman </title>
            </Helmet>

            <Scrollbar options={{ suppressScrollX: true }}>
                <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                    <Container maxWidth={false}>
                        <Headline />

                        <Box sx={{ mt: 2 }}>
                            {[ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER].includes(user.role) && (
                                <OverviewAdmin loading={loading} germanyCount={germanyCount} />
                            )}
                            {ROLE.WORKER === user.role && <OverviewWorker loading={loading} collections={collections} />}
                        </Box>
                    </Container>
                </Box>
            </Scrollbar>
        </>
    );
};

export default Overview;
