import { FC, ReactNode } from 'react';
import { matchPath } from 'react-router-dom';

import { List, ListProps, ListSubheader } from '@mui/material';

import NavItem from './NavItem';

import { DARK_BG } from 'src/theme';

interface Item {
    children?: Item[];
    icon?: ReactNode;
    roles?: string[];
    info?: ReactNode;
    path?: string;
    title: string;
}

interface Props extends ListProps {
    items: Item[];
    pathname: string;
    title: string;
    role: string;
    roles?: string[];
}

const renderNavItems = ({
    depth = 0,
    items,
    pathname,
    role,
}: {
    items: Item[];
    pathname: string;
    depth?: number;
    role: string;
}): JSX.Element => (
    <List disablePadding>
        {items
            .filter((e) => !e.roles || e.roles.includes(role))
            .reduce(
                // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
                (acc, item) =>
                    reduceChildRoutes({
                        acc,
                        item,
                        pathname,
                        depth,
                        role,
                    }),
                []
            )}
    </List>
);

const reduceChildRoutes = ({
    acc,
    pathname,
    item,
    depth,
    role,
}: {
    acc: JSX.Element[];
    pathname: string;
    item: Item;
    depth: number;
    role: string;
}): Array<JSX.Element> => {
    const key = `${item.title}-${depth}`;
    const exactMatch = item.path
        ? !!matchPath(
              {
                  path: item.path,
                  end: true,
              },
              pathname
          )
        : false;

    if (item.children) {
        const partialMatch = item.path
            ? !!matchPath(
                  {
                      path: item.path,
                      end: false,
                  },
                  pathname
              )
            : false;

        acc.push(
            <NavItem
                active={partialMatch}
                depth={depth}
                icon={item.icon}
                info={item.info}
                key={key}
                open={partialMatch}
                path={item.path}
                title={item.title}>
                {renderNavItems({
                    depth: depth + 1,
                    items: item.children,
                    pathname,
                    role,
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem active={exactMatch} depth={depth} icon={item.icon} info={item.info} key={key} path={item.path} title={item.title} />
        );
    }

    return acc;
};

const NavSection: FC<Props> = (props) => {
    const { items, pathname, title, role, roles, ...other } = props;

    if (roles && !roles.includes(role)) return <></>;

    return (
        <List
            subheader={
                <ListSubheader
                    disableGutters
                    disableSticky
                    sx={{
                        color: (theme) => (theme.palette.mode === 'light' ? theme.palette.getContrastText(DARK_BG) : 'textPrimary'),
                        fontSize: '0.75rem',
                        lineHeight: 2.5,
                        fontWeight: 700,
                        textTransform: 'uppercase',
                    }}>
                    {title}
                </ListSubheader>
            }
            {...other}>
            {renderNavItems({
                items,
                pathname,
                role,
            })}
        </List>
    );
};

export default NavSection;
