import { find, get } from 'lodash';
import { FC, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

import { AccountTree, Add, ChevronRight } from '@mui/icons-material';
import { alpha, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material';

import FulfillmentPaper from './FulfillmentPaper';
import OrderPaper from './OrderPaper';

import Scrollbar from 'src/components/Scrollbar';
import useSettings from 'src/hooks/useSettings';
import { setError } from 'src/slices/error';
import { useDispatch } from 'src/store';
import { Fulfillment } from 'src/types/fulfillment';
import { Order } from 'src/types/order';
import { changeIndexArray } from 'src/utils/change-index-array';
import { insertArray } from 'src/utils/insert-array';

interface Props {
    open: boolean;
    order: Order;
    fulfillments: Fulfillment[];

    onClose: () => void;
    onSave: (value: Fulfillment[]) => void;
}

const filterItems = (order: Order, fulfillments: Fulfillment[]): any[] => {
    var items = [];

    for (const item of order.items) {
        var amount = item.amount;

        for (const fulfillment of fulfillments) {
            if (find(fulfillment.items, { item: { _id: get(item, 'item._id') } })) {
                amount -= find(fulfillment.items, { item: { _id: get(item, 'item._id') } }).amount;
            }
        }

        if (amount > 0) items.push({ ...item, amount });
    }

    return items;
};

const CreateFulfillmentDialog: FC<Props> = (props) => {
    const { open, order, onClose, onSave } = props;
    const dispatch = useDispatch();
    const { settings } = useSettings();
    const [index, setIndex] = useState<number>(0);

    const [fulfillments, setFulfillments] = useState<any[]>(
        props.fulfillments && props.fulfillments.length > 0
            ? props.fulfillments.map((e) => ({ ...e, warehouse: get(e, 'warehouse._id') }))
            : [{ items: [], warehouse: null }]
    );
    const [items, setItems] = useState<any[]>(props.fulfillments ? filterItems(order, props.fulfillments) : order.items);

    const onFulfillmentAdd = (): void => {
        setFulfillments(fulfillments.concat({ items: [], warehouse: null }));
    };

    const pushAllItems = (): void => {
        setFulfillments(fulfillments.map((e, i) => (i === index ? { ...e, items: e.items.concat(items) } : e)));
        setItems([]);
    };

    const handleDragEnd = async ({ source, destination }: DropResult): Promise<void> => {
        try {
            if (!destination) return;

            const from: string = source.droppableId;
            const fromIndex: number = source.index;
            const to: string = destination.droppableId;
            const toIndex: number = destination.index;

            if (from === to && fromIndex === toIndex) return;

            if (from === to) {
                if (from === 'order') {
                    setItems(changeIndexArray(items, fromIndex, toIndex));
                } else {
                    const fulfillmentIndex: number = Number(destination.droppableId.split('.')[1]);

                    setFulfillments(
                        fulfillments.map((fulfillment, index) => {
                            if (index !== fulfillmentIndex) return fulfillment;

                            return { ...fulfillment, items: changeIndexArray(fulfillment.items, fromIndex, toIndex) };
                        })
                    );
                }
            }

            if (from !== to) {
                if (from === 'order') {
                    const item = get(items, fromIndex);
                    const fulfillmentIndex: number = Number(destination.droppableId.split('.')[1]);

                    setItems(items.filter((e, index) => index !== fromIndex));
                    setFulfillments(
                        fulfillments.map((fulfillment, index) => {
                            if (index === fulfillmentIndex) {
                                return { ...fulfillment, items: insertArray(fulfillment.items, item, toIndex) };
                            }

                            return fulfillment;
                        })
                    );
                } else if (to === 'order') {
                    const fulfillmentIndex: number = Number(from.split('.')[1]);
                    const fulfillment = get(fulfillments, fulfillmentIndex);
                    const item = get(fulfillment.items, fromIndex);

                    setFulfillments(
                        fulfillments.map((fulfillment, index) => {
                            if (index === fulfillmentIndex) {
                                return { ...fulfillment, items: fulfillment.items.filter((e, itemIndex) => itemIndex !== fromIndex) };
                            }

                            return fulfillment;
                        })
                    );
                    setItems(insertArray(items, item, toIndex));
                } else {
                    const fulfillmentFromIndex: number = Number(from.split('.')[1]);
                    const fulfillmentToIndex: number = Number(to.split('.')[1]);
                    const fulfillment = get(fulfillments, fulfillmentFromIndex);
                    const item = get(fulfillment.items, fromIndex);

                    setFulfillments(
                        fulfillments.map((fulfillment, index) => {
                            if (index === fulfillmentFromIndex) {
                                return { ...fulfillment, items: fulfillment.items.filter((e, itemIndex) => itemIndex !== fromIndex) };
                            }

                            if (index === fulfillmentToIndex) {
                                return { ...fulfillment, items: insertArray(fulfillment.items, item, toIndex) };
                            }

                            return fulfillment;
                        })
                    );
                }
            }
        } catch (error) {
            dispatch(setError(error));
        }
    };

    return (
        <Dialog open={open} onClose={onClose} scroll='paper' fullWidth maxWidth='xl'>
            <DialogTitle sx={{ backgroundColor: (theme) => alpha(theme.palette.warning.main, settings.theme === 'LIGHT' ? 0.7 : 1) }}>
                <Box sx={{ display: 'flex' }}>
                    <AccountTree fontSize='large' sx={{ color: (theme) => theme.palette.text.primary, mt: 0.5, mr: 2 }} />
                    <Typography color='textPrimary' variant='h4'>
                        Teilsendungen erstellen
                    </Typography>
                </Box>
            </DialogTitle>
            <Scrollbar options={{ suppressScrollX: true, suppressScrollY: true }}>
                <DialogContent>
                    <Tooltip title='Sendung hinzufügen'>
                        <IconButton onClick={onFulfillmentAdd} sx={{ ml: 2 }}>
                            <Add />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Alle Artikel verschieben'>
                        <IconButton disabled={Boolean(fulfillments[index]._id) || items.length === 0} onClick={pushAllItems} sx={{ ml: 2 }}>
                            <ChevronRight />
                        </IconButton>
                    </Tooltip>

                    <Box
                        sx={{
                            display: 'block',
                            pb: 2,
                        }}>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    flexShrink: 1,
                                    overflowX: 'auto',
                                    overflowY: 'hidden',
                                }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        px: 1,
                                        py: 1,
                                    }}>
                                    <OrderPaper items={items} setItems={setItems} />
                                    {fulfillments.length > 0 && (
                                        <FulfillmentPaper
                                            setIndex={setIndex}
                                            fulfillments={fulfillments}
                                            items={items}
                                            setFulfillments={setFulfillments}
                                            setItems={setItems}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </DragDropContext>
                    </Box>
                </DialogContent>
            </Scrollbar>

            <DialogActions sx={{ mr: 1 }}>
                <Button onClick={onClose} color='primary' variant='outlined'>
                    Abbrechen
                </Button>
                <Button color='primary' variant='contained' onClick={() => onSave(fulfillments)}>
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateFulfillmentDialog;
