import { get, omit, set } from 'lodash';
import { FC, useState } from 'react';

import { Delete, Save } from '@mui/icons-material';
import { Box, Button, IconButton, Tooltip } from '@mui/material';

import DataCard from './DataCard';

import { setError } from 'src/slices/error';
import { useDispatch } from 'src/store';
import { User } from 'src/types/user';

interface Props {
    deletable?: boolean;
    disabled?: boolean;
    initialValue?: User;

    onSave: (value: User) => void;
}

const UserForm: FC<Props> = ({ deletable, disabled, initialValue, onSave }) => {
    const dispatch = useDispatch();

    const [user, setUser] = useState<User>(initialValue || {});

    const [_, forceUpdate] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const handleChange = (path, value) => {
        setUser((user) => set(user, path, value));

        forceUpdate(!_);
    };

    const onChange = (event: any): void => handleChange(event.target.name, event.target.value);

    const handleSubmit = async (): Promise<void> => {
        try {
            setSubmitting(true);

            if (deletable) {
                onSave({ ...user, client: get(user, 'client._id'), warehouse: get(user, 'warehouse._id') });
            } else {
                onSave(omit({ ...user, client: get(user, 'client._id'), warehouse: get(user, 'warehouse._id') }, 'password'));
            }
        } catch (error) {
            dispatch(setError(error));
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <Box sx={{ mt: 2 }}>
                <DataCard disabled={disabled} deletable={deletable} user={user} onChange={onChange} handleChange={handleChange} />
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: 2,
                }}>
                {deletable && (
                    <Tooltip title='Alles löschen'>
                        <IconButton
                            color='default'
                            sx={{ mr: 1 }}
                            onClick={() => {
                                setUser({});
                                window.localStorage.removeItem('userTemplate');
                            }}>
                            <Delete fontSize='small' />
                        </IconButton>
                    </Tooltip>
                )}

                <Button
                    color='primary'
                    disabled={submitting || disabled}
                    type='submit'
                    variant='contained'
                    onClick={handleSubmit}
                    startIcon={<Save fontSize='small' />}>
                    Speichern
                </Button>
            </Box>
        </>
    );
};

export default UserForm;
