import NProgress from 'nprogress';
import { FC, useEffect } from 'react';

import { useSelector } from 'src/store';

const Loading: FC = () => {
    const { loading } = useSelector((state) => state.loading);

    useEffect(() => {
        if (!loading) return;

        NProgress.start();

        return (): void => {
            NProgress.done();
        };
    }, [loading]);

    return <></>;
};

export default Loading;
