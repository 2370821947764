import { find, last } from 'lodash';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { kanbanApi } from 'src/api/kanban';
import { AppThunk } from 'src/store';
import { Board, Card, Checklist, ChecklistItem, Column } from 'src/types/kanban';
import { User } from 'src/types/user';

interface KanbanState {
    isLoaded: boolean;
    cards: Card[];
    columns: Column[];
}

const initialState: KanbanState = {
    isLoaded: false,
    cards: [],
    columns: [],
};

const slice = createSlice({
    name: 'kanban',
    initialState,
    reducers: {
        getBoard(state: KanbanState, action: PayloadAction<Board>): void {
            const board = action.payload;

            state.cards = board.cards;
            state.columns = board.columns;
            state.isLoaded = true;
        },
        createColumn(state: KanbanState, action: PayloadAction<Column>): void {
            const column = action.payload;

            state.columns.push(column);
        },
        updateColumn(state: KanbanState, action: PayloadAction<Column>): void {
            const data = action.payload;

            state.columns = state.columns.map((e) => (e._id === data._id ? data : e));
        },
        clearColumn(state: KanbanState, action: PayloadAction<string>): void {
            const columnId = action.payload;
            const column = find(state.columns, { _id: columnId });

            state.columns = state.columns.map((column) => (column._id === columnId ? { ...column, cards: [] } : column));
            state.cards = state.cards.filter((card) => !column.cards.includes(card._id));
        },
        deleteColumn(state: KanbanState, action: PayloadAction<string>): void {
            const columnId = action.payload;
            const column = find(state.columns, { _id: columnId });

            state.cards = state.cards.filter((card) => !column.cards.includes(card._id));
            state.columns = state.columns.filter((column) => column._id !== columnId);
        },
        createCard(state: KanbanState, action: PayloadAction<{ card: Card; columnId: string }>): void {
            const { card, columnId } = action.payload;

            state.cards.push(card);
            state.columns = state.columns.map((column) => (columnId === column._id ? { ...column, cards: column.cards.concat(card._id) } : column));
        },
        updateCard(state: KanbanState, action: PayloadAction<Card>): void {
            const card = action.payload;

            state.cards = state.cards.map((e) => (e._id === card._id ? { ...e, ...card } : e));
        },
        moveCard(state: KanbanState, action: PayloadAction<{ source: string; cardId: string; position: number; columnId?: string; sourceColumnId?: string }>): void {
            const { source, cardId, position, columnId } = action.payload;

            if (columnId) {
                state.columns = state.columns.map((column) => {
                    if (column._id === source) {
                        return {
                            ...column,
                            cards: column.cards.filter((e) => e !== cardId),
                        };
                    }

                    if (column._id === columnId) {
                        var cards = column.cards;
                        cards.splice(position, 0, cardId);

                        return {
                            ...column,
                            cards,
                        };
                    }

                    return column;
                });
            } else {
                state.columns = state.columns.map((column) => {
                    if (column._id === source) {
                        var cards = column.cards.filter((e) => e !== cardId);
                        cards.splice(position, 0, cardId);

                        return {
                            ...column,
                            cards,
                        };
                    }

                    return column;
                });
            }
        },
        deleteCard(state: KanbanState, action: PayloadAction<string>): void {
            const cardId = action.payload;

            state.cards = state.cards.filter((e) => e._id !== cardId);
        },
        addChecklist(state: KanbanState, action: PayloadAction<{ cardId: string; checklist: Checklist }>): void {
            const { cardId, checklist } = action.payload;

            state.cards = state.cards.map((e) => (e._id === cardId ? { ...e, checklists: e.checklists.concat(checklist) } : e));
        },
        updateChecklist(state: KanbanState, action: PayloadAction<{ cardId: string; checklistId: string; checklist: Checklist }>): void {
            const { cardId, checklistId, checklist } = action.payload;

            state.cards = state.cards.map((e) => (e._id === cardId ? { ...e, checklists: e.checklists.map((c) => (c._id === checklistId ? { ...c, ...checklist } : c)) } : e));
        },
        deleteChecklist(state: KanbanState, action: PayloadAction<{ cardId: string; checklistId: string }>): void {
            const { cardId, checklistId } = action.payload;

            state.cards = state.cards.map((e) => (e._id === cardId ? { ...e, checklists: e.checklists.filter((c) => c._id !== checklistId) } : e));
        },
        addCheckItem(state: KanbanState, action: PayloadAction<{ cardId: string; checklistId: string; checkItem: ChecklistItem }>): void {
            const { cardId, checklistId, checkItem } = action.payload;

            state.cards = state.cards.map((e) =>
                e._id === cardId
                    ? {
                          ...e,
                          checklists: e.checklists.map((c) => (c._id === checklistId ? { ...c, checklist_items: c.checklist_items ? c.checklist_items.concat(checkItem) : [checkItem] } : c)),
                      }
                    : e
            );
        },
        updateCheckItem(state: KanbanState, action: PayloadAction<{ cardId: string; checklistId: string; checkItemId: string; checkItem: ChecklistItem }>): void {
            const { cardId, checklistId, checkItemId, checkItem } = action.payload;

            state.cards = state.cards.map((e) =>
                e._id === cardId
                    ? {
                          ...e,
                          checklists: e.checklists.map((c) =>
                              c._id === checklistId ? { ...c, checklist_items: c.checklist_items.map((i) => (i._id === checkItemId ? { ...i, ...checkItem } : i)) } : c
                          ),
                      }
                    : e
            );
        },
        deleteCheckItem(state: KanbanState, action: PayloadAction<{ cardId: string; checklistId: string; checkItemId: string }>): void {
            const { cardId, checklistId, checkItemId } = action.payload;

            state.cards = state.cards.map((e) =>
                e._id === cardId
                    ? {
                          ...e,
                          checklists: e.checklists.map((c) => (c._id === checklistId ? { ...c, checklist_items: c.checklist_items.filter((i) => i._id !== checkItemId) } : c)),
                      }
                    : e
            );
        },
    },
});

export const { reducer } = slice;

export const getBoard =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        const data = await kanbanApi.getBoard();

        dispatch(slice.actions.getBoard(data));
    };

export const getBoardPrivate =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        const data = await kanbanApi.getBoardPrivate();

        dispatch(slice.actions.getBoard(data));
    };

export const createColumn =
    (name: string, shared: boolean): AppThunk =>
    async (dispatch): Promise<void> => {
        const data = await kanbanApi.createColumn({ name, shared });

        dispatch(slice.actions.createColumn(data));
    };

export const updateColumn =
    (columnId: string, update: any): AppThunk =>
    async (dispatch): Promise<void> => {
        const data = await kanbanApi.updateColumn({ ID: columnId, update });

        dispatch(slice.actions.updateColumn(data));
    };

export const clearColumn =
    (columnId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        await kanbanApi.clearColumn(columnId);

        dispatch(slice.actions.clearColumn(columnId));
    };

export const deleteColumn =
    (columnId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        await kanbanApi.deleteColumn(columnId);

        dispatch(slice.actions.deleteColumn(columnId));
    };

export const createCard =
    (columnId: string, name: string, user: User): AppThunk =>
    async (dispatch): Promise<void> => {
        const data = await kanbanApi.createCard({ name, user });
        const populated = await kanbanApi.findCardById(data._id, { populate: 'members' });
        const column = await kanbanApi.findColumnById(columnId);
        await kanbanApi.updateColumn({ ID: columnId, update: { ...column, cards: column.cards.concat(data._id) } });

        dispatch(slice.actions.createCard({ card: populated, columnId: column._id }));
    };

export const updateCard =
    (cardId: string, update: any): AppThunk =>
    async (dispatch): Promise<void> => {
        const data = await kanbanApi.updateCard({ ID: cardId, update });
        const populated = await kanbanApi.findCardById(data._id, { populate: 'members' });
        dispatch(slice.actions.updateCard(populated));
    };

export const updateCardCheck =
    (cardId: string, update: any): AppThunk =>
    async (dispatch): Promise<void> => {
        await kanbanApi.updateCard({ ID: cardId, update });
        dispatch(slice.actions.updateCard(update));
    };

export const moveCard =
    (source: string, cardId: string, position: number, columnId?: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(
            slice.actions.moveCard({
                source,
                cardId,
                position,
                columnId,
            })
        );
        if (columnId) {
            const column = await kanbanApi.findColumnById(source);
            await kanbanApi.updateColumn({ ID: source, update: { cards: column.cards.filter((e) => e !== cardId) } });

            const newColumn = await kanbanApi.findColumnById(columnId);
            let cards = newColumn.cards;
            newColumn.cards.splice(position, 0, cardId);
            await kanbanApi.updateColumn({ ID: columnId, update: { cards } });
        } else {
            const column = await kanbanApi.findColumnById(source);
            let cards = column.cards.filter((e) => e !== cardId);
            cards.splice(position, 0, cardId);
            await kanbanApi.updateColumn({ ID: source, update: { cards } });
        }
    };

export const deleteCard =
    (cardId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        await kanbanApi.deleteCard(cardId);

        dispatch(slice.actions.deleteCard(cardId));
    };

export const addChecklist =
    (cardId: string, name: string): AppThunk =>
    async (dispatch): Promise<void> => {
        const data = await kanbanApi.addChecklist({ ID: cardId, name });

        dispatch(
            slice.actions.addChecklist({
                cardId,
                checklist: { _id: last(data.checklists)._id, name },
            })
        );
    };

export const updateChecklist =
    (cardId: string, checklistId: string, update: any): AppThunk =>
    async (dispatch): Promise<void> => {
        await kanbanApi.updateChecklist({ ID: cardId, checklistId, update });

        dispatch(
            slice.actions.updateChecklist({
                cardId,
                checklistId,
                checklist: update,
            })
        );
    };

export const deleteChecklist =
    (cardId: string, checklistId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        await kanbanApi.deleteChecklist({ ID: cardId, checklistId });

        dispatch(
            slice.actions.deleteChecklist({
                cardId,
                checklistId,
            })
        );
    };

export const addCheckItem =
    (cardId: string, checklistId: string, name: string): AppThunk =>
    async (dispatch): Promise<void> => {
        const data = await kanbanApi.addCheckItem({ ID: cardId, checklistId, name });

        dispatch(
            slice.actions.addCheckItem({
                cardId,
                checklistId,
                checkItem: { _id: last(find(data.checklists, { _id: checklistId }).checklist_items)._id, name },
            })
        );
    };

export const updateCheckItem =
    (cardId: string, checklistId: string, checkItemId: string, update: any): AppThunk =>
    async (dispatch): Promise<void> => {
        await kanbanApi.updateCheckItem({ ID: cardId, checklistId, checkItemId, update });

        dispatch(
            slice.actions.updateCheckItem({
                cardId,
                checklistId,
                checkItemId,
                checkItem: update,
            })
        );
    };

export const deleteCheckItem =
    (cardId: string, checklistId: string, checkItemId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        await kanbanApi.deleteCheckItem({ ID: cardId, checklistId, checkItemId });

        dispatch(
            slice.actions.deleteCheckItem({
                cardId,
                checklistId,
                checkItemId,
            })
        );
    };

export default slice;
