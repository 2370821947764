import { AnalyticsSettings } from 'src/types/analytics-settings';
import { Fulfillment, Pack } from 'src/types/fulfillment';
import axios from 'src/utils/axios';

class FulfillmentApi {
    async analytics(data: AnalyticsSettings): Promise<Fulfillment[]> {
        return new Promise((resolve, reject) =>
            axios({ method: 'POST', url: '/fulfillments/analytics', data })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async find(params: any = {}): Promise<Fulfillment[]> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/fulfillments', params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async aggregate(params: any = {}): Promise<Fulfillment[]> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/fulfillments/aggregate', params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async count(params: any = {}): Promise<number> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/fulfillments', params: { ...params, pageSize: 1 } })
                .then((response) => {
                    resolve(Number(response.headers.totalcount));
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async countAggregate(params: any = {}): Promise<number> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/fulfillments/aggregate', params: { ...params, pageSize: 1 } })
                .then((response) => {
                    resolve(Number(response.headers.totalcount));
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async findById(ID: string, params: any = {}): Promise<Fulfillment> {
        if (!ID) return null;
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/fulfillments/' + ID, params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async findPack(ID: string, collection: string): Promise<Pack> {
        if (!ID) return null;
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/fulfillments/pack', params: { ID, collection } })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async getHistory(ID: string, params: any = {}): Promise<Fulfillment[]> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/fulfillments/' + ID + '/history', params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async create(data: Fulfillment): Promise<Fulfillment> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'POST',
                url: '/fulfillments',
                data,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async update(ID: string, data: Fulfillment): Promise<Fulfillment> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: '/fulfillments/' + ID,
                data,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async updateCharge(ID: string, data: { productId: string; charge: string }): Promise<void> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: '/fulfillments/' + ID + '/charge',
                data,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async redoLabel(ID: string): Promise<string> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: '/fulfillments/' + ID + '/re-label',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async distribute(
        fulfillmentIds: string[],
        collections: {
            regular_packagings: string;
            regular_box_packagings: string;
            regular_combination_packagings: string;
            neutral_packagings: string;
            neutral_box_packagings: string;
            neutral_combination_packagings: string;
            manufacturer?: string;
        }
    ): Promise<{ success: string[]; errors: string[] }> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'POST',
                url: '/fulfillments/distribute',
                data: { fulfillmentIds, ...collections },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async reserve(ID: string): Promise<Fulfillment> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: '/fulfillments/' + ID + '/reserve',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async automaticPackaging(ID: string): Promise<Fulfillment> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: '/fulfillments/' + ID + '/packaging',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async createLabels(ID: string): Promise<Fulfillment> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: '/fulfillments/' + ID + '/labels',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async createLabelsBulk(IDs: string[]): Promise<{ success: string[]; failed: { orderId: string; error: any }[] }> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'POST',
                url: '/fulfillments/labels',
                data: { IDs: IDs },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async getStatus(ID: string): Promise<Fulfillment> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: '/fulfillments/' + ID + '/status',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async getSignature(ID: string): Promise<Fulfillment> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: '/fulfillments/' + ID + '/signature',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async export(ID: string): Promise<Fulfillment> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: '/fulfillments/' + ID + '/export',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async reserved(ID: string): Promise<Fulfillment> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: '/fulfillments/' + ID + '/reserved',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async picked(ID: string): Promise<Fulfillment> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: '/fulfillments/' + ID + '/picked',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async packed(ID: string, parcels?: { _id: string; packaging: string }[]): Promise<Fulfillment> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: '/fulfillments/' + ID + '/packed',
                ...(parcels && { data: { parcels } }),
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async exportSignature(ID: string): Promise<Fulfillment> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: '/fulfillments/' + ID + '/export-signature',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async delete(ID: string): Promise<Fulfillment> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'DELETE',
                url: '/fulfillments/' + ID,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Fulfillments Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }
}

export const fulfillmentApi = new FulfillmentApi();
