import { FC, useState } from 'react';

import { Warning } from '@mui/icons-material';
import { alpha, Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';

import { Item } from 'src/types/item';

interface Props {
    item: Item;
    open: boolean;

    onClose: () => void;
    onSplit: (value: number) => void;
}

const SplitDialog: FC<Props> = ({ item, open, onClose, onSplit }) => {
    const [amount, setAmount] = useState<number>(1);

    return (
        <Dialog open={open} onClose={onClose} scroll='paper' fullWidth maxWidth='sm'>
            <DialogTitle>
                <Box sx={{ display: 'flex', pt: 1 }}>
                    <Avatar
                        sx={{
                            backgroundColor: (theme) => alpha(theme.palette.warning.main, 0.08),
                            color: 'warning.main',
                            mr: 2,
                            height: 58,
                            width: 58,
                        }}>
                        <Warning fontSize='large' />
                    </Avatar>
                    <Box sx={{ mt: 1 }}>
                        <Typography color='textPrimary' variant='h4'>
                            Anzahl splitten
                        </Typography>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ ml: 5, mr: 5 }}>
                <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label='Menge'
                    name='amount'
                    onChange={(event) => setAmount(Number(event.target.value) < 1 ? 1 : Math.min(parseInt(event.target.value), item.amount - 1))}
                    type='number'
                    value={amount}
                    variant='outlined'
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color='primary' sx={{ mr: 2 }} variant='outlined'>
                    Abbrechen
                </Button>
                <Button
                    sx={{
                        backgroundColor: 'warning.main',
                        '&:hover': {
                            backgroundColor: 'warning.dark',
                        },
                    }}
                    variant='contained'
                    onClick={() => {
                        onSplit(amount);
                        onClose();
                    }}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SplitDialog;
