import { FC, ReactNode, useState } from 'react';
import { Navigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import useAuth from 'src/hooks/useAuth';
import Login from 'src/pages/authentication/Login';

interface Props {
    children: ReactNode;
}

const AuthGuard: FC<Props> = (props) => {
    const auth = useAuth();
    const location = useLocation();
    const { children } = props;

    const [requestedLocation, setRequestedLocation] = useState(null);

    if (!auth.isAuthenticated) {
        if (location.pathname !== requestedLocation) {
            setRequestedLocation(location.pathname);
        }

        return <Login />;
    }

    // This is done so that in case the route changes by any chance through other
    // means between the moment of request and the render we navigate to the initially
    // requested route.
    if (requestedLocation && location.pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation} />;
    }

    return <>{children}</>;
};

export default AuthGuard;
