import { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import { Box, Button, Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const AuthorizationRequired: FC = () => {
    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <Helmet>
                <title>Error 401: Nicht Authorisiert - Shipman</title>
            </Helmet>

            <Box
                sx={{
                    alignItems: 'center',
                    backgroundColor: 'background.paper',
                    display: 'flex',
                    minHeight: '100%',
                    px: 3,
                    py: '120px',
                    ...(mobileDevice && { pb: 29 }),
                }}>
                <Container maxWidth='lg'>
                    <Typography align='center' color='textPrimary' variant={mobileDevice ? 'h4' : 'h1'}>
                        401: Nicht Authorisiert
                    </Typography>
                    <Typography align='center' color='textSecondary' sx={{ mt: 0.5 }} variant='subtitle2'>
                        Diese Seite ist nicht für Sie freigegeben.
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: 6,
                        }}>
                        <Box
                            alt='Under development'
                            component='img'
                            src='/static/error/error401.svg'
                            sx={{
                                height: 'auto',
                                maxWidth: '100%',
                                width: 600,
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: 6,
                        }}>
                        <Button color='primary' onClick={() => window.history.back()} variant='outlined'>
                            Zurück
                        </Button>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default AuthorizationRequired;
