import { FC } from 'react';

import { Box } from '@mui/material';

const SplashScreen: FC = () => (
    <Box
        sx={{
            alignItems: 'center',
            backgroundColor: 'background.paper',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            left: 0,
            p: 3,
            position: 'fixed',
            top: 0,
            width: '100%',
            zIndex: 2000,
        }}>
        <img alt='Profil' src='/static/icons/shipman-filled.svg' />
    </Box>
);

export default SplashScreen;
