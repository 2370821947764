/* eslint-disable no-bitwise */
import jwtDecode from 'jwt-decode';

export const JWT_SECRET = ')7Ye.7y3U$HTuhU';
export const JWT_EXPIRES_IN = 3600 * 24 * 2; // 2 days

export const decode = (token: string): any => {
    const decoded: any = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
        throw new Error('Expired token');
    }

    return decoded;
};
