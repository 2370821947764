import { combineReducers } from '@reduxjs/toolkit';

import { reducer as calendarReducer } from '../slices/calendar';
import { reducer as errorReducer } from '../slices/error';
import { reducer as helpReducer } from '../slices/help';
import { reducer as kanbanReducer } from '../slices/kanban';
import { reducer as loadingReducer } from '../slices/loading';
import { reducer as notificationReducer } from '../slices/notification';

const rootReducer = combineReducers({
    calendar: calendarReducer,
    error: errorReducer,
    help: helpReducer,
    kanban: kanbanReducer,
    loading: loadingReducer,
    notifications: notificationReducer,
});

export default rootReducer;
