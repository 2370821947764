import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'src/store';

interface ErrorState {
    error: any;
}

const initialState: ErrorState = {
    error: null,
};

const slice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        setError(state: ErrorState, action: PayloadAction<any>): void {
            const error = action.payload;

            state.error = error;
        },
    },
});

export const { reducer } = slice;

export const setError =
    (error: any): AppThunk =>
    (dispatch): void => {
        dispatch(slice.actions.setError(error));
    };

export default slice;
