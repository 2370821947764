import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { fulfillmentApi } from 'src/api/fulfillment';
import { orderApi } from 'src/api/order';
import LoadingDashboard from 'src/components/dashboard/LoadingDashboard';
import OrderFulfillments from 'src/components/dashboard/order/OrderFulfillments';
import OrderSummary from 'src/components/dashboard/order/OrderSummary';
import Scrollbar from 'src/components/Scrollbar';
import { setError } from 'src/slices/error';
import { useDispatch } from 'src/store';
import { Fulfillment } from 'src/types/fulfillment';
import { Order } from 'src/types/order';

import { Box, Container } from '@mui/material';

import Headline from './Headline';

const OrderDetails: FC = () => {
    const dispatch = useDispatch();
    const { orderId } = useParams();

    const [created_at, setCreatedAt] = useState<Date>();
    const [fulfillments, setFulfillments] = useState<Fulfillment[]>([]);
    const [histories, setHistories] = useState<Order[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [order, setOrder] = useState<Order>();
    const [tab, setTab] = useState<string>('details');

    const onTabChange = (event: ChangeEvent<{}>, value: string): void => {
        setTab(value);
    };

    const fetchData = useCallback(async (): Promise<void> => {
        try {
            setLoading(true);

            const order = await orderApi.findById(orderId, {
                populate: 'data.documents,client,warehouse:name,items.item,original_file',
            });

            setOrder(order);

            const histories = await orderApi.getHistory(orderId, {
                populate: 'data.documents,client,warehouse:name,items.item,user,original_file',
            });
            const fulfillments = await fulfillmentApi.find({
                all_warehouses: true,
                order: orderId,
                populate: 'collection_id:name,warehouse:name,items.item',
                pageSize: 250,
            });

            setCreatedAt(order.created_at);
            setHistories(histories);
            setFulfillments(fulfillments);
        } catch (error) {
            dispatch(setError(error));
        } finally {
            setLoading(false);
        }
    }, [orderId, dispatch]);

    useEffect(() => {
        fetchData();
    }, [orderId, fetchData]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const refresh = (): Promise<void> => fetchData();

    if (!order || loading) return <LoadingDashboard />;

    return (
        <>
            <Helmet>
                <title>{order.name} - Shipman</title>
            </Helmet>

            <Scrollbar options={{ suppressScrollX: true }}>
                <Container sx={{ width: { xl: '83.5%', lg: '100%' } }} maxWidth={false}>
                    <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                        <Headline
                            fulfillments={fulfillments}
                            histories={histories}
                            order={order}
                            orderId={orderId}
                            tab={tab}
                            onTabChange={onTabChange}
                            refresh={refresh}
                            setOrder={setOrder}
                        />

                        {tab === 'details' && <OrderSummary order={{ ...order, created_at }} />}
                        {tab === 'fulfillments' && <OrderFulfillments fulfillments={fulfillments} />}
                    </Box>
                </Container>
            </Scrollbar>
        </>
    );
};

export default OrderDetails;
