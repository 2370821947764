import { Delivery } from 'src/types/delivery';
import axios from 'src/utils/axios';

class DeliveryApi {
    async find(params: any = {}): Promise<Delivery[]> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/deliveries', params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Deliverys Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async count(params: any = {}): Promise<number> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/deliveries', params: { ...params, pageSize: 1 } })
                .then((response) => {
                    resolve(Number(response.headers.totalcount));
                })
                .catch((error) => {
                    console.error('[Deliverys Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async findById(ID: string, params: any = {}): Promise<Delivery> {
        if (!ID) return null;
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/deliveries/' + ID, params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Deliverys Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async getHistory(ID: string, params: any = {}): Promise<Delivery[]> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/deliveries/' + ID + '/history', params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Deliverys Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async findAll(params: any = {}): Promise<Delivery[]> {
        const count = await this.count(params);

        var deliveries = [];
        var page = 1;

        while (deliveries.length < count) {
            const result = await this.find({ ...params, pageSize: 250, page });
            deliveries = [...deliveries, ...result];
            page += 1;
        }

        return deliveries;
    }

    async create(data: Delivery): Promise<Delivery> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'POST',
                url: '/deliveries',
                data,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Deliverys Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async update(ID: string, data: Delivery): Promise<Delivery> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: '/deliveries/' + ID,
                data,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Deliverys Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async delete(ID: string): Promise<Delivery> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'DELETE',
                url: '/deliveries/' + ID,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Deliverys Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }
}

export const deliveryApi = new DeliveryApi();
