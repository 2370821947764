import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router';

import useAuth from 'src/hooks/useAuth';

interface Props {
    children: ReactNode;
}

const GuestGuard: FC<Props> = ({ children }) => {
    const { isAuthenticated } = useAuth();

    if (isAuthenticated) {
        return <Navigate to='/dashboard/' />;
    }

    return <>{children}</>;
};

export default GuestGuard;
