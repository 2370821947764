import { FC, ReactNode } from 'react';

import { Box, LinearProgress, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

interface Props {
    children: ReactNode;
    loading: boolean;
    width?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        minHeight: '100%',
        padding: theme.spacing(3),
    },
}));

const LoadingDashboardWrapper: FC<Props> = ({ children, loading, width }) => {
    const classes = useStyles();

    if (loading) {
        return (
            <div className={classes.root}>
                <Box width={width || 400}>
                    <LinearProgress />
                </Box>
            </div>
        );
    } else {
        return <>{children}</>;
    }
};

export default LoadingDashboardWrapper;
