import { ApexOptions } from 'apexcharts';
import { groupBy } from 'lodash';
import { FC } from 'react';
import Chart from 'react-apexcharts';

import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { SHOP_LABELS } from 'src/constants/shop';
import { Fulfillment } from 'src/types/fulfillment';

interface Props {
    fulfillments: Fulfillment[];
}

const AnalyticsOrigins: FC<Props> = ({ fulfillments }) => {
    const theme = useTheme();

    const origins = groupBy(fulfillments, 'Schnittstelle');

    const chartOptions: ApexOptions = {
        chart: {
            background: 'transparent',
            stacked: false,
            toolbar: {
                show: false,
            },
        },
        colors: Object.keys(origins).map((e) => SHOP_LABELS[e].colorCode),
        dataLabels: {
            enabled: false,
        },
        labels: Object.keys(origins).map((e) => SHOP_LABELS[e].text),
        legend: {
            fontSize: '14px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.subtitle2.fontWeight,
            itemMargin: {
                vertical: 8,
            },
            labels: {
                colors: theme.palette.text.primary,
            },
            markers: {
                width: 8,
                height: 8,
            },
            show: true,
        },
        stroke: {
            width: 0,
        },
        theme: {
            mode: theme.palette.mode,
        },
    };

    const chartSeries = Object.keys(origins).map((e) => origins[e].length);

    return (
        <Card>
            <CardHeader
                disableTypography
                title={
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}>
                        <Typography color='textPrimary' variant='h6'>
                            Quellen
                        </Typography>
                    </Box>
                }
            />
            <CardContent>
                <Chart height={300} options={chartOptions} series={chartSeries} type='donut' />
            </CardContent>
        </Card>
    );
};

export default AnalyticsOrigins;
