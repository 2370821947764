import { User } from 'src/types/user';
import axios from 'src/utils/axios';

class UserApi {
    async find(params: any = {}): Promise<User[]> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/users', params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Users Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async count(params: any = {}): Promise<number> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/users', params: { ...params, pageSize: 1 } })
                .then((response) => {
                    resolve(Number(response.headers.totalcount));
                })
                .catch((error) => {
                    console.error('[Users Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async findById(ID: string, params: any = {}): Promise<User> {
        if (!ID) return null;
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/users/' + ID, params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Users Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async getHistory(ID: string, params: any = {}): Promise<User[]> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/users/' + ID + '/history', params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Users Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async create(data: User): Promise<User> {
        console.log(data);
        return new Promise((resolve, reject) =>
            axios({
                method: 'POST',
                url: '/users',
                data,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Users Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async update(ID: string, data: User): Promise<User> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: '/users/' + ID,
                data,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Users Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async delete(ID: string): Promise<User> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'DELETE',
                url: '/users/' + ID,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Users Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }
}

export const userApi = new UserApi();
