import NProgress from 'nprogress';
import { FC, useEffect } from 'react';

const LoadingScreen: FC = () => {
    useEffect(() => {
        NProgress.start();

        return (): void => {
            NProgress.done();
        };
    }, []);

    return <></>;
};

export default LoadingScreen;
