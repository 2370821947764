import { FC } from 'react';

import { Box, LinearProgress, SxProps, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        minHeight: '100%',
        padding: theme.spacing(3),
    },
}));

interface Props {
    sx?: SxProps<Theme>;
}

const LoadingDashboard: FC<Props> = ({ sx }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Box width={400} sx={sx}>
                <LinearProgress />
            </Box>
        </div>
    );
};

export default LoadingDashboard;
