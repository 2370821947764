import { get, isObject } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Check, Clear, Mail, Warning } from '@mui/icons-material/';
import { Avatar, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { notificationApi } from 'src/api/notification';
import Scrollbar from 'src/components/Scrollbar';
import useAuth from 'src/hooks/useAuth';
import { setError } from 'src/slices/error';
import { useDispatch, useSelector } from 'src/store';
import { getErrorMessage } from 'src/utils/get-error-message';

const ErrorDialog: FC = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
    const { user, logout } = useAuth();
    const { error } = useSelector((state) => state.error);

    const [message, setMessage] = useState<string>('');
    const [note, setNote] = useState<string>('');
    const [sending, setSending] = useState<boolean>(false);
    const [sent, setSent] = useState<boolean>(false);
    const [ticket, setTicket] = useState<boolean>(false);

    const [newError, setNewError] = useState<boolean>(false);

    const setErrorMessage = useCallback(
        (error) => {
            if (!error) setSent(false);
            if (error && String(get(error, 'statusCode')) === '401') logout();

            if (error) {
                const errorMessage = getErrorMessage(error);
                setMessage(errorMessage);
            }
        },
        [logout]
    );

    useEffect(() => {
        setErrorMessage(error);
    }, [error, setErrorMessage]);

    const sendTicket = () => {
        setSending(true);

        notificationApi
            .sendMail({
                to: 'nikilplex@gmail.com',
                subject: 'Shipman Error Ticket #' + uuidv4(),
                text: `Path: ${window.location.href} \nUser: ${user.username} \nNote: ${note} \n\nError: ${
                    isObject(error) ? JSON.stringify(error, null, 2) : error
                }`,
            })
            .then(() => {
                setSending(false);
                setTicket(false);
                setSent(true);
            })
            .catch((error) => {
                console.log(error);
                setNewError(true);
                setSending(false);
            });
    };

    const onClose = () => {
        dispatch(setError(null));
    };

    if (!error) return <></>;

    return (
        <>
            {get(error, 'statusCode') === 404 ? (
                <Dialog open={error} scroll='paper' maxWidth='md' fullWidth>
                    <DialogTitle>
                        <Box
                            sx={{
                                display: 'flex',
                            }}>
                            <Avatar
                                sx={{
                                    backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
                                    color: 'error.main',
                                    mr: 1,
                                }}>
                                <Warning />
                            </Avatar>
                            <Box sx={{ mt: 0.5, mr: 1 }}>
                                <Typography color='textPrimary' variant='h5'>
                                    Fehler
                                </Typography>
                            </Box>
                            <Box>
                                {ticket ? (
                                    <IconButton
                                        onClick={() => {
                                            setTicket(false);
                                        }}
                                        disabled={sending}
                                        color='primary'
                                        size='large'>
                                        <Clear fontSize='small' />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        onClick={() => {
                                            setTicket(true);
                                        }}
                                        disabled={sent || sending}
                                        color='primary'
                                        size='large'>
                                        {sent ? <Check fontSize='small' /> : <Mail fontSize='small' />}
                                    </IconButton>
                                )}
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Container maxWidth='lg'>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mb: 2,
                                }}>
                                <Box
                                    alt='Not Found'
                                    component='img'
                                    src={`/static/error/error-404.svg`}
                                    sx={{
                                        height: 'auto',
                                        maxWidth: '100%',
                                        width: 400,
                                    }}
                                />
                            </Box>
                            <Typography align='center' color='textPrimary' variant={mobileDevice ? 'h4' : 'h2'}>
                                Nicht gefunden
                            </Typography>
                        </Container>
                        {ticket && (
                            <TextField
                                sx={{ mt: 4 }}
                                fullWidth
                                required
                                disabled={sending}
                                error={newError || !note}
                                helperText='mindestens 5 Buchstaben'
                                label='Notiz'
                                name='note'
                                onChange={(event) => {
                                    setNote(event.target.value);
                                }}
                                value={note}
                                variant='outlined'
                            />
                        )}
                    </DialogContent>
                    <DialogActions sx={{ mr: 1 }}>
                        <Button
                            disabled={sending}
                            onClick={() => {
                                window.history.back();
                                onClose();
                            }}
                            color='primary'
                            variant='outlined'>
                            Zurück
                        </Button>
                        {ticket ? (
                            <Button
                                sx={{
                                    backgroundColor: 'error.main',
                                    '&:hover': {
                                        backgroundColor: 'error.dark',
                                    },
                                }}
                                disabled={sending || !note}
                                startIcon={<Mail fontSize='small' />}
                                variant='contained'
                                onClick={sendTicket}>
                                Senden
                            </Button>
                        ) : (
                            <Button
                                sx={{
                                    backgroundColor: 'error.main',
                                    '&:hover': {
                                        backgroundColor: 'error.dark',
                                    },
                                }}
                                disabled={sending}
                                variant='contained'
                                onClick={onClose}>
                                OK
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            ) : (
                <Dialog open={error} scroll='paper' maxWidth='md' fullWidth>
                    <DialogTitle>
                        <Box
                            sx={{
                                display: 'flex',
                            }}>
                            <Avatar
                                sx={{
                                    backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
                                    color: 'error.main',
                                    mr: 1,
                                }}>
                                <Warning />
                            </Avatar>
                            <Box sx={{ mt: 0.5, mr: 1 }}>
                                <Typography color='textPrimary' variant='h5'>
                                    Fehler
                                </Typography>
                            </Box>
                            <Box>
                                {ticket ? (
                                    <IconButton
                                        onClick={() => {
                                            setTicket(false);
                                        }}
                                        disabled={sending}
                                        color='primary'
                                        size='large'>
                                        <Clear fontSize='small' />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        onClick={() => {
                                            setTicket(true);
                                        }}
                                        disabled={sent || sending}
                                        color='primary'
                                        size='large'>
                                        {sent ? <Check fontSize='small' /> : <Mail fontSize='small' />}
                                    </IconButton>
                                )}
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Scrollbar>
                            <Box
                                sx={{
                                    display: 'block',
                                    pt: 1,
                                    pb: 2,
                                    px: 5,
                                }}>
                                <Box>
                                    {message ? (
                                        <>
                                            {message.split(';').map((line) => (
                                                <Typography color='textSecondary' variant='body1'>
                                                    {line}
                                                </Typography>
                                            ))}
                                        </>
                                    ) : (
                                        <Typography color='textSecondary' variant='body2'>
                                            {isObject(error) ? <pre>{JSON.stringify(error, null, 2)}</pre> : error}
                                        </Typography>
                                    )}

                                    {ticket && (
                                        <TextField
                                            sx={{ mt: 4 }}
                                            fullWidth
                                            required
                                            disabled={sending}
                                            error={newError || !note}
                                            helperText='mindestens 5 Buchstaben'
                                            label='Notiz'
                                            name='note'
                                            onChange={(event) => {
                                                setNote(event.target.value);
                                            }}
                                            value={note}
                                            variant='outlined'
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Scrollbar>
                    </DialogContent>
                    <DialogActions sx={{ mr: 1 }}>
                        <Button
                            disabled={sending}
                            onClick={() => {
                                window.history.back();
                                onClose();
                            }}
                            color='primary'
                            variant='outlined'>
                            Zurück
                        </Button>
                        {ticket ? (
                            <Button
                                sx={{
                                    backgroundColor: 'error.main',
                                    '&:hover': {
                                        backgroundColor: 'error.dark',
                                    },
                                }}
                                disabled={sending || !note}
                                startIcon={<Mail fontSize='small' />}
                                variant='contained'
                                onClick={sendTicket}>
                                Senden
                            </Button>
                        ) : (
                            <Button
                                sx={{
                                    backgroundColor: 'error.main',
                                    '&:hover': {
                                        backgroundColor: 'error.dark',
                                    },
                                }}
                                disabled={sending}
                                variant='contained'
                                onClick={onClose}>
                                OK
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default ErrorDialog;
