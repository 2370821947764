import Label from 'src/components/dashboard/Label';
import { CARRIER_LABELS } from 'src/constants/carrier';
import { Client } from 'src/types/client';

import { alpha } from '@mui/material';

export const CarrierLabel = (value: Client | string): JSX.Element => {
    const carrier = typeof value === 'string' ? value : value.carrier;
    const active =
        typeof value === 'string' ? true : carrier === 'self' ? true : value.credentials.carrier[carrier].production;

    return (
        <Label
            color={CARRIER_LABELS[carrier]['color']}
            colorCode={
                active ? CARRIER_LABELS[carrier]['colorCode'] : alpha(CARRIER_LABELS[carrier]['colorCode'], 0.3)
            }>
            {CARRIER_LABELS[carrier]['text']}
        </Label>
    );
};
