/* eslint-disable react/no-array-index-key */
import { FC, KeyboardEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Search as SearchIcon } from '@mui/icons-material';
import { CircularProgress, InputAdornment, InputBase, List, ListItemButton, ListItemText, Popover, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import { extraApi } from 'src/api/extra';
import { similarity } from 'src/utils/similarity';

interface Result {
    description: string;
    title: string;
    tags: string;
    to: string;
}

const guideList: Result[] = [
    {
        title: 'Dashboard',
        description: 'Das Dashboard gibt einen Überblick aller Aufträge für den Tag.',
        tags: 'Übersicht,Zusammenfassung,Home,Landing,Startseite',
        to: '/dashboard',
    },
    {
        title: 'Account',
        description: 'Account Einstellungen ansehen & bearbeiten',
        tags: 'Account,Benutzerdaten,Einstellungen,Logout,ausloggen,User,Userdaten',
        to: '/dashboard/account',
    },
    {
        title: 'Dokumentation',
        description: 'Das Handbuch für Shipman',
        tags: 'Hilfe,Infos,Handbuch,Fragen,Antworten,Tips,Erklärung,?,??,???,????????,docu,doc,docs,doku',
        to: '/docs',
    },
    {
        title: 'Aufträge',
        description: 'Alle Aufträge in verschiedenen Stati',
        tags: 'Auftragseingagn,Offen,Orders,Import,Eingang',
        to: '/dashboard/orders',
    },
    {
        title: 'Sendungen',
        description: 'Lieferungen / Fulfillments',
        tags: 'Lieferung,Sendung,Tracking,Pakete,Trackingnummer,Nachverfolgung,Unterschrift,Historie,Sendungshistorie,Paketdaten',
        to: '/dashboard/fulfillments',
    },
    {
        title: 'Läufe',
        description: 'Alle Läufe in einer Übersicht.',
        tags: 'Lauf,collection,Multi,Multi Label,Neutral',
        to: '/dashboard/collections',
    },
    {
        title: 'Entwürfe',
        description:
            'Hier sammeln sich alle Aufträge, bei denen der Import nicht geklappt hat. Bitte regelmäßig kontrollieren & bearbeiten.',
        tags: 'draft,Import,Fehler,Korrektur',
        to: '/dashboard/draft-orders',
    },
    {
        title: 'Produkte',
        description: 'Übersicht aller Produkte.',
        tags: 'Artikel,Stammdaten,Maße,Freigabe,Höhe,Länge,Breite',
        to: '/dashboard/products',
    },
    {
        title: 'Stellplätze',
        description: 'Stellplätze in den verschiedenen Lagern.',
        tags: 'Stellplatz,Lager,Buchen,Reservierung,reservieren,abbuchen,Bestand,verfügbar,Artikel,Produkt,Product,Storage',
        to: '/dashboard/storages',
    },
    {
        title: 'Verpackungen',
        description: 'Verpackungen alle in einer Übersicht',
        tags: 'Pack,Packung,Karton,Kartonage,Pappe,Package,Packaging,Maße,Höhe,Länge,Breite',
        to: '/dashboard/packagings',
    },
    {
        title: 'Lager',
        description: 'Lagerstandorte',
        tags: 'Halle,Lager,Standort,Warehouse,Adresse,Einlagern,Stellplatz,Produkte,Ludwigsfelde,Braunschweig',
        to: '/dashboard/warehouses',
    },
    {
        title: 'Kunden',
        description: 'Kunden und ihre Daten für Schnittstellen / Versanddienstleister müssen sorgfältig eingepflegt & kontrolliert werden.',
        tags: 'Client,Kundendaten,Daten,Stammdaten,einpflegen,Neukunde,Kunden anlegen,Aufschalten,Schnittstelle,DHL,HERMES,GLS,Versand,Nummern,Passwort,password,hinterlegen,Logo,Lieferschein,Email,meldung,Import,importmeldung',
        to: '/dashboard/clients',
    },
    {
        title: 'Logs',
        description: 'Eine Übersicht aller Systemlogs. Sollte es Systemfehler geben, lassen sich diese hier analysieren',
        tags: '',
        to: '/dashboard/logs',
    },
];

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    ...(theme.palette.mode === 'light' && {
        backgroundColor: alpha(theme.palette.common.black, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.black, 0.25),
        },
    }),
    ...(theme.palette.mode === 'dark' && {
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
    }),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '32ch',
            '&:focus': {
                width: '60ch',
            },
        },
    },
}));

const ContentSearch: FC = () => {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);

    const [guide, setGuide] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [results, setResults] = useState<any>([]);
    const [value, setValue] = useState<string>('');

    const handleClose = (): void => {
        setOpen(false);
    };

    const search = async (): Promise<void> => {
        setOpen(false);
        setLoading(true);

        if (value) {
            try {
                const data = await extraApi.search(value);

                setResults(data);
            } catch (error) {}

            setGuide(
                guideList.filter(
                    (e) =>
                        similarity(e.title, value) > 0.5 ||
                        e.tags
                            .split(',')
                            .map((d) => similarity(d, value))
                            .filter((d) => d > 0.6).length > 0
                )
            );
        } else {
            setResults([]);
            setGuide(guideList);
        }

        setLoading(false);
        setOpen(true);
    };

    const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>): void => {
        setAnchorEl(event.currentTarget);

        const enterCodes = ['ENTER', 'Enter', 'NumpadEnter'];

        if (enterCodes.includes(event.code)) {
            search();
        }
    };

    const handeNavigate = (url: string): void => {
        navigate(url);

        handleClose();
    };

    return (
        <>
            <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    onChange={(event): void => setValue(event.target.value)}
                    placeholder='Suche...'
                    inputProps={{ 'aria-label': 'search' }}
                    onKeyUp={handleKeyUp}
                    endAdornment={
                        <InputAdornment sx={{ pr: 2 }} position='end'>
                            <CircularProgress color='inherit' size={20} thickness={loading ? 4 : 0} />
                        </InputAdornment>
                    }
                />
            </Search>

            <Popover
                sx={{ mt: 5, ml: 5 }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: { width: 560 },
                }}>
                {guide.length > 0 && (
                    <List>
                        {guide.map((result, index) => (
                            <ListItemButton onClick={() => handeNavigate(`/dashboard${result.to}`)}>
                                <ListItemText key={index} primary={result.title} secondary={result.description} />
                            </ListItemButton>
                        ))}
                    </List>
                )}
                {results.length > 0 && (
                    <List>
                        {results.map((result, index) => (
                            <ListItemButton onClick={() => handeNavigate(`/dashboard${result.to}`)}>
                                <ListItemText key={index} primary={result.title} secondary={result.description} />
                            </ListItemButton>
                        ))}
                    </List>
                )}

                {results.length === 0 && guide.length === 0 && (
                    <Typography sx={{ m: 3 }} color='textPrimary' variant='h6'>
                        Nichts gefunden ...
                    </Typography>
                )}
            </Popover>
        </>
    );
};

export default ContentSearch;
