export const FULFILLMENT_STATUS_LABELS = {
    completed: {
        text: 'Abgeschlossen',
        colorCode: '#4CAF50',
    },
    packed: {
        text: 'Gepackt',
        colorCode: '#688eff',
    },
    picked: {
        text: 'Gepickt',
        colorCode: '#e4c605',
    },
    reservation: {
        text: 'Reservierung',
        colorCode: '#ff9800',
    },
    open: {
        text: 'Offen',
        colorCode: '#cf5d4e',
    },
};

export const FULFILLMENT_STATUS_OPTIONS = [
    {
        label: 'Alle',
        value: '',
    },
    {
        label: 'Offen',
        value: 'open',
    },
    {
        label: 'Reservierung',
        value: 'reservation',
    },
    {
        label: 'Gepickt',
        value: 'picked',
    },
    {
        label: 'Gepackt',
        value: 'packed',
    },
    {
        label: 'Abgeschlossen',
        value: 'completed',
    },
];
