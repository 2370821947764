import { TypedUseSelectorHook, useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import { ThunkAction } from 'redux-thunk';

import { Action, configureStore } from '@reduxjs/toolkit';

import rootReducer from './rootReducer';

const store = configureStore({
    reducer: rootReducer,
    devTools: (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();

export default store;
