import { FC, ReactNode } from 'react';

import useAuth from 'src/hooks/useAuth';

interface Props {
    children: ReactNode;
    roles: string[];
}

const RoleGuard: FC<Props> = ({ children, roles }) => {
    const { user } = useAuth();

    if (roles.includes(user.role)) {
        return <>{children}</>;
    } else {
        return <></>;
    }
};

export default RoleGuard;
