import * as faker from 'faker';
import 'faker/locale/de';
import { isEqual, pick } from 'lodash';
import moment from 'moment';
import 'moment/locale/de';
import { FC, useCallback, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useRoutes } from 'react-router';
import { userApi } from 'src/api/user';
import ErrorDialog from 'src/components/Error';
import LoadingBackdrop from 'src/components/LoadingBackdrop';
import SplashScreen from 'src/components/SplashScreen';
import { ROLE } from 'src/constants/role';
import useAuth from 'src/hooks/useAuth';
import useScrollReset from 'src/hooks/useScrollReset';
import useSettings from 'src/hooks/useSettings';
import routes from 'src/routes';
import { setError } from 'src/slices/error';
import { getNotifications } from 'src/slices/notification';
import { useDispatch } from 'src/store';
import { createCustomTheme } from 'src/theme';

import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';

import Loading from './components/Loading';

const App: FC = () => {
    const auth = useAuth();
    const content = useRoutes(routes);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { settings } = useSettings();
    const { user, isAuthenticated, update, logout } = useAuth();

    moment.locale('de');
    faker.locale = 'de';

    useScrollReset();

    const theme = createCustomTheme({ theme: settings.theme });

    const handleLogout = useCallback(async (): Promise<void> => {
        try {
            toast.error('Lager wurde im Benutzer geändert. Bitte erneut einloggen');

            await logout();
            navigate('/');
        } catch (error) {
            dispatch(setError(error));
        }
    }, [logout, navigate, dispatch]);

    const fetchData = useCallback(
        async (ID?: string): Promise<void> => {
            try {
                if (user.role === ROLE.CLIENT) return;

                if (ID) {
                    const me = await userApi.findById(ID);

                    const isSame = isEqual(
                        pick(user, ['username', 'role', 'warehouse']),
                        pick(me, ['username', 'role', 'warehouse'])
                    );

                    if (!isSame) update(me);
                    if (me.warehouse !== user.warehouse) handleLogout();
                }

                dispatch(getNotifications());
            } catch (error) {
                // dispatch(setError(error));
            }
        },
        [user, update, handleLogout, dispatch]
    );

    useEffect(() => {
        if (!isAuthenticated) return;
        if (!user || ![ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.DISPATCHER].includes(user.role)) return;

        fetchData();

        const interval = setInterval(() => {
            fetchData(user._id);
        }, 5000);

        return () => clearInterval(interval);
    }, [isAuthenticated, user, fetchData]);

    useEffect(() => {
        const currentVersion = window.localStorage.getItem('version');
        const mostRecentVersion = '1.9.5';

        if (!currentVersion || currentVersion !== mostRecentVersion || (user && user.role === ROLE.CLIENT)) {
            const accessToken = window.localStorage.getItem('accessToken');
            const settings = window.localStorage.getItem('settings');
            window.localStorage.clear();
            window.localStorage.setItem('version', mostRecentVersion);
            window.localStorage.setItem('accessToken', accessToken);
            window.localStorage.setItem('settings', settings);
        }
    }, [user]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Loading />
                <LoadingBackdrop />
                <ErrorDialog />
                <CssBaseline />
                <Toaster position='top-center' />
                {auth.isInitialized ? content : <SplashScreen />}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
