import { get, sumBy, uniqBy } from 'lodash';
import { FC } from 'react';

import { AddBox, BuildCircle, Healing, ListAlt, LocalShipping, MarkunreadMailbox, Warning, Widgets } from '@mui/icons-material';
import { Box, Card, CardHeader, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { Fulfillment } from 'src/types/fulfillment';

interface Props {
    fulfillments: Fulfillment[];
}

const AnalyticsTotal: FC<Props> = ({ fulfillments }) => {
    return (
        <Card>
            <CardHeader
                disableTypography
                title={
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}>
                        <Typography color='textPrimary' variant='h6'>
                            Analyse
                        </Typography>
                    </Box>
                }
            />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Art</TableCell>
                        <TableCell>Anzahl</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                }}>
                                <LocalShipping fontSize='small' />
                                <Typography color='textPrimary' sx={{ ml: 2 }} variant='subtitle2'>
                                    Aufträge
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell>{uniqBy(fulfillments, 'Auftragsnummer').length}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                }}>
                                <MarkunreadMailbox fontSize='small' />
                                <Typography color='textPrimary' sx={{ ml: 2 }} variant='subtitle2'>
                                    Pakete
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell>{fulfillments.length}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                }}>
                                <Warning fontSize='small' />
                                <Typography color='textPrimary' sx={{ ml: 2 }} variant='subtitle2'>
                                    Neutral
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell>{fulfillments.filter((e) => get(e, 'Neutral')).length}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                }}>
                                <ListAlt fontSize='small' />
                                <Typography color='textPrimary' sx={{ ml: 2 }} variant='subtitle2'>
                                    Positionen
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell>{sumBy(fulfillments, 'Positionen')}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                }}>
                                <Widgets fontSize='small' />
                                <Typography color='textPrimary' sx={{ ml: 2 }} variant='subtitle2'>
                                    Picks
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell>{sumBy(fulfillments, 'Picks')}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                }}>
                                <BuildCircle fontSize='small' />
                                <Typography color='textPrimary' sx={{ ml: 2 }} variant='subtitle2'>
                                    Anbruch
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell>{fulfillments.filter((e) => !get(e, 'Eigenverpackung')).length}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ border: 0 }}>
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                }}>
                                <Healing fontSize='small' />
                                <Typography color='textPrimary' sx={{ ml: 2 }} variant='subtitle2'>
                                    Pflegepakete
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell sx={{ border: 0 }}>????</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Card>
    );
};

export default AnalyticsTotal;
