import FileSaver from 'file-saver';
import moment from 'moment';
import { FC, useState } from 'react';
import { defaultStyles, FileIcon } from 'react-file-icon';
import { Lightbox } from 'react-modal-image';
import { fileApi } from 'src/api/file';
import { setError } from 'src/slices/error';
import { useDispatch } from 'src/store';
import { File } from 'src/types/file';
import bytesToSize from 'src/utils/bytes-to-size';

import { ArrowBack } from '@mui/icons-material';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';

const format = require('xml-formatter');

interface Props {
    file: File;
}

const FileCard: FC<Props> = (props) => {
    const { file } = props;
    const dispatch = useDispatch();

    const [loading, setLoading] = useState<boolean>(false);
    const [fileData, setFileData] = useState<File>(null);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const handleExpand = () => {
        setLoading(true);

        fileApi
            .findById(file._id)
            .then((data) => {
                setFileData(data);
                setLoading(false);
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    };

    const handleDowndload = () => {
        setLoading(true);

        fileApi
            .findById(file._id)
            .then((data) => {
                FileSaver.saveAs(new Blob([Buffer.from(data.file, 'base64')], { type: data.mimetype }), data.name);
                setLoading(false);
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    };

    return (
        <>
            <Card>
                <Box
                    sx={{
                        alignItems: 'center',
                        backgroundColor: theme.palette.text.secondary,
                        color: '#000000',
                        display: 'flex',
                        height: 140,
                        justifyContent: 'center',
                    }}>
                    <div style={{ width: 90 }}>
                        <FileIcon
                            fold
                            radius={4}
                            extension={file.ext}
                            {...defaultStyles[file.ext]}
                            {...(['png', 'jpg'].includes(file.ext) && { labelColor: '#03368a' })}
                            {...(['xml'].includes(file.ext) && { labelColor: '#2A6E01', type: 'document' })}
                            {...(['txt'].includes(file.ext) && { labelColor: '#860f91', type: 'document' })}
                            {...(['json'].includes(file.ext) && { labelColor: '#c4c035', type: 'document' })}
                        />
                    </div>
                </Box>
                <CardContent
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                    <div>
                        <Tooltip title={file.name}>
                            <Typography color='textPrimary' variant='subtitle2'>
                                {file.name.length > 28 ? file.name.slice(0, 27) + '...' : file.name}
                            </Typography>
                        </Tooltip>
                        <Typography color='textSecondary' variant='caption'>
                            {bytesToSize(file.size)} - {moment(file.created_at).format('L HH:mm')}
                        </Typography>
                    </div>
                    {loading && (
                        <IconButton size='small'>
                            <CircularProgress size={20} />
                        </IconButton>
                    )}
                </CardContent>
                <Divider />
                <CardActions>
                    {matches && file.mimetype.includes('pdf') ? (
                        <Button onClick={handleDowndload} disabled={loading} fullWidth variant='text'>
                            Vorschau
                        </Button>
                    ) : (
                        <Button onClick={handleExpand} disabled={loading} fullWidth variant='text'>
                            Vorschau
                        </Button>
                    )}

                    <Button onClick={handleDowndload} disabled={loading} fullWidth variant='text'>
                        Download
                    </Button>
                </CardActions>
            </Card>

            {fileData && file.mimetype.includes('image') && (
                <Lightbox
                    hideDownload
                    large={URL.createObjectURL(
                        new Blob([Buffer.from(fileData.file, 'base64')], { type: file.mimetype })
                    )}
                    onClose={(): void => setFileData(null)}
                    alt={file.name}
                />
            )}
            {fileData && file.mimetype.includes('pdf') && (
                <Dialog fullScreen open={Boolean(fileData)}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                        }}>
                        <Box
                            sx={{
                                backgroundColor: '#111827',
                                p: 1.65,
                            }}>
                            <Button
                                color='primary'
                                startIcon={<ArrowBack fontSize='small' />}
                                onClick={(): void => setFileData(null)}
                                variant='contained'>
                                Zurück
                            </Button>
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <iframe
                                title='File'
                                src={URL.createObjectURL(
                                    new Blob([Buffer.from(fileData.file, 'base64')], { type: 'application/pdf' })
                                )}
                                width='100%'
                                height='100%'
                                frameBorder='0'
                            />
                        </Box>
                    </Box>
                </Dialog>
            )}
            {fileData && file.mimetype.includes('xml') && (
                <Dialog open={Boolean(fileData)} fullWidth maxWidth='lg' onClose={() => setFileData(null)}>
                    <DialogTitle>{fileData.name}</DialogTitle>
                    <DialogContent sx={{ p: 5 }}>
                        <Typography color='textSecondary' variant='body1'>
                            <pre>{format(Buffer.from(fileData.file, 'base64').toString('utf-8'))}</pre>
                        </Typography>
                    </DialogContent>
                </Dialog>
            )}
            {fileData && file.mimetype.includes('json') && (
                <Dialog open={Boolean(fileData)} fullWidth maxWidth='lg' onClose={() => setFileData(null)}>
                    <DialogTitle>{fileData.name}</DialogTitle>
                    <DialogContent sx={{ p: 5 }}>
                        <Typography color='textSecondary' variant='body1'>
                            <pre>{Buffer.from(fileData.file, 'base64').toString('utf-8')}</pre>
                        </Typography>
                    </DialogContent>
                </Dialog>
            )}
            {fileData && file.mimetype.includes('csv') && (
                <Dialog open={Boolean(fileData)} fullWidth maxWidth='lg' onClose={() => setFileData(null)}>
                    <DialogTitle>{fileData.name}</DialogTitle>
                    <DialogContent sx={{ p: 5 }}>
                        <Typography color='textSecondary' variant='body1'>
                            <pre>{Buffer.from(fileData.file, 'base64').toString('binary')}</pre>
                        </Typography>
                    </DialogContent>
                </Dialog>
            )}
            {fileData && file.ext.includes('txt') && (
                <Dialog open={Boolean(fileData)} fullWidth maxWidth='lg' onClose={() => setFileData(null)}>
                    <DialogTitle>{fileData.name}</DialogTitle>
                    <DialogContent sx={{ p: 5 }}>
                        <Typography color='textSecondary' variant='body1'>
                            <pre>{Buffer.from(fileData.file, 'base64').toString('binary')}</pre>
                        </Typography>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default FileCard;
