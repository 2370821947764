import { DHL_DELIVERED_CODES } from './dhl';
import { GLS_DELIVERED_CODES } from './gls';
import { HERMES_DELIVERED_CODES } from './hermes';

export const CARRIERS = ['dhl', 'hermes', 'gls', 'self'];

export const CARRIER_LABELS = {
    dhl: {
        text: 'DHL',
        colorCode: '#e4bd05',
    },
    gls: {
        text: 'GLS',
        colorCode: '#141b4d',
    },
    hermes: {
        text: 'Hermes',
        colorCode: '#0290ce',
    },
    self: {
        text: 'Selbstabholer',
        colorCode: '#5c6163',
    },
};

export const CARRIER_OPTIONS = [
    {
        label: 'DHL',
        value: 'dhl',
    },
    {
        label: 'GLS',
        value: 'gls',
    },
    {
        label: 'Hermes',
        value: 'hermes',
    },
    {
        label: 'Selbstabholer',
        value: 'self',
    },
];

export const CARRIER_OPTIONS_WITH_EMPTY = [
    {
        label: 'DHL',
        value: 'dhl',
    },
    {
        label: 'GLS',
        value: 'gls',
    },
    {
        label: 'Hermes',
        value: 'hermes',
    },
    {
        label: 'Selbstabholer',
        value: 'self',
    },
    {
        label: 'Stammdaten verwenden',
        value: '',
    },
];

export const DELIVERED_CODES = [...DHL_DELIVERED_CODES, ...GLS_DELIVERED_CODES, ...HERMES_DELIVERED_CODES];
