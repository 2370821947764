import { Board, Card, Column } from 'src/types/kanban';
import axios from 'src/utils/axios';

class KanbanApi {
    findHistoryById(ID: string, params: any = {}): Promise<any> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/kanbans/' + ID, params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    findCardById(ID: string, params: any = {}): Promise<Card> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/kanbans/cards/' + ID, params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    findColumnById(ID: string, params: any = {}): Promise<Column> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/kanbans/columns/' + ID, params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    getBoard(): Promise<Board> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/kanbans' })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    getBoardPrivate(): Promise<Board> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/kanbans/private' })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    createColumn({ name, shared }): Promise<Column> {
        return new Promise((resolve, reject) =>
            axios({ method: 'POST', url: '/kanbans/columns', data: { name, shared } })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    updateColumn({ ID, update }): Promise<Column> {
        return new Promise((resolve, reject) =>
            axios({ method: 'PUT', url: '/kanbans/columns/' + ID, data: update })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    clearColumn(ID): Promise<true> {
        return new Promise((resolve, reject) =>
            axios({ method: 'PUT', url: '/kanbans/columns/' + ID + '/clear' })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    deleteColumn(ID): Promise<true> {
        return new Promise((resolve, reject) =>
            axios({ method: 'DELETE', url: '/kanbans/columns/' + ID })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    createCard({ name, user }): Promise<Card> {
        return new Promise((resolve, reject) =>
            axios({ method: 'POST', url: '/kanbans/cards/', data: { name, members: [user._id] } })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    updateCard({ ID, update }): Promise<Card> {
        return new Promise((resolve, reject) =>
            axios({ method: 'PUT', url: '/kanbans/cards/' + ID, data: update })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    moveCard({ ID, position, column }): Promise<Card> {
        return new Promise((resolve, reject) =>
            axios({ method: 'PUT', url: '/kanbans/cards/' + ID, data: { column, position } })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    deleteCard(ID): Promise<true> {
        return new Promise((resolve, reject) =>
            axios({ method: 'DELETE', url: '/kanbans/cards/' + ID })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    addChecklist({ ID, name }): Promise<Card> {
        return new Promise((resolve, reject) =>
            axios({ method: 'POST', url: '/kanbans/cards/' + ID + '/checklists', data: { name } })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    updateChecklist({ ID, checklistId, update }): Promise<Card> {
        return new Promise((resolve, reject) =>
            axios({ method: 'PUT', url: '/kanbans/cards/' + ID + '/checklists/' + checklistId, data: update })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    deleteChecklist({ ID, checklistId }): Promise<true> {
        return new Promise((resolve, reject) =>
            axios({ method: 'DELETE', url: '/kanbans/cards/' + ID + '/checklists/' + checklistId })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    addCheckItem({ ID, checklistId, name }): Promise<Card> {
        return new Promise((resolve, reject) =>
            axios({ method: 'POST', url: '/kanbans/cards/' + ID + '/checklists/' + checklistId + '/check-items', data: { name } })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    updateCheckItem({ ID, checklistId, checkItemId, update }): Promise<Card> {
        return new Promise((resolve, reject) =>
            axios({ method: 'PUT', url: '/kanbans/cards/' + ID + '/checklists/' + checklistId + '/check-items/' + checkItemId, data: update })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    deleteCheckItem({ ID, checklistId, checkItemId }): Promise<true> {
        return new Promise((resolve, reject) =>
            axios({ method: 'DELETE', url: '/kanbans/cards/' + ID + '/checklists/' + checklistId + '/check-items/' + checkItemId })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Kanbans Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }
}

export const kanbanApi = new KanbanApi();
