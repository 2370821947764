export const SHOPS = ['api', 'amazon', 'egeko', 'sanivision', 'shopify', 'topm', 'weclapp'];

export const SHOP_LABELS = {
    api: {
        text: 'API',
        colorCode: '#2da2bd',
    },
    amazon: {
        text: 'Amazon',
        colorCode: '#fc9900',
    },
    egeko: {
        text: 'Egeko',
        colorCode: '#146dd1',
    },
    sanivision: {
        text: 'Sanivision',
        colorCode: '#9f2035',
    },
    shopify: {
        text: 'Shopify',
        colorCode: '#95bf47',
    },
    topm: {
        text: 'TopM',
        colorCode: '#40617f',
    },
    weclapp: {
        text: 'Weclapp',
        colorCode: '#14c890',
    },
    woocommerce: {
        text: 'Woocommerce',
        colorCode: '#9b5c8f',
    },
    ftp: {
        text: 'FTP',
        colorCode: '#d66f42',
    },
    sftp: {
        text: 'SFTP',
        colorCode: '#723dcc',
    },
};
