export const GLS_SERVICES = [
    {
        label: 'Paket (Standard)',
        value: 'parcel',
    },
    {
        label: 'Express',
        value: 'express',
    },
    {
        label: 'Fracht',
        value: 'freight',
    },
];

export const GLS_DELIVERED_CODES = ['DELIVERED'];
