import { ApexOptions } from 'apexcharts';
import { get, groupBy } from 'lodash';
import moment from 'moment';
import { FC } from 'react';
import Chart from 'react-apexcharts';

import { Card, CardContent, CardHeader } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Fulfillment } from 'src/types/fulfillment';

interface Props {
    fulfillments: Fulfillment[];
}

const AnalyticsGraph: FC<Props> = ({ fulfillments }) => {
    const theme = useTheme();
    const groupedByDay = groupBy(
        fulfillments.map((e) => ({ ...e, date: moment(get(e, 'Versanddatum')).format('DD.MM.YY') })),
        'date'
    );

    const chartOptions: ApexOptions = {
        chart: {
            background: 'transparent',
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        colors: [theme.palette.primary.main],
        dataLabels: {
            enabled: false,
        },
        fill: {
            type: 'solid',
            opacity: 0.15,
        },
        grid: {
            show: false,
        },
        stroke: {
            curve: 'smooth',
            width: 4,
        },
        theme: {
            mode: theme.palette.mode,
        },
        tooltip: {
            enabled: false,
        },
        xaxis: {
            axisBorder: {
                color: theme.palette.divider,
            },
            categories: Object.keys(groupedByDay),
        },
    };

    const chartSeries = [
        {
            data: Object.keys(groupedByDay).map((e) => groupedByDay[e].length),
        },
    ];

    return (
        <Card>
            <CardHeader title='Aufträge' />
            <CardContent>
                <Chart height='360' options={chartOptions} series={chartSeries} type='area' />
            </CardContent>
        </Card>
    );
};

export default AnalyticsGraph;
