import { Collection, Pick } from 'src/types/collection';
import axios from 'src/utils/axios';
import { timeInMilliseconds } from 'src/utils/time-in-milliseconds';

class CollectionApi {
    async find(params: any = {}): Promise<Collection[]> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/collections', params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Collections Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async count(params: any = {}): Promise<number> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/collections', params: { ...params, pageSize: 1 } })
                .then((response) => {
                    resolve(Number(response.headers.totalcount));
                })
                .catch((error) => {
                    console.error('[Collections Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async findById(ID: string, params: any = {}): Promise<Collection> {
        if (!ID) return null;
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/collections/' + ID, params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Collections Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async pick(ID: string): Promise<Pick[]> {
        if (!ID) return null;
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: `/collections/${ID}/pick` })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Collections Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async pickList(ID: string): Promise<Collection> {
        if (!ID) return null;
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: `/collections/${ID}/picklist` })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Collections Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async getDocuments(ID: string, filter?: string[]): Promise<void> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'GET',
                url: `/collections/${ID}/documents`,
                ...(filter && { params: { filter: filter.join(',') } }),
                timeout: timeInMilliseconds(10, 'minutes'),
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Collections Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async getLabels(ID: string, filter?: string[]): Promise<void> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'GET',
                url: `/collections/${ID}/labels`,
                ...(filter && { params: { filter: filter.join(',') } }),
                timeout: timeInMilliseconds(10, 'minutes'),
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Collections Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async getCollectionList(ID: string): Promise<void> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'GET',
                url: `/collections/${ID}/list`,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Collections Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async getAllCollectionsList(carrier: 'DHL' | 'GLS' | 'Hermes'): Promise<void> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'GET',
                url: `/collections/${carrier}/listall`,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Collections Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async getHistory(ID: string, params: any = {}): Promise<Collection[]> {
        return new Promise((resolve, reject) =>
            axios({ method: 'GET', url: '/collections/' + ID + '/history', params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Collections Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async create(data: Collection): Promise<Collection> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'POST',
                url: '/collections',
                data,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Collections Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async update(ID: string, data: Collection): Promise<Collection> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: '/collections/' + ID,
                data,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Collections Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async setPicked(ID: string): Promise<void> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'PUT',
                url: `/collections/${ID}/picked`,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Collections Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async export(ID: string): Promise<void> {
        if (!ID) return null;
        return new Promise((resolve, reject) =>
            axios({ method: 'PUT', url: `/collections/${ID}/export` })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Collections Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }

    async delete(ID: string): Promise<Collection> {
        return new Promise((resolve, reject) =>
            axios({
                method: 'DELETE',
                url: '/collections/' + ID,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('[Collections Api]: ', error.response ? error.response.data : error);
                    reject(error.response ? error.response.data : error);
                })
        );
    }
}

export const collectionApi = new CollectionApi();
