import { createContext, FC, ReactNode, useEffect, useReducer } from 'react';

import { authApi } from 'src/api/auth';
import { User } from 'src/types/user';
import axios from 'src/utils/axios';

interface State {
    isInitialized: boolean;
    isAuthenticated: boolean;
    user: User | null;
}

interface AuthContextValue extends State {
    platform: 'JWT';
    update: (user: User) => void;
    login: (email: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
}

interface AuthProviderProps {
    children: ReactNode;
}

type InitializeAction = {
    type: 'INITIALIZE';
    payload: {
        isAuthenticated: boolean;
        user: User | null;
    };
};

type UpdateAction = {
    type: 'UPDATE';
    payload: {
        user: User;
    };
};

type LoginAction = {
    type: 'LOGIN';
    payload: {
        user: User;
    };
};

type LogoutAction = {
    type: 'LOGOUT';
};

type Action = InitializeAction | LoginAction | LogoutAction | UpdateAction;

const initialState: State = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

const handlers: Record<string, (state: State, action: Action) => State> = {
    INITIALIZE: (state: State, action: InitializeAction): State => {
        const { isAuthenticated, user } = action.payload;

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
        };
    },
    UPDATE: (state: State, action: UpdateAction): State => {
        const { user } = action.payload;

        return {
            ...state,
            user,
        };
    },
    LOGIN: (state: State, action: LoginAction): State => {
        const { user } = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user,
        };
    },
    LOGOUT: (state: State): State => ({
        ...state,
        isAuthenticated: false,
        user: null,
    }),
};

const reducer = (state: State, action: Action): State => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext<AuthContextValue>({
    ...initialState,
    platform: 'JWT',
    update: () => Promise.resolve(),
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            try {
                const accessToken = window.localStorage.getItem('accessToken');

                if (accessToken) {
                    const user = await authApi.me(accessToken);

                    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    });
                } else {
                    delete axios.defaults.headers.common.Authorization;

                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        };

        initialize();
    }, []);

    const update = async (user: User): Promise<void> => {
        dispatch({
            type: 'UPDATE',
            payload: {
                user,
            },
        });
    };

    const login = async (username: string, password: string): Promise<void> => {
        const accessToken = await authApi.login({ username, password });
        const user = await authApi.me(accessToken);

        localStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

        dispatch({
            type: 'LOGIN',
            payload: {
                user,
            },
        });
    };

    const logout = async (): Promise<void> => {
        localStorage.removeItem('accessToken');
        delete axios.defaults.headers.common.Authorization;
        dispatch({ type: 'LOGOUT' });
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                platform: 'JWT',
                update,
                login,
                logout,
            }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
