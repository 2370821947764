export const ROLE = {
    SUPERADMIN: 'superadmin',
    ADMIN: 'admin',
    DISPATCHER: 'dispatcher',
    WORKER: 'worker',
    CLIENT: 'client',
};

export type Role = 'superadmin' | 'admin' | 'dispatcher' | 'worker' | 'client';

export const ROLE_OPTIONS = [
    {
        label: 'Superadmin',
        value: 'superadmin',
    },
    {
        label: 'Admin',
        value: 'admin',
    },
    {
        label: 'Disponent',
        value: 'dispatcher',
    },
    {
        label: 'Kommissionierer',
        value: 'worker',
    },
    {
        label: 'Kunde',
        value: 'client',
    },
];

export const ROLE_LABELS = {
    superadmin: {
        label: 'Superadmin',
        colorCode: '#cf5d4e', // cf5d4e
    },
    admin: {
        label: 'Admin',
        colorCode: '#e0bc2b',
    },
    dispatcher: {
        label: 'Disponent',
        colorCode: '#34b366',
    },
    worker: {
        label: 'Kommissionierer',
        colorCode: '#34b39e',
    },
    client: {
        label: 'Kunde',
        colorCode: '#688eff',
    },
};
